import {
  BarChart2,
  Database,
  Terminal,
  Home,
  RefreshCw,
  Clock,
  UploadCloud,
} from "react-feather";

const Overview = [
  {
    href: "/",
    icon: Home,
    title: "Home",
  },
];

const Taps = [
  {
    href: "/taps/mytaps",
    icon: RefreshCw,
    title: "Manage your data taps",
  },
];

const Transformations = [
  {
    href: "/sql/editor",
    icon: Terminal,
    title: "SQL Editor",
  },
];

const Jobs = [
  {
    href: "/jobs/overview",
    icon: Clock,
    title: "Scheduled Jobs",
  },
];

const Push = [
  {
    href: "/jobs/push",
    icon: UploadCloud,
    title: "Data Push",
  },
];

const navItems = [
  {
    title: "Overview",
    pages: Overview,
  },
  {
    title: "Data Taps",
    pages: Taps,
  },
  {
    title: "Data Transformations",
    pages: Transformations,
  },
  {
    title: "Jobs",
    pages: Jobs,
  },
  {
    title: "Data Push",
    pages: Push,
  },
];

export default navItems;
