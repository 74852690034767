/* eslint-disable */

import React, { Component } from "react";
import axios from "../../../utils/axios";
import { Button, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { config } from "../../../constants";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import LogsTable from "../LogsTable";
import _ from "lodash";
import "./daghistory.css";

import { ReactComponent as FailedIcon } from "../../../assets/img/icons/failed.svg";
import { ReactComponent as QueuedIcon } from "../../../assets/img/icons/queed.svg";
import { ReactComponent as RunningIcon } from "../../../assets/img/icons/running.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/img/icons/success.svg";

import "../../../styles/table.css";
import DatajoltSpinner from "../../../components/DatajoltSpinner";
import { fetchLastRuns } from "../../functions.js";

const user = JSON.parse(localStorage.getItem("user"));
var customer_timezone;
(function retryFetch(retryDelay = 4000, maxAttempts = 2, currentAttempt = 1) {
  var localTimezone = localStorage.getItem("timezone");

  if (!localTimezone && currentAttempt <= maxAttempts) {
    console.log(
      `Attempt ${currentAttempt}: Timezone not found, retrying in ${retryDelay}ms...`
    );
    setTimeout(
      () => retryFetch(retryDelay, maxAttempts, currentAttempt + 1),
      retryDelay
    );
  } else if (localTimezone) {
    customer_timezone = localTimezone; // Update the broader scoped variable
    // Proceed with your logic here
  } else {
    console.log("Max attempts reached. Failed to get timezone.");
    // Handle failure case here
  }
})();

class DagHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyTargetId:
        new URLSearchParams(window.location.search).get("history_modal") ||
        this.props.historyModalId,
      loading: false,
      historical_runs_available: true,
      DAGs: [],
      running_dags: [],
      loading_metadata: true,
      dag_runs: [],
      last_succesful_runs: {},
      dag_pause_statuses: {},
      historical_dag_runs: [],
      progress: 40,
      fetching_last_runs: false,
      selected_job: "",
      schedule_frequency: false,
      schedule_details: false,
      scheduling: false,
      modal_logs: null,
      schedule_day: false,
      dag_logs: [],
      selected_job_logo: "",
      selected_job_type: "",
      isShowingDagHistoryTable: false,
    };
  }

  componentDidMount() {
    this.setState({ fetching_last_runs: true });
    window.scrollTo({
      top: 0,
    });
    this.getTargetDAGData();
    this.setState(
      {
        isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
      },
      () => {
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 500);
      }
    );
    this.fetchDAGLastRunsTimerMounted = true;
    this.fetchDAGLastRunsTimeout = this.fetchDAGLastRunsTimer();
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    this.fetchDAGLastRunsTimerMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  }

  getTargetDAGData = async () => {
    const history_modal =
      new URLSearchParams(window.location.search).get("history_modal") ||
      this.props.historyModalId;

    let targetDAG = {};

    if (this.props.dagList) {
      let dag_id;
      targetDAG = this.props.dagList.find(
        (DAG) => DAG.workflowname == history_modal
      );
      if (!targetDAG) {
        targetDAG = this.props.dagList.find((DAG) => DAG.id == history_modal);
        dag_id = targetDAG?.datajolt_task_id;
        console.log(targetDAG?.dag_id);
      } else {
        dag_id = targetDAG?.dag_id;
      }
      console.log(targetDAG);
      this.openDAGHistory(dag_id, targetDAG?.logo);
      return targetDAG;
    } else {
      const toastID = toast.loading("Retrieving job data");
      this.setState({ DAGs: [], loading: true, fetching_last_runs: true });
      this.setState({ progress: 80 });
      await axios
        .post(config.url.AIRFLOW + "/api/airflow/list_dags", {
          dag_type: "all",
        })
        .then((response) => {
          this.setState({
            dag_pause_statuses: response.data["pauses_status"],
            DAGs: response.data["dags_list"],
          });

          targetDAG = response.data["dags_list"].find(
            (DAG) => DAG.workflowname == history_modal
          );
          console.log("DAG list HERE");
          console.log(this.state.DAGs);
          console.log(targetDAG);

          this.openDAGHistory(targetDAG?.dag_id, targetDAG?.logo);
        })
        .catch((err) => {
          toast.error("Error while loading scheduled jobs.");
          this.setState({ historical_runs_available: false });
        })
        .finally(() => {
          this.setState({
            progress: 100,
            loading: false,
            fetching_last_runs: false,
          });
          toast.dismiss(toastID);
          return targetDAG;
        });
    }
  };

  fetchDAGLastRunsTimer = (datajolt_task_id, no, bool, time) => {
    if (datajolt_task_id)
      setTimeout(() => {
        this.fetchDAGLastRunsTimerMounted &&
          fetchLastRuns(datajolt_task_id, bool, this.setState.bind(this));
      }, time);
  };

  openDAGHistory = (datajolt_task_id, logo) => {
    console.log("Opening history for " + datajolt_task_id + "...");
    this.setState({
      selected_job: datajolt_task_id,
      modal_history: true,
      selected_logo: logo,
    });
    fetchLastRuns(datajolt_task_id, 50, this.setState.bind(this));
  };

  gettasks = (dag_state, dag_id, dag_run_id, started_at) => {
    if (this.state.modal_logs === dag_run_id) {
      this.setState({
        modal_logs: false,
        dag_logs: [],
      });
      return;
    }
    var toastId = toast.loading("Fetching logs...");
    this.setState({
      fetching_logs: true,
      progress: 40,
      dag_started_at: started_at,
      selected_dag_state: dag_state,
      loading_log_id: dag_run_id,
    });
    axios
      .post(config.url.AIRFLOW + "/api/airflow/get_task_level_for_logs", {
        dag_state: dag_state,
        dag_id: dag_id,
        dag_run_id: dag_run_id,
        dag_started_at: started_at,
      })
      .then((response) => {
        this.setState({
          modal_logs: dag_run_id,
          dag_logs: response.data["logs"],
        });
      })
      .catch((err) => {
        toast.error("Error while fetching logs.");
      })
      .finally(() => {
        this.setState({
          fetching_logs: false,
          progress: 100,
          loading_log_id: false,
        });
        toast.dismiss(toastId);
      });
  };

  render() {
    return (
      <div id="history-table-container">
        <div
          style={{ display: "flex", justifyContent: "flex-start" }}
          className="history-table-content-container"
        >
          <CSSTransition
            in={this.state.isShowingDagHistoryTable}
            classNames="right-slide"
            timeout={300}
            unmountOnExit
          >
            <div className="history-sidebar">
              <header className="history-heading">
                {this.state.selected_logo ? (
                  <img
                    alt="dag logo"
                    src={this.state.selected_logo}
                    style={{
                      width: "50px",
                      objectFit: "cover",
                    }}
                  ></img>
                ) : null}
                <b className="history-heading-name">
                  Historical Runs:&nbsp;
                  {this.state.historyTargetId}
                  &nbsp;
                </b>
                <button
                  className="close-history-sidebar-button"
                  onClick={() => this.props.onClose()}
                >
                  <Icon.X size={18} className="close-history-sidebar-icon" />
                  Close
                </button>
              </header>
              <>
                <table className="table-lists table-daged no-sticky-table-header table-triped table-hover my-8px">
                  <thead>
                    <tr>
                      <th width="20%">Date</th>
                      <th width="20%">Started</th>
                      <th width="20%">Ended</th>
                      <th width="15%">Duration</th>
                      <th width="15%">Status</th>
                      {/* <th width="15%">Rows</th> */}
                      <th width="15%">Run Type</th>
                      <th width="10%">Logs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Boolean(
                      Object.keys(this.state.historical_dag_runs).length
                    ) &&
                      this.state.historical_dag_runs[
                        this.state.selected_job
                      ].map((DAG) => (
                        <>
                          <tr key={DAG.dag_run_id}>
                            <td>
                              {DAG.start_date && DAG.start_date != "Seconds ago"
                                ? moment
                                    .utc(DAG.start_date)
                                    .tz(customer_timezone)
                                    .format("YYYY-MM-DD")
                                : "Today"}{" "}
                            </td>
                            <td>
                              {DAG.start_date && DAG.start_date != "Seconds ago"
                                ? moment
                                    .utc(DAG.start_date)
                                    .tz(customer_timezone)
                                    .format("YYYY-MM-DD HH:mm")
                                : "Seconds ago"}
                            </td>
                            <td>
                              {DAG.end_date
                                ? moment
                                    .utc(DAG.end_date)
                                    .tz(customer_timezone)
                                    .format("YYYY-MM-DD HH:mm")
                                : ""}
                            </td>
                            <td>{DAG.duration}</td>
                            <td className="text-break">
                              {DAG.state == "success" ? (
                                <OverlayTrigger
                                  rootClose
                                  trigger={["hover", "hover"]}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Success
                                    </Tooltip>
                                  }
                                >
                                  <SuccessIcon className="centered-svg-block" />
                                </OverlayTrigger>
                              ) : DAG.state == "failed" ? (
                                <OverlayTrigger
                                  rootClose
                                  trigger={["hover", "hover"]}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Failed
                                    </Tooltip>
                                  }
                                >
                                  <FailedIcon className="centered-svg-block" />
                                </OverlayTrigger>
                              ) : DAG.state == "queued" ? (
                                <OverlayTrigger
                                  rootClose
                                  trigger={["hover", "hover"]}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Queued
                                    </Tooltip>
                                  }
                                >
                                  <QueuedIcon className="centered-svg-block" />
                                </OverlayTrigger>
                              ) : DAG.state == "running" ? (
                                <OverlayTrigger
                                  rootClose
                                  trigger={["hover", "hover"]}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      Running
                                    </Tooltip>
                                  }
                                >
                                  <RunningIcon className="centered-svg-block" />
                                </OverlayTrigger>
                              ) : (
                                DAG.state
                              )}
                            </td>
                            {/* <td>
                              {DAG.rows}
                              </td> */}
                            <td>{DAG.run_type}</td>

                            <td>
                              <Button
                                disabled={!this.props.show_logs}
                                variant="primary"
                                className="me-1 mb-1 logs-trigger"
                                size="sm"
                                onClick={() =>
                                  this.gettasks(
                                    DAG.state,
                                    this.state.dag_id || DAG.dag_id,
                                    DAG.dag_run_id,
                                    DAG.start_date
                                  )
                                }
                              >
                                {this.state.fetching_logs &&
                                DAG.dag_run_id === this.state.loading_log_id ? (
                                  <center className="rotate-icon">
                                    <Icon.Loader size={16} color="white" />
                                  </center>
                                ) : DAG.dag_run_id === this.state.modal_logs ? (
                                  <Icon.ArrowDown size={16} color="white" />
                                ) : (
                                  <Icon.Code size={16} color="white" />
                                )}
                              </Button>
                            </td>
                          </tr>
                          {DAG.dag_run_id === this.state.modal_logs && (
                            <LogsTable
                              show={this.state.modal_logs}
                              onHide={() =>
                                this.setState({ modal_logs: false })
                              }
                              selected_job={this.state.selected_job}
                              dag_started_at={this.state.dag_started_at}
                              selected_dag_state={this.state.selected_dag_state}
                              dag_logs={this.state.dag_logs}
                            />
                          )}
                        </>
                      ))}
                  </tbody>
                </table>
              </>
              {!this.state.historical_runs_available && (
                <center
                  className="no-history-text"
                  onClick={() => this.props.onClose()}
                >
                  <b>No historical runs of this job yet.</b>{" "}
                  <Icon.ChevronsRight width={14} />
                </center>
              )}
              {this.state.fetching_last_runs && (
                <>
                  <center>
                    <DatajoltSpinner />
                  </center>
                </>
              )}
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default DagHistory;
