/* eslint-disable */

import React, { Component } from "react";
import axios from "../../utils/axios";
import {
  Row,
  Tooltip,
  OverlayTrigger,
  Tab,
  Col,
  Button,
  Spinner,
  Form,
  Popover,
} from "react-bootstrap";
import Redshift from "../../assets/img/brands/dwh/redshift.png";
import Snowflake from "../../assets/img/brands/dwh/snowflake.png";
import { useNavigate } from "react-router-dom";
import { config } from "../../constants";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Icon from "react-feather";

var api_url = config.url.API_URL;
const DWHTypes = [
  {
    value: "Snowflake",
    label: (
      <center>
        <img src={Snowflake} width="14%"></img>
      </center>
    ),
  },
  {
    value: "Redshift",
    label: (
      <center>
        <img src={Redshift} width="14%"></img>
      </center>
    ),
  },
];

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DWHs: [],
      message: "",
      loading_dwhs: false,
      color: "primary",
      activeTab: "dwh",
      EditOpen: false,
      progress: 40,
      dwh_types: [],
      loading_connectors: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    });
  };

  handleSelectChange = (e) => {
    this.setState({
      ["DWHType"]: e.value.trim(),
    });
  };

  openEditModal = () => {};

  testConnection = () => {
    var toastId = toast.loading(
      "Testing your " + this.state.selected_dwh + " connection..."
    );
    this.setState({
      progress: 40,
      loading: true,
      status_message: "",
      logs: "",
    });
    if (this.state.selected_dwh == "Redshift") {
      var ConnectionDetails = {
        dwhtype: this.state.selected_dwh,
        endpoint: this.state.DWHEndpoint,
        username: this.state.DWHUsername,
        alias: this.state.Alias,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
        port: "5439",
      };
    } else if (this.state.selected_dwh == "Snowflake") {
      var ConnectionDetails = {
        alias: this.state.Alias,
        dwhtype: this.state.selected_dwh,
        accountid: this.state.AccountID,
        username: this.state.DWHUsername,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
      };
    }
    try {
      axios.post(api_url + "/api/dwh/test_connection", ConnectionDetails, {
        onDownloadProgress: (progressEvent) => {
          const api_logs = progressEvent.currentTarget.response;
          if (
            api_logs.slice(0, 5) == "[API]" &&
            api_logs.slice(-4) != "Done" &&
            !api_logs.includes("[ERROR]")
          ) {
            this.setState({
              logs: api_logs,
            });
          } else if (api_logs.slice(-4) == "Done") {
            this.setState({
              progress: 100,
              loading: false,
              color: "success",
            });
            toast.dismiss(toastId);
            toast.success(
              "Congratulations! Your " +
                this.state.selected_dwh +
                " DWH is accessible.\n\nClick Save to add it to Datajolt!",
              { autoClose: 5000 }
            );
          } else {
            toast.dismiss(toastId);
            toast.error(
              "Unable to connect to your DWH.\n\nPlease check your credentials and try again."
            );
            this.setState({
              loading: false,
              progress: 100,
            });
          }
        },
      });
    } catch {
      toast.dismiss(toastId);
      toast.error("Issue testing your connection.");
      this.setState({
        progress: 100,
        loading: false,
        status_message: "error",
        color: "danger",
      });
    }
  };

  componentDidMount() {
    this.fetchDWHs();
  }

  saveDWH = () => {
    var toastId = toast.loading(
      "Saving your " + this.state.selected_dwh + " connection..."
    );
    this.setState({ progress: 40, saving: true, status_message: "", logs: "" });
    if (this.state.selected_dwh == "Redshift") {
      var ConnectionDetails = {
        dwhtype: this.state.selected_dwh,
        endpoint: this.state.DWHEndpoint,
        username: this.state.DWHUsername,
        alias: this.state.Alias,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
        port: "5439",
      };
    } else if (this.state.selected_dwh == "Snowflake") {
      var ConnectionDetails = {
        alias: this.state.Alias,
        dwhtype: this.state.selected_dwh,
        accountid: this.state.AccountID,
        username: this.state.DWHUsername,
        password: this.state.DWHPassword,
        dbname: this.state.DBname,
      };
    }
    axios
      .post(api_url + "/api/dwh/save_dwh", ConnectionDetails)
      .then((response) => {
        toast.success("Successfully connected your DWH to Datajolt!", {
          autoClose: 5000,
        });
        this.setState({
          activeTab: "dwh",
          progress: 100,
        });
        this.fetchDWHs();
      })
      .catch((err) => {
        toast.error("There was an internal issue saving your DWH.");
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({ progress: 100, saving: false });
      });
  };

  listDWHtaps = () => {
    this.setState({
      loading_connectors: true,
      color: "primary",
      progress: 40,
      activeTab: "adddwh",
    });
    axios
      .post(api_url + "/api/taps/list_connectors", { type: "dwh" })
      .then((response) => {
        this.setState({ progress: 100, dwh_types: response.data });
        toast.success("Select your DWH type.", { autoClose: 5000 });
      })
      .catch((err) => {
        toast.error("There was an internal issue listing DWH connectors.");
      })
      .finally(() => {
        this.setState({ loading_connectors: false, progress: 100 });
      });
  };

  goToNewConnection = () => {
    console.log("Trying to redirect");
    let navigate = useNavigate();
    navigate("/dwh/new-connection");
  };

  fetchDWHs = () => {
    this.setState({ activeTab: "dwh", progress: 40, loading_dwhs: true });
    axios
      .post(api_url + "/api/dwh/list_dwhs", { customer: "Datajolt" })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            DWHs: response.data,
          });
        }
        this.getDiskSpace();
      })
      .catch((err) => {
        toast.error("Error while loading your DWHs.");
      })
      .finally(() => {
        this.setState({ progress: 100, loading_dwhs: false });
      });
  };

  getDiskSpace = (e) => {
    console.log("Fetching disk space...");
    axios
      .post(api_url + "/api/dwh/get_disk_space", { customer: "Datajolt" })
      .then((response) => {
        this.setState({ DWH_diskspace: JSON.parse(response.data["used_gb"]) });
      });
  };

  setupDWH = (selected_dwh, logo) => {
    this.setState({
      progress: 40,
      logo: logo,
      selected_dwh: selected_dwh,
      activeTab: "setup",
    });
    this.setState({ progress: 100 });
  };

  vacuum = (e) => {
    console.log("Triggering vacuum...");
    this.setState({
      vacuum_disable: true,
      color: "primary",
      status_message: "Trigggering VACUUM...",
    });
    axios
      .post(api_url + "/api/dwh/trigger_vacuum", { customer: "Datajolt" })
      .then((response) => {
        console.log(response.data);
        this.setState({
          vacuum_disable: false,
          color: "success",
          status_message: "Successfully trigggered VACUUM.",
        });
      });
  };

  render() {
    return (
      <div>
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <div className="container-fluid py-3">
          <Helmet title="Datajolt | Data Warehouse" />
          <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
            <Row className="justify-content-left mt-3 mb-2">
              <Col xs="auto">
                <Button
                  variant="generaltabs"
                  style={{ "margin-left": "10px" }}
                  onClick={() => this.fetchDWHs()}
                  className={this.state.activeTab === "dwh" ? "active" : ""}
                >
                  My DWHs{" "}
                  {this.state.loading_dwhs
                    ? ""
                    : "(" + this.state.DWHs.length + ")"}
                </Button>
                <Button
                  variant="generaltabs"
                  style={{ "margin-left": "10px" }}
                  onClick={() => this.listDWHtaps()}
                  className={this.state.activeTab === "adddwh" ? "active" : ""}
                >
                  +
                </Button>
                {this.state.activeTab != "dwh" ? (
                  <>
                    <Icon.ChevronRight />
                    <Button
                      disabled={this.state.activeTab === "setup" ? false : true}
                      variant="generaltabs"
                      onClick={() => this.getCatalog()}
                      className={
                        this.state.activeTab === "setup" ? "active" : ""
                      }
                    >
                      Setup
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="dwh">
                <div className="card-body">
                  <div
                    className="card"
                    style={{ minHeight: "500px", height: "100%" }}
                  >
                    <h4 style={{ "margin-left": "20px", "margin-top": "30px" }}>
                      <b>My DWHs</b>{" "}
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={
                          <Popover id={`popover-positioned-left`}>
                            <Popover.Header as="h3">
                              {"What is a DWH?"}
                            </Popover.Header>
                            <Popover.Body>
                              The DWH is the main piece of your data
                              infrastructure.
                              <br />
                              <br />
                              This is the place where your data will be
                              aggregated, transformed, analysed, and deliver
                              insights.
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          variant="no-outline-secondary"
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                          }}
                        >
                          <Icon.HelpCircle />
                        </Button>
                      </OverlayTrigger>{" "}
                    </h4>
                    <hr
                      style={{ "margin-left": "20px", "margin-right": "20px" }}
                    />
                    {this.state.loading_dwhs ? (
                      <>
                        <center>
                          <Spinner
                            as="span"
                            variant="primary"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </center>
                        <br />
                      </>
                    ) : this.state.DWHs.length > 0 ? (
                      <table
                        className="table-lists table-bordered my-3"
                        style={{
                          "margin-left": "20px",
                          "margin-top": "30px",
                          width: "97%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>NAME</th>
                            <th>USED DISK SPACE</th>
                            <th>STATUS</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DWHs.map((DWH) => (
                            <tr key={DWH.id}>
                              {DWH.dwh_type == "Redshift" ? (
                                <td width="15%">
                                  <img src={Redshift} width="60%"></img>
                                </td>
                              ) : (
                                <td width="15%">
                                  <img src={Snowflake} width="60%"></img>
                                </td>
                              )}
                              <td width="15%"> {DWH.alias} </td>
                              <td width="15%">
                                <b>
                                  {this.state.DWH_diskspace ? (
                                    this.state.DWH_diskspace + " GB"
                                  ) : (
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  )}
                                </b>
                              </td>
                              <td width="15%">
                                <Button
                                  type="submit"
                                  variant="live-success"
                                  size="sm"
                                >
                                  Live
                                </Button>
                              </td>
                              <td>
                                <Button variant="no-outline">
                                  <Icon.Settings />
                                </Button>
                                <Button variant="no-outline-danger">
                                  <Icon.XCircle />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <center>
                        {!this.state.loading_dwhs &&
                        this.state.DWHs.length == 0 ? (
                          <>
                            <br />
                            <br />
                            <h4>
                              You currently have no DWH connected to Datajolt.
                            </h4>
                            <br />
                            <Button
                              variant="generaltabs"
                              onClick={() => this.listDWHtaps()}
                              className="active"
                            >
                              Connect your DWH now
                            </Button>
                            <br />
                            <br />
                            <br />
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </center>
                    )}
                    {!this.state.loading_dwhs ? (
                      <center>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Refresh DWHs
                            </Tooltip>
                          }
                        >
                          <Button
                            type="submit"
                            variant="no-outline"
                            className="me-1 mb-1"
                            disabled={this.state.loading_dwhs}
                            onClick={() => this.fetchDWHs()}
                          >
                            {" "}
                            {this.state.loading_dwhs ? (
                              <Spinner
                                size="sm"
                                variant="secondary"
                                animation="border"
                              />
                            ) : (
                              <Icon.RefreshCw size={16} />
                            )}
                          </Button>
                        </OverlayTrigger>
                        <br />
                        <br />
                      </center>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="adddwh">
                <div className="card-body">
                  <div className="card">
                    <h4 style={{ "margin-left": "20px", "margin-top": "30px" }}>
                      <b>Data Warehouse Connectors</b>{" "}
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={
                          <Popover id={`popover-positioned-left`}>
                            <Popover.Header as="h3">
                              {"What are DWH Connectors?"}
                            </Popover.Header>
                            <Popover.Body>
                              Connectors are the data warehouses currently
                              supported by Datajolt.
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          variant="no-outline-secondary"
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                          }}
                        >
                          <Icon.HelpCircle />
                        </Button>
                      </OverlayTrigger>{" "}
                    </h4>
                    <hr
                      style={{ "margin-left": "20px", "margin-right": "20px" }}
                    />
                    <Row style={{ "margin-left": "20px" }}>
                      {!this.state.loading_connectors ? (
                        this.state.dwh_types.length > 0 ? (
                          this.state.dwh_types.map((dwh_type) => (
                            <Col lg="2">
                              <div
                                onClick={() =>
                                  this.setupDWH(
                                    dwh_type.value,
                                    dwh_type.logo,
                                    "Data Warehouse"
                                  )
                                }
                                class="w-dyn-item"
                                style={{
                                  display: "inline-block",
                                  marginleft: "5px",
                                }}
                              >
                                <div class="w-embed">
                                  <input
                                    type="hidden"
                                    class="jetboost-list-item"
                                  />
                                </div>
                                <div class="card-taps">
                                  <div class="card-taps-body connector-card-taps-body">
                                    <div class="connector-image">
                                      <img
                                        src={dwh_type.logo}
                                        loading="lazy"
                                        alt=""
                                        class="image-contain"
                                      />
                                    </div>
                                    <div class="card-taps-title text-center">
                                      {dwh_type.label}
                                    </div>
                                    <a class="mb-5 w-inline-block">
                                      <div class="card-taps-metadata">
                                        {dwh_type.tap_group}
                                      </div>
                                    </a>
                                    <div class="icons-wrapper"></div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <br></br>
                          <center>
                            <Spinner
                              as="span"
                              variant="primary"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                            />
                            <br />
                            <br />
                          </center>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="setup">
                <div className="card-body">
                  <div className="card">
                    <h4 style={{ "margin-left": "20px", "margin-top": "30px" }}>
                      <b>Data Warehouse Details</b>{" "}
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={
                          <Popover id={`popover-positioned-left`}>
                            <Popover.Header as="h3">
                              {"What are DWH Details?"}
                            </Popover.Header>
                            <Popover.Body></Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          variant="no-outline-secondary"
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                          }}
                        >
                          <Icon.HelpCircle />
                        </Button>
                      </OverlayTrigger>{" "}
                    </h4>
                    <hr
                      style={{ "margin-left": "20px", "margin-right": "20px" }}
                    />
                    <Col lg="12">
                      <Row>
                        <Col lg="6">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              style={{ "margin-left": "20px" }}
                            >
                              <Form.Label>
                                <b>DWH Alias</b>
                              </Form.Label>
                              <Form.Control
                                style={{ width: "98.5%" }}
                                type="text"
                                name="Alias"
                                placeholder="Alias"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            {this.state.selected_dwh == "Redshift" ? (
                              <>
                                <Form.Group
                                  className="mb-3"
                                  style={{ "margin-left": "20px" }}
                                >
                                  <Form.Label>
                                    <b>DWH Endpoint</b>
                                  </Form.Label>
                                  <Form.Control
                                    style={{ width: "98.5%" }}
                                    type="text"
                                    name="DWHEndpoint"
                                    placeholder="Endpoint"
                                    onChange={this.handleChange}
                                  />
                                </Form.Group>
                              </>
                            ) : (
                              <Form.Group
                                className="mb-3"
                                style={{ "margin-left": "20px" }}
                              >
                                <Form.Label>
                                  <b>Account ID</b>
                                </Form.Label>
                                <Form.Control
                                  style={{ width: "98.5%" }}
                                  type="text"
                                  name="AccountID"
                                  placeholder="Account ID"
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            )}
                            <Form.Group
                              className="mb-3"
                              style={{ "margin-left": "20px" }}
                            >
                              <Form.Label>
                                <b>Database Name</b>
                              </Form.Label>
                              <Form.Control
                                style={{ width: "98.5%" }}
                                type="text"
                                name="DBname"
                                placeholder="Database Name"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              style={{ "margin-left": "20px" }}
                            >
                              <Form.Label>
                                <b>Username</b>
                              </Form.Label>
                              <Form.Control
                                style={{ width: "98.5%" }}
                                type="text"
                                name="DWHUsername"
                                placeholder="Username"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Label style={{ "margin-left": "20px" }}>
                              <b>Password</b>
                            </Form.Label>
                            <Form.Control
                              style={{ "margin-left": "20px", width: "95.5%" }}
                              type="password"
                              name="DWHPassword"
                              placeholder="Password"
                              onChange={this.handleChange}
                            />
                          </Form>
                        </Col>
                        <Col lg="4"></Col>
                        <Col lg="1">
                          <div
                            class="w-dyn-item"
                            style={{
                              display: "inline-block",
                              marginleft: "5px",
                            }}
                          >
                            <div class="w-embed">
                              <input
                                type="hidden"
                                class="jetboost-list-item"
                                value={this.state.selected_dwh}
                              />
                            </div>
                            <div class="card-taps">
                              <div class="card-taps-body connector-card-taps-body">
                                <div class="connector-image">
                                  <img
                                    src={this.state.logo}
                                    loading="lazy"
                                    alt=""
                                    class="image-contain"
                                  />
                                </div>
                                <div class="card-taps-title text-center">
                                  {this.state.selected_dwh}
                                </div>
                                <a class="mb-5 w-inline-block">
                                  <div class="card-taps-metadata">
                                    Data Warehouse
                                  </div>
                                </a>
                                <div class="icons-wrapper"></div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <hr
                            style={{
                              "margin-left": "20px",
                              "margin-right": "20px",
                            }}
                          />
                          <Button
                            style={{
                              "margin-left": "20px",
                              "margin-bottom": "20px",
                            }}
                            type="submit"
                            variant={this.state.color}
                            size="lg"
                            disabled={
                              this.state.loading
                                ? true
                                : this.state.color == "success"
                                ? true
                                : false
                            }
                            onClick={this.testConnection}
                          >
                            {this.state.color == "primary"
                              ? this.state.loading
                                ? "Testing..."
                                : "Test Connection"
                              : this.state.color == "success"
                              ? "Success"
                              : ""}
                            &nbsp;
                            {this.state.color == "success" ? (
                              <Icon.Check />
                            ) : (
                              ""
                            )}
                            {this.state.loading ? (
                              <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />
                            ) : (
                              ""
                            )}
                          </Button>
                          {this.state.color == "success" ? (
                            <Button
                              style={{
                                "margin-left": "20px",
                                "margin-bottom": "20px",
                              }}
                              type="submit"
                              variant="primary"
                              size="lg"
                              disabled={this.state.saving}
                              onClick={() => this.saveDWH()}
                            >
                              {this.state.saving ? "Saving..." : "Save DWH"}
                              {this.state.saving ? (
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              ) : (
                                ""
                              )}
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    );
  }
}
