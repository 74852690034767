import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { MoreVertical } from "react-feather";

const HorizontalDropdownMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="horizontal-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertical color="#4E4F61" />
      </Button>
      <Menu
        sx={{
          "& .MuiPaper-root": { overflow: "visible" },
          "& ul": { padding: 0 },
        }}
        id="horizontal-menu"
        className="dag-action-buttons"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onBlur={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          sx={{
            overflow: "visible",
            padding: "17px 12px",
            gap: "10px",
            "&:hover": { background: "white" },
            "& svg": { width: "16px" },
            borderRadius: "15px",
          }}
        >
          {children}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HorizontalDropdownMenu;
