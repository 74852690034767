import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useOuterClick from "../hooks/useOuterClick";
import useTheme from "../hooks/useTheme";
import useSidebar from "../hooks/useSidebar";
import useLayout from "../hooks/useLayout";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Settings = () => {
  const query = useQuery();
  const [isOpen, setIsOpen] = useState(false);

  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  const setSettingByQueryParam = (name, set) => {
    const value = query.get(name);
    if (value) {
      set(value);
    }
  };

  // Read from query parameter (e.g. ?theme=dark)
  // only for demo purposes
  useEffect(() => {
    setSettingByQueryParam("theme", setTheme);
    setSettingByQueryParam("sidebarPosition", setPosition);
    setSettingByQueryParam("sidebarBehavior", setBehavior);
    setSettingByQueryParam("layout", setLayout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Settings;
