import React from "react";
import runningIcon from "../assets/img/icons/running.json";
import Lottie from "lottie-react";

export default function RunningLottie({ width = 20 }) {
  return (
    <div
      style={{
        width,
        margin: "0 auto",
      }}
    >
      <Lottie animationData={runningIcon} loop />
    </div>
  );
}
