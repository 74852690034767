import { useContext } from "react";
import { ScheduleCrudContext } from "../contexts/ScheduleCrudContext";

function useModalContext() {
  const context = useContext(ScheduleCrudContext);
  // if context is undefined this means it was used outside of its provider
  // you can throw an error telling that to your fellow developers
  if (!context) {
    throw new Error(
      "useModalContext must be used under <ModalContextProvider/>"
    );
  }
  return context;
}

export default useModalContext;
