/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  useAuth,
  useTenantsState,
  useAuthActions,
  AdminPortal,
  ContextHolder
} from "@frontegg/react";
import axios from "../../utils/axios";
import { Navbar, Nav, Dropdown, Spinner } from "react-bootstrap";
import { config } from "../../constants";
import { Settings, User, LogOut } from "react-feather";
import useSidebar from "../../hooks/useSidebar";
import useScheduleCrud from "../../hooks/useScheduleCrud";
import { setSession } from "../../utils/jwt";

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSidebar();
  const { setIsOpen: setScheduleCrudOpen } = useScheduleCrud();
  const [loading, setLoading] = useState(true);
  const [tenant, setTenant] = useState(localStorage.getItem("activeTenantId"));
  const { switchTenant } = useAuthActions();
  const [profileImage, setProfileImage] = useState();
  const { user, isAuthenticated } = useAuth();
  const { tenants } = useTenantsState();
  const [sortedTenants, setSortedTenants] = useState([]);

  useEffect(() => {
    if (tenants.length > 0) {
      const sorted = [...tenants].sort((a, b) => a.name.localeCompare(b.name));
      setSortedTenants(sorted);
    } else {
      setLoading(false);
    }
  }, [tenants]);

  useEffect(() => {
    fetchCustomerParameters();
  }, []);

  useEffect(() => {
    if (user?.profilePictureUrl) {
      setProfileImage(user.profilePictureUrl);
    }
  }, [user?.profilePictureUrl]);

  const handleTenantChange = async (newTenantId) => {
    try {
      console.log(`Switching to tenant ID: ${newTenantId}`);
      localStorage.removeItem("timezone");
      localStorage.removeItem("logo");
      setSession(null);
      console.log("Setting selected_branch to main");
      localStorage.setItem("selected_branch", "main");
      const switchResponse = await switchTenant({ tenantId: newTenantId });

      // const authResponse = await requestAuthorize();
      // console.log('requestAuthorize response:', authResponse);

      // if (authResponse.payload && authResponse.payload.accessToken) {
      //   const newAccessToken = authResponse.payload.accessToken;
      //   localStorage.setItem("accessToken", newAccessToken);
      //   setSession(newAccessToken);
      //   console.log('New access token set:', newAccessToken);
      //   await fetchCustomerParameters();
      // } else {
      //   console.error('Failed to reauthorize after switching tenant.');
      // }
    } catch (error) {
      console.error("Error switching tenant:", error);
    }
  };

  const fetchCustomerParameters = async () => {
    try {
      const logo = localStorage.getItem("logo");
      const timezone = localStorage.getItem("timezone");

      if (
        logo &&
        logo !== "undefined" &&
        timezone &&
        timezone !== "undefined"
      ) {
        setLoading(false);
      } else {
        const url = `${config.url.WEBAPP}/api/datajolt/customer_parameters`;
        const response = await axios.get(url);
        const { logo, timezone, customer_name } = response.data;

        if (logo) localStorage.setItem("logo", logo);
        if (timezone) localStorage.setItem("timezone", timezone);
        if (customer_name)
          localStorage.setItem("activeTenantId", customer_name);
        setLoading(false);
        console.log("Setting tenant:", customer_name);
        setTenant(customer_name);

        console.log("Customer parameters fetched and set");
      }
    } catch (error) {
      console.error("Error fetching customer parameters:", error);
    }
  };

  const adminPortal = () => {
    AdminPortal.show();
  };

  const signOut = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  return (
    <Navbar variant="light" expand className="navbar-bg">
      {loading ? (
        <Spinner animation="border" role="status" variant="primary" size="sm" />
      ) : (
        <img
          src={localStorage.getItem("logo")}
          alt="Logo"
          style={{
            width:
              localStorage.getItem("logo") ===
              "https://assets-global.website-files.com/64cc5a89d4d4629cd9a9c005/64cc5a89d4d4629cd9a9c180_Pear-Logo-Green-dark-2021%2520(1)-p-500.png"
                ? "90px"
                : "135px",
            height: "auto",
          }}
        />
      )}
      <div style={{ flexGrow: 1 }}></div>
      <div>{/* Additional navbar buttons or elements */}</div>
      <Navbar.Collapse style={{ flexGrow: "unset" }}>
        <Nav className="navbar-align">
          <Dropdown className="nav-item" align="end">
            <span className="d-inline-block d-sm-none">
              <Dropdown.Toggle as="a" className="nav-link">
                <Settings size={18} className="align-middle" />
              </Dropdown.Toggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <Dropdown.Toggle as="a" className="nav-link">
                <img
                  src={profileImage}
                  className="avatar img-fluid rounded-circle me-1"
                  alt={user ? user.name : "Avatar"}
                />
                &nbsp;
                <span className="text-dark">{user?.name}</span>
              </Dropdown.Toggle>
            </span>
            <Dropdown.Menu drop="end">
              <Dropdown.Item onClick={adminPortal}>
                <User size={18} className="align-middle me-2" />
                Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={signOut}>
                <LogOut size={18} className="align-middle me-2" />
                Sign out
              </Dropdown.Item>
              <hr />
              {sortedTenants.map((tenantItem) => (
                <Dropdown.Item
                  key={tenantItem.name}
                  active={tenantItem.tenantId === tenant}
                  onClick={() => handleTenantChange(tenantItem.tenantId)}
                >
                  {tenantItem.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
