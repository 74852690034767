import { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { Clock } from "react-feather";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const CustomPopper = function (props) {
  return (
    <Popper
      {...props}
      sx={{ "& .MuiPaper-elevation": { margin: "8px 0" } }}
      placement="top-start"
    />
  );
};

export default function AutocompleteInput({
  options,
  onChange,
  sx,
  placeholder,
  onKeyDown,
}) {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#28339a",
              boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
            },
          },
        },
      },
    },
  });

  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!initialRender) {
      onChange(inputRef.current.value);
    } else {
      setInitialRender(false);
    }
  }, [inputValue, value]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        PopperComponent={CustomPopper}
        onKeyDown={onKeyDown}
        options={options}
        freeSolo
        value={value}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        sx={{
          ...sx,
          mt: 1,
          "& .MuiAutocomplete-listbox > li": `Menlo, Monaco, "Courier New", monospace !important`,
          "& .MuiSvgIcon-root": { mt: "-2px" },
          "& .MuiOutlinedInput-root": {
            height: 18.5,
            "& fieldset": { borderColor: "#dfe0eb" },
            "& input": {
              fontFamily: `Menlo, Monaco, "Courier New", monospace !important`,
              height: 13,
              fontSize: 12,
            },
          },
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              color: "#6c757d",
              fontFamily: "Menlo",
              fontSize: 14,
            }}
          >
            <Clock color="#d3d3d3" width="15" style={{ marginRight: "8px" }} />{" "}
            {/* Render the time icon */}
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            sx={{
              mb: 1,
              "& fieldset legend": { display: "none" },
              "& .MuiInputBase-sizeSmall": {
                padding: "0 !important",
              },
              "& .MuiAutocomplete-input": { paddingTop: "0 !important" },
            }}
            {...params}
            label=""
            placeholder={placeholder}
          />
        )}
        size="small"
      />
    </ThemeProvider>
  );
}
