import React, { useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const navPositions = [4, 42, 80, 118, 156, 194, 232, 270];

function findClosestNumber(arr, target) {
  if (arr.length === 0) {
    return null;
  }
  let closestNumber = arr[0];
  let minDifference = Math.abs(target - closestNumber);
  for (let i = 1; i < arr.length; i++) {
    const currentNumber = arr[i];
    const currentDifference = Math.abs(target - currentNumber);
    if (currentDifference < minDifference) {
      closestNumber = currentNumber;
      minDifference = currentDifference;
    }
  }
  return closestNumber;
}

export default function NavIndicator() {
  const location = useLocation();
  const navIndicator = useRef(null);

  const moveIndicator = () => {
    const element = document.querySelector('[aria-current="page"]');
    if (navIndicator?.current && element) {
      let offset = element.getBoundingClientRect().top - 70;
      const navIndicatorTopStyle = findClosestNumber(navPositions, offset);
      navIndicator.current.style.top = navIndicatorTopStyle + "px";
    }
  };

  useLayoutEffect(() => {
    const handleMoveIndicator = () => {
      moveIndicator();
    };

    // Call moveIndicator after a short delay to ensure the DOM is fully rendered
    setTimeout(handleMoveIndicator, 200);

    // Also call moveIndicator when the window is resized to handle dynamic layout changes
    window.addEventListener("resize", handleMoveIndicator);

    return () => {
      window.removeEventListener("resize", handleMoveIndicator);
    };
  }, [location]);

  return (
    <span
      ref={navIndicator}
      id="navIndicator"
      style={{
        width: "30px",
        height: "30px",
        background: "rgba(255,255,255,0.1)",
        position: "absolute",
        top: `4px`,
        left: 14.5,
        transition: "all 0.5s ease",
        borderRadius: "5px",
      }}
    ></span>
  );
}
