/* eslint-disable */

import React, { Component } from "react";
import axios from "../../utils/axios";
import {
  Modal,
  Row,
  Col,
  Popover,
  Button,
  Tooltip,
  OverlayTrigger,
  Spinner,
  Tab,
  Form,
} from "react-bootstrap";
import { config } from "../../constants";
import Select from "react-select";
import * as Icon from "react-feather";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import InputMask from "react-input-mask";
import { SpecDetailsForm } from "../connectors/specDetails.js";
import ConnectorCardPush from "../connectors/connectorCardPush.js";
import WithRouter from "../../HOC/RouterWrapper";
import StepIndicator from "./StepIndicator";

import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import DatajoltSpinner from "../DatajoltSpinner";

var api_url = config.url.API_URL;

const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

class Push extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields_mapping: [{ source: "", target: "" }],
      loading: false,
      loading_destination_objects: false,
      EditOpen: false,
      getting_push_types: false,
      getting_models: true,
      datasources: [],
      progress: 40,
      spec_details: [],
      running_dags: [],
      DAGs: [],
      loading_my_destinations: false,
      loading_connectors: false,
      dag_runs: [],
      dag_pause_statuses: [],
      destination_id: "",
      gs_url: "",
      historical_dag_runs: [],
      subelements: false,
      selected_datapoint_source: false,
      json_update_endpoint: false,
      source_link: false,
      destination_link: false,
      logs_schedule: false,
      get_dags_status: true,
      push_type: false,
      selected_destination_object: false,
      selected_datapoint_destination: false,
      destination_fields: [],
      selected_model: false,
      mydestinations: [],
      modal_history: false,
      activeTab: "all",
      deleting_push_alias: "",
      push_alias: "",
      destination_alias: "",
      mytaps: [],
      modal_edit: false,
      modal_flow: false,
      schedule_frequency: false,
      selected_job: "",
      schedule_details: false,
      scheduling: false,
      schedule_day: false,
      dag_logs: [],
      modal_lose_create_push_progress: false,
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
    });
    document.body.style.overflow = "hidden";
    const hasInitialEditView =
      this.props.router.location.search.includes("edit_push");

    if (hasInitialEditView) {
      this.showInitialEditView();
    } else {
      this.listConnectors("destinations");
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    if (this.props.setSelectedPushData) {
      this.props.setSelectedPushData({
        logo: null,
        selected_tap: "",
      });
    }
  }

  showInitialEditView = async () => {
    const edit_push_id = new URLSearchParams(window.location.search).get(
      "edit_push"
    );

    let targetDAG;

    if (this.props.dagsList.length) {
      targetDAG = this.props.dagsList.find((DAG) => DAG.id == edit_push_id);
      this.editPush(targetDAG.id, targetDAG.logo, targetDAG.subtype);
    } else {
      await axios
        .post(config.url.AIRFLOW + "/api/airflow/list_dags", { dag_type: "Push" })
        .then((response) => {
          this.setState({
            loading: false,
            DAGs: response.data["dags_list"],
            dag_pause_statuses: response.data["pauses_status"],
          });

          targetDAG = response.data["dags_list"].find(
            (DAG) => DAG.id == edit_push_id
          );
          this.editPush(targetDAG.id, targetDAG.logo, targetDAG.subtype);
          if (this.props.setSelectedPushData) {
            this.props.setSelectedPushData({
              logo: targetDAG.logo,
              selected_tap: targetDAG.subtype,
            });
          }
        })
        .catch((err) => {
          toast.error("Error while fetching your push data");
        });
    }
  };

  redirectToHistoryModalView = (DAG) => {
    this.props.router.navigate(`/jobs/push?history_modal=${DAG.id}`);
  };

  handleSelectChangeOneOf = (e, name, oneofs, options) => {
    const subelement_index = options
      .map((object) => object.value)
      .indexOf(e.value);
    const element_index = this.state.spec_details
      .map((object) => object.name)
      .indexOf(name);
    oneofs.map((oneoff) => {
      if (oneoff["title"] == e.value) {
        this.setState({
          element_index: element_index,
          subelements: oneoff["required"],
          subelement_index: subelement_index,
        });
      }
    });
  };

  handleSelectChangeEnum = (name) => (selectedvalue) => {
    this.setState({ [name]: selectedvalue.value });
  };

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  getColumns = (depends_on,table) => {
    if (this.state.selected_tap == "Google Sheets") {
      this.setState({ selected_model: table,depends_on:depends_on});
    } else {
      var toastID = toast.loading(
        "Fetching available datapoints from " + table + "..."
      );
      this.setState({ loading_datapoints: true,depends_on:depends_on});
      axios
        .post(this.props.editorURL + "/api/sql/get_columns", { table: table })
        .then((response) => {
          var datapoints_options = [];
          for (var data_point in response.data["columns"]) {
            datapoints_options.push({
              label: response.data["label"][data_point],
              value: response.data["columns"][data_point],
            });
          }
          this.setState({ datapoints_options: datapoints_options,selected_model:response.data["table_with_case"] });
        })
        .catch((err) => {
          toast.error(
            "There was an issue loading available datapoints from " +
              table +
              "."
          );
        })
        .finally(() => {
          toast.dismiss(toastID);
          this.setState({ loading_datapoints: false });
        });
    }
  };

  options_oneofs = (name, oneofs) => {
    let oneof = [];
    oneofs.map((oneoff) => {
      //find upper key name where order = 0 inside property key and use that variable to identify what the initial dropdown value is
      let obj = oneoff["properties"];
      let result = Object.keys(obj).find((key) => obj[key]["order"] === 0);
      if (result === undefined) {
        oneof.push({ label: oneoff["title"], value: oneoff["title"] });
      } else {
        oneof.push({
          label: oneoff["title"],
          value: oneoff["properties"][result]["const"],
        });
      }
    });
    return oneof;
  };

  options_jobs = (name, oneofs) => {
    let oneof = [];
    oneofs.map((oneoff) => {
      //find upper key name where order = 0 inside property key and use that variable to identify what the initial dropdown value is
      let obj = oneoff["properties"];
      let result = Object.keys(obj).find((key) => obj[key]["order"] === 0);
      if (result === undefined) {
        oneof.push({ label: oneoff["title"], value: oneoff["title"] });
      } else {
        oneof.push({
          label: oneoff["title"],
          value: oneoff["properties"][result]["const"],
        });
      }
    });
    return oneofs;
  };

  fetchDAGLastRunsTimer = (dag_id, no, bool, time) => {
    if (dag_id)
      setTimeout(() => {
        this.fetchDAGLastRunsTimerMounted &&
          this.fetchDAGLastRuns(dag_id, no, bool);
      }, time);
  };

  getMyModels = (notify_user) => {
    this.setState({ logs: false, getting_models: true });
    if (notify_user) {
      var toastId = toast.loading("Retrieving models from your DWH...");
    }
    axios
      .get(this.props.editorURL + "/api/sql/get_tables")
      .then((response) => {
        var query_options = [];
        for (var key in response.data) {
          if (response.data.hasOwnProperty(key)) {
            query_options.push({
              label: key,
              value: response.data[key]
            });
          }
        }
        this.setState({ options: query_options });
      })
      .catch((err) => {
        toast.error("There was an issue loading your SQL Models.");
      })
      .finally(() => {
        this.setState({ getting_models: false });
        if (notify_user) {
          toast.dismiss(toastId);
        }
      });
  };

  toggleEditModal = (DAG) => {
    this.setState({ selected_job: DAG, modal_edit: !this.state.modal_edit });
  };

  getTapDetails = (
    tap,
    logo,
    destination_group,
    status,
    destinationdefinitionID
  ) => {
    if (status == "Contact Us") {
      toast.info("Please contact Datajolt to set up this push", {
        autoClose: 5000,
      });
    } else {
      var toastID = toast.loading("Retrieving " + tap + " requirements...");
      console.log(
        "Fetching " + tap + " destination specs..." + destinationdefinitionID
      );

      if (this.props.setSelectedPushData) {
        this.props.setSelectedPushData({
          logo,
          selected_tap: tap,
        });
      }
      const SelectedTap = {
        tap: tap,
        destinationdefinitionID: destinationdefinitionID,
      };
      this.setState({
        destinationdefinitionID: destinationdefinitionID,
        subelements: [],
        subelements_index: false,
        destination_group: destination_group,
        logo: logo,
        activeTab: "setup",
        progress: 40,
        loading_spinner: true,
        logs: false,
        selected_tap: tap,
        spec_details: false,
      });
      axios
        .post(config.url.PUSH_URL + "/api/push/get_destination_specs", SelectedTap)
        .then((response) => {
          this.setState({
            fields: response.data["inputs"],
            spec_details: JSON.parse(response.data["specs"]),
          });
          //CHECK FOR BOOLEANS, IF FOUND ADD TO STATE (SO IT DOESNT CRASH IF IT IS NOT TOUCHED BY USER AS ONLY FORM ELEMENTS INTERACTED WITH WILL TRIGGER A STATE UPDATE)
          JSON.parse(response.data["specs"]).map((detail) =>
            detail.type == "boolean"
              ? this.setState({ [detail.name]: false })
              : ""
          );
        })
        .catch((err) => {
          toast.error("There was an issue loading " + tap + " requirements.");
          this.setState({ spec_details: [] });
        })
        .finally(() => {
          toast.dismiss(toastID);
          this.setState({ progress: 100, loading_spinner: false });
        });
    }
  };

  fetchAirflowDags = async () => {
    this.setState({
      editing: false,
      get_dags_status: true,
      DAGs: [],
      loading: true,
      loading_spinner_pushes: true,
      progress: 60,
      activeTab: "all",
    });
    await axios
      .post(config.url.AIRFLOW + "/api/airflow/list_dags", { dag_type: "Push" })
      .then((response) => {
        this.setState({
          loading: false,
          DAGs: response.data["dags_list"],
          dag_pause_statuses: response.data["pauses_status"],
        });
      })
      .catch((err) => {
        toast.error("Error while retrieving scheduled jobs.");
      })
      .finally(() => {
        this.setState({
          progress: 100,
          loading: false,
          loading_spinner_pushes: false,
        });
      });
  };

  
  SelectExistingDestination = (
    destination_alias,
    logo,
    destination_id,
    destination_name,
    details
  ) => {
    if (this.props.setSelectedPushData) {
      this.props.setSelectedPushData({
        logo: logo,
        selected_tap: destination_name,
      });
    }
    this.setState({
      editing: false,
      selected_destination_object: false,
      selected_model: false,
      activeTab: "dataset",
      logo: logo,
      selected_tap: destination_name,
      destination_alias: destination_alias,
      destination_id: destination_id,
      fields: [],
      destination_objects: [],
      fields_mapping: [{ source: "", target: "" }],
      source_link_label: false,
      destination_link_label: false,
      push_type: false,
      selected_datapoint_destination: false,
      source_link: false,
      destination_link: false,
      selected_datapoint_source: false,
    });
    if (destination_id.length < 20) {
      this.getDestinationObjects(
        destination_name,
        destination_alias
      );
    } else {
      this.getDestinationObjectsAirbyte(destination_name, destination_id);
    }
  };

  applyEdit = () => {
    var toastId = toast.loading("Saving your push details...");
    for (let value of this.state.fields_mapping) {
      if (value["source"] == "" || value["target"] == "") {
        toast.error("You cannot have an empty mapping.");
        toast.dismiss(toastId);
        this.setState({ progress: 100, testing: false });
        return;
      }
    }
    this.setState({ progress: 40, testing: true });
    axios
      .post(config.url.PUSH_URL + "/api/push/edit_push", {
        dag_id: this.state.push_edit_dag_id,
        mappings: this.state.fields_mapping,
      })
      .then((response) => {
        this.props.toggleCreatePushForm();
        this.props.router.navigate("/jobs/push");
        toast.success("Push successfully updated.");
      })
      .catch((err) => {
        this.props.toggleCreatePushForm();
        toast.error("Error while editing your push.");
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({ progress: 100, testing: false });
      });
  };

  editPush = (dag_id, logo, subtype) => {
    // this.getMyModels(false);
    this.setState({
      source_link_label: false,
      destination_link_label: false,
      source_link: false,
      destination_link: false,
      fields: [],
      push_fields_options: [],
      destination_objects: [],
      push_edit_dag_id: dag_id,
      push_type: false,
      progress: 40,
      editing: true,
      activeTab: "dataset",
      logo: logo,
      selected_tap: subtype,
      destination_id: "pending",
    });
    if (this.props.setSelectedPushData) {
      this.props.setSelectedPushData({
        logo: logo,
        selected_tap: subtype,
      });
    }
    var toastId = toast.loading("Retrieving your push details...");
    axios
      .post(config.url.PUSH_URL + "/api/push/get_push_details", { dag_id: dag_id })
      .then((response) => {
        this.setState({
          fields_mapping: response.data["mappings"],
          selected_destination_object:
            response.data["selected_destination_object"],
          selected_model: response.data["selected_model"],
          push_alias: response.data["alias"],
          destination_id: response.data["destination_id"],
          destination_alias: response.data["destination_name"],
          destination_name: response.data["destination_type"],
          push_type: response.data["push_type"],
          source_link: response.data["source_link"],
          source_link_label: response.data["source_link_label"],
          destination_link: response.data["destination_link"],
          destination_link_label: response.data["destination_link_label"],
        });

        // this.getColumns(response.data["depends_on"],response.data["selected_model"]);
        // display mappings properly
        // display links properly
        // if (this.state.destination_id.length < 20) {
        //   this.getDestinationObjects(
        //     this.state.destination_name,
        //     this.state.destination_alias
        //   );
        // } else {
        //   this.getDestinationObjectsAirbyte(
        //     this.state.destination_name,
        //     this.state.destination_id
        //   );
        // }
      })
      .catch((err) => {
        toast.error("Error while retrieving push details.");
        this.setState({ activeTab: "all" });
        this.props.router.navigate("/jobs/push");
        this.props.toggleCreatePushForm();
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({
          progress: 100,
          loading: false,
          loading_spinner_pushes: false,
        });
      });
  };

  saveDestination = (tap) => {
    this.setState({ progress: 40, saving: true });
    var connection_details = [];
    for (var field in this.state.fields) {
      var field_name = this.state.fields[field];
      connection_details.push({
        [this.state.fields[field]]: this.state[field_name],
      });
    }
    // save destination if this is not an airbyte tap, otherwise skip call
    if (this.state.destination_id == "") {
      const savetab = {
        tap_name: tap,
        alias: this.state.destination_alias,
        secrets: connection_details,
      };
      axios
        .post(config.url.PUSH_URL + "/api/push/save_destination", savetab)
        .then((response) => {
          this.setState({
            selected_datapoint_destination: false,
            source_link: false,
            destination_link: false,
            selected_datapoint_source: false,
            streams: [],
            progress: 100,
            activeTab: "dataset",
            color: "primary",
            destination_id: response.data["destinationId"],
            secrets: connection_details,
          });
          toast.success("Credentials saved successfully.");
          this.getDestinationObjects(
            tap,
            this.state.destination_alias
          );
        })
        .catch((err) => {
          toast.error("There was an internal issue saving credentials.");
        })
        .finally(() => {
          this.setState({ progress: 100, saving: false });
        });
    } else {
      toast.success("Credentials saved successfully.");
      this.setState({ activeTab: "dataset" });
      this.getDestinationObjectsAirbyte(tap, this.state.destination_id);
    }
  };

  getPushTypes = (tap) => {
    this.setState({ getting_push_types: true });
    axios
      .post(config.url.PUSH_URL + "/api/push/get_push_types", { destination: tap })
      .then((response) => {
        this.setState({ push_types: response.data["push_types"] });
      })
      .finally(() => {
        this.setState({ getting_push_types: false });
      });
  };

  getDestinationObjects = (destination, destination_alias) => {
    console.log("Getting destination objects for " + destination);
    var toastId = toast.loading(
      "Retrieving objects from " +
        destination +
        " (this can take up to 30 seconds depending on your destination connection)..."
    );
    this.setState({ loading_destination_objects: true });
    this.getPushTypes(destination);
    axios
      .post(config.url.PUSH_URL + "/api/push/get_destination_objects", {
        destination_alias: destination_alias,
        destination: destination
      })
      .then((response) => {
        var destination_objects = [];
        for (var field in response.data["fields"]) {
          destination_objects.push({
            label: Object.keys(response.data["fields"][field]),
            value: Object.keys(response.data["fields"][field]),
          });
        }
        this.setState({
          fields: response.data["fields"],
          destination_objects: destination_objects,
        });
        if (this.state.editing) {
          this.getDestinationFields([this.state.selected_destination_object]);
        }
      })
      .catch((err) => {
        toast.error(
          "There was an issue getting objects from your destination."
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({ loading_destination_objects: false });
      });
  };

  getDestinationObjectsAirbyte = (destination, destinationId) => {
    var toastId = toast.loading(
      "Retrieving objects from your source & destination, this can take up to 30s depending on their connection..."
    );
    this.setState({
      fields: [],
      destination_objects: [],
      loading_destination_objects: true
    });
    this.getPushTypes(destination);
    axios
      .post(config.url.PUSH_URL + "/api/push/get_destination_objects_airbyte", {
        destinationId: destinationId,
      })
      .then((response) => {
        var destination_objects = [];
        for (var field in response.data["fields"]) {
          destination_objects.push({
            label: Object.keys(response.data["fields"][field]),
            value: Object.keys(response.data["fields"][field]),
          });
        }
        this.setState({
          streams: [],
          color: "primary",
          fields: response.data["fields"],
          destination_objects: destination_objects
        });
        if (this.state.editing) {
          this.getDestinationFields([this.state.selected_destination_object]);
        }
      })
      .catch((err) => {
        toast.error(
          "There was an issue getting objects from your PG destination."
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({
          loading_destination_objects: false,
          progress: 100,
          saving: false,
        });
      });
  };

  getDestinationFields = (object) => {
    this.setState({ selected_destination_object: object });
    if (this.state.selected_tap != "Google Sheets") {
      var push_fields = [];
      var arr = this.state.fields.find((obj) => obj.hasOwnProperty(object[0]))[
        object[0]
      ];
      for (var field in arr) {
        push_fields.push({
          label: arr[field]["label"],
          value: arr[field]["value"],
        });
      }
      this.setState({ push_fields_options: push_fields });
    }
  };

  refreshDestinationCatalog = (tap, destination_alias) => {
    var toastId = toast.loading(
      "Retrieving objects from " +
        tap +
        " (this can take up to 30 seconds depending on your destination connection)..."
    );
    this.setState({ loading_destination_objects: true, progress: 40 });
    axios
      .post(config.url.PUSH_URL + "/api/push/refresh_destination_catalog", {
        destination_alias: destination_alias,
        destination: tap,
      })
      .then((response) => {
        var destination_objects = [];
        for (var field in response.data["fields"]) {
          destination_objects.push({
            label: Object.keys(response.data["fields"][field]),
            value: Object.keys(response.data["fields"][field]),
          });
        }
        this.setState({
          fields: response.data["fields"],
          destination_objects: destination_objects,
        });
      })
      .catch((err) => {
        toast.error(
          "There was an issue getting objects from your destination."
        );
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({ loading_destination_objects: false, progress: 100 });
      });
  };

  toggle_modal_cron = () => {
    this.setState({ modal_cron: !this.state.modal_cron });
  };

  testDestination = () => {
    if (!/[a-zA-Z]/.test(this.state.destination_alias[0])) {
      toast.info("Alias has to start with a letter.");
    } else if (
      /[`!@#$£%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        this.state.destination_alias
      )
    ) {
      toast.info('Alias cannot have any special characters except "_".');
    } else if (
      this.state.mydestinations
        .map((obj) => obj.alias)
        .includes(this.state.destination_alias)
    ) {
      toast.info(
        "A destination called " +
          this.state.destination_alias +
          " already exists"
      );
    } else if (this.state.destination_alias == "") {
      toast.info("Destination alias cannot be empty.");
    } else {
      this.setState({
        selected_destination_object: false,
        selected_model: false,
      });
      // need to get inputs from state --> use field names to retrieve from state?
      //for airbyte inputs
      if (this.state.destinationdefinitionID) {
        var connection_details = this.state;
      }
      //for custom ones
      else {
        var connection_details = [];
        for (var field in this.state.fields) {
          var field_name = this.state.fields[field];
          connection_details.push({
            [this.state.fields[field]]: this.state[field_name],
          });
        }
      }
      var toastId = toast.loading(
        "Testing your connection to " + this.state.selected_tap + " ..."
      );
      this.setState({ progress: 40, testing: true });
      axios
        .post(config.url.PUSH_URL + "/api/push/test_destination", {
          selected_tap: this.state.selected_tap,
          destination_alias: this.state.destination_alias,
          inputs: connection_details,
          destinationdefinitionId: this.state.destinationdefinitionID,
        })
        .then((response) => {
          toast.success(
            "Connected to " + this.state.selected_tap + " successfully.",
            { autoClose: 5000 }
          );
          this.setState({
            color: "success",
            destination_id: response.data["destinationId"],
            sourceID: response.data["sourceId"],
          });
        })
        .catch((err) => {
          if (err.response && err.response.status === 501) {
            toast.error(err.response.data["error"], { autoClose: 15000 });
          } else {
            toast.error(
              "Unable to connect to " + this.state.selected_tap + ".",
              { autoClose: 5000 }
            );
          }
        })
        .finally(() => {
          toast.dismiss(toastId);
          this.setState({ progress: 100, testing: false });
        });
    }
  };

  createPush = () => {
    if (
      this.state.selected_tap != "Google Sheets" &&
      this.state.selected_tap != "Copper" &&
      this.state.push_type != "Append" &&
      this.state.push_type != "Delete" &&
      (!this.state.push_type ||
        !this.state.source_link ||
        !this.state.destination_link)
    ) {
      toast.info(
        "Unable to create your data push. A link or push type is missing."
      );
    } else if (
      /[^a-zA-Z0-9_]/.test(this.state.push_alias)
    ) {
      toast.info('Push alias cannot have any special characters except "_".');
    } else if (
      this.props.dagsList
        .map((obj) => obj.alias)
        .includes(this.state.push_alias)
    ) {
      toast.info(
        "A push called " +
          this.state.push_alias +
          " already exists"
      );
    } 
    else if (
      this.state.selected_tap == "Google Sheets" &&
      (!this.state.selected_model ||
        !this.state.selected_destination_object ||
        !this.state.push_type)
    ) {
      toast.info(
        "Unable to create your data push. Please double check inputs."
      );
    } else if (
      this.state.selected_tap != "Google Sheets" &&
      this.state.fields_mapping.some(
        (obj) => obj.source === "" || obj.target === ""
      )
    ) {
      toast.info("You cannot leave a field mapping empty.");
    } else {
      var toastId = toast.loading("Creating your push, this could take up to a minute...", { autoClose: false });
      this.setState({ creating_push: true });
     axios
        .post(config.url.PUSH_URL + "/api/push/create_push", {
          destination_id: this.state.destination_id,
          destination_alias: this.state.destination_alias,
          push_type: this.state.push_type,
          mappings: this.state.fields_mapping,
          source_link: this.state.source_link,
          destination_link: this.state.destination_link,
          selected_model: this.state.selected_model,
          selected_destination_object: this.state.selected_destination_object,
          alias: this.state.push_alias,
          selected_push: this.state.selected_tap,
          depends_on: this.state.depends_on
        })
        .then((response) => {
          toast.success("Push created successfully 🎉");
          this.props.toggleCreatePushForm();
          this.props.router.navigate("/jobs/push");
          this.props.fetchAirflowDags();
        })
        .catch((err) => {
          toast.error("Error creating your push.");
        })
        .finally(() => {
          this.setState({ creating_push: false });
          toast.dismiss(toastId);
        });
      }
  };


  gotoSchedulePush = () => {
    if (
      this.state.selected_tap != "Google Sheets" &&
      this.state.selected_tap != "Copper" &&
      this.state.push_type != "Append" &&
      this.state.push_type != "Delete" &&
      (!this.state.push_type ||
        !this.state.source_link ||
        !this.state.destination_link)
    ) {
      toast.error(
        "Unable to create your data push. A link or push type is missing."
      );
    } else if (
      this.state.selected_tap == "Google Sheets" &&
      (!this.state.selected_model ||
        !this.state.selected_destination_object ||
        !this.state.push_type)
    ) {
      toast.error(
        "Unable to create your data push. Please double check inputs."
      );
    } else if (
      this.state.selected_tap != "Google Sheets" &&
      this.state.fields_mapping.some(
        (obj) => obj.source === "" || obj.target === ""
      )
    ) {
      toast.error("You cannot leave a field mapping empty.");
    } else {
      this.setState({ activeTab: "schedule" });
    }
  };

  testPush = () => {
    if (
      this.state.selected_tap != "Google Sheets" &&
      (!this.state.selected_datapoint_source ||
        !this.state.selected_datapoint_destination ||
        !this.state.push_type ||
        !this.state.source_link ||
        !this.state.destination_link)
    ) {
      toast.error(
        "Unable to create your data push. Please double check inputs."
      );
    } else if (
      this.state.selected_tap == "Google Sheets" &&
      (!this.state.selected_model ||
        !this.state.selected_destination_object ||
        !this.state.push_type)
    ) {
      toast.error(
        "Unable to create your data push. Please double check inputs."
      );
    } else {
      if (this.state.selected_tap != "Google Sheets") {
        var toastId = toast.loading(
          "Testing pushing " +
            this.state.selected_datapoint_source +
            " to " +
            this.state.selected_destination_object +
            " in " +
            this.state.selected_tap +
            " ..."
        );
      } else {
        var toastId = toast.loading(
          "Testing pushing " +
            this.state.selected_model +
            " to " +
            this.state.selected_destination_object +
            " in " +
            this.state.selected_tap +
            " ..."
        );
      }
      this.setState({ progress: 40, testing: true, color: "primary" });
      axios
        .post(config.url.PUSH_URL + "/api/push/test_push", {
          gs_url: this.state.gs_url,
          push_type: this.state.push_type,
          destination: this.state.selected_tap,
          model: this.state.selected_model,
          source_fields: this.state.selected_datapoint_source,
          source_unique_id: this.state.source_link,
          destination_object: this.state.selected_destination_object,
          destination_field: this.state.selected_datapoint_destination,
          destination_unique_id: this.state.destination_link,
        })
        .then((response) => {
          toast.success(
            "Pushed to " + this.state.selected_tap + " successfully.",
            { autoClose: 5000 }
          );
          this.setState({
            color: "success",
            json_update_endpoint: response.data["json_update_endpoint"],
          });
        })
        .catch((err) => {
          toast.error(
            "There was an issue pushing to " +
              this.state.selected_tap +
              ". Try again."
          );
        })
        .finally(() => {
          toast.dismiss(toastId);
          this.setState({ progress: 100, testing: false });
        });
    }
  };

  handleDeleteSuccess = () => {
    this.getMyDestinations("newpush");
  };


  renderCloseFormButton = (isLastElm = true) => (
    <button
      className="close-push-tap-form"
      onClick={this.handleClosePushForm}
      style={{ marginLeft: isLastElm ? "auto" : "unset" }}
    >
      <Icon.X size={18} className="close-push-tap-form-icon" />
      Close
    </button>
  );

  getMyDestinations = (activeTab) => {
    this.setState({
      progress: 60,
      mytaps: [],
      mydestinations: [],
      loading_my_destinations: true,
      activeTab: activeTab,
    });
    axios
      .get(config.url.PUSH_URL + "/api/push/mydestinations")
      .then((response) => {
        this.setState({ mydestinations: response.data });
      })
      .catch((err) => {
        toast.error("There was an issue listing your destinations taps.");
      })
      .finally(() => {
        this.setState({ progress: 100, loading_my_destinations: false });
      });
  };

  listConnectors = (tap_type) => {
    this.getMyDestinations("newpush");
    this.setState({
      schedule_frequency: false,
      push_alias: "",
      datasources: [],
      loading_connectors: true,
      get_dags_status: false,
      color: "primary",
      progress: 40,
      replication_disabled: true,
      loading_spinner: true,
      activeTab: "newpush",
    });
    axios
      .post(config.url.TAPS_URL + "/api/taps/list_available_connectors", { type: "destinations" })
      .then((response) => {
        this.setState({
          saving_settings: false,
          selected_tap: false,
          progress: 100,
          datasources: response.data,
        });
        this.getMyModels(false);
      })
      .catch((err) => {
        toast.error("There was an internal issue listing taps.");
      })
      .finally(() => {
        this.setState({
          loading_connectors: false,
          progress: 100,
          loading_spinner: false,
        });
      });
  };

  toggleLoseCreatePushFormProgressModal = () =>
    this.setState({
      modal_lose_create_push_progress:
        !this.state.modal_lose_create_push_progress,
    });

  handleClosePushForm = () => {
    if (this.state.activeTab === "newpush") {
      this.props.toggleCreatePushForm();
    } else {
      this.toggleLoseCreatePushFormProgressModal();
    }
  };

  fetchDAGLastRuns = (dag_id, page_limit, toastId) => {
    this.setState({ fetching_last_runs: true });
    axios
      .post(config.url.AIRFLOW + "/api/airflow/get_dag_runs", {
        dag_id: dag_id,
        page_limit: page_limit,
      })
      .then((response) => {
        if (!response.data["runs"].length) {
          this.setState({ historical_runs_available: false });
        } else {
          if (page_limit > 1) {
            this.setState({
              historical_runs_available: true,
              historical_dag_runs: {
                ...this.state.historical_dag_runs,
                [dag_id]: response.data["runs"],
              },
            });
          } else {
            this.setState({
              dag_runs: {
                ...this.state.dag_runs,
                [dag_id]: response.data["runs"],
              },
            });
          }
          if (
            (response.data["runs"][0].state == "running" ||
              response.data["runs"][0].state == "queued") &&
            this.state.get_dags_status
          ) {
            console.log(dag_id + " is running, pinging again...");
            this.fetchDAGLastRunsTimer(dag_id, 1, false, 10000);
          } else {
            this.setState({
              running_dags: this.state.running_dags.filter(
                (dag) => dag != dag_id
              ),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while fetching job history.");
      })
      .finally(() => {
        this.setState({ fetching_last_runs: false, progress: 100 });
        toast.dismiss(toastId);
      });
  };

  handleStateUpdate = (newState) => {
    this.setState(newState);
  };

  updateFieldsMapping = (type, index, e) => {
    var fields_mapping = this.state.fields_mapping;
    fields_mapping[index][type] = e.value;
    fields_mapping[index][type + "_label"] = e.label;
    this.setState({ fields_mapping: fields_mapping });
  };

  render() {
    return (
      <div id="push-tap-creator-form">
        <div className="container-fluid py-3">
          <div style={{ display: "flex" }} className="push-tap-collector-form">
            <StepIndicator
              steps={
                this.props.isEditingPush
                  ? ["Settings", "Data"]
                  // : ["Settings", "Data", "Settings"]
                  : ["Destination", "Settings"]
                }
              activeStep={
                this.state.activeTab === "setup"
                  ? 1
                  : this.state.activeTab === "dataset"
                  ? 2
                  : this.state.activeTab === "schedule"
                  ? 3
                  : 0
              }
            />

            <div
              style={{
                width: "80%",
                position: "relative",
              }}
            >
              <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
                <Tab.Content>
                  <CSSTransition
                    in={this.state.activeTab === "newpush"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="newpush">
                      <div className="card-body push-tap-form">
                        <div className="card form-step-wrapper">
                          <h4 className="d-flex gap-2 align-items-center horizontal-spacing">
                            <b>Destination Connectors </b>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-left`}>
                                  <Popover.Header as="h3">
                                    {"What are Destination Connectors?"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    Destination Connectors are integrations that
                                    you can use to push data to.
                                    <br />
                                    <br />
                                    One destination can have several data
                                    pushes.
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>{" "}
                            {this.renderCloseFormButton()}
                          </h4>

                          <b
                            style={{
                              display: "block",
                              marginLeft: "20px",
                              width: "100%",
                            }}
                          >
                            Your Existing Destinations (
                            {this.state.mydestinations.length})
                          </b>
                          <div className="destinations-push connectors-container">
                            {!this.state.loading_my_destinations ? (
                              this.state.mydestinations.map((mydestination) => (
                                <div
                                  onClick={() =>
                                    this.SelectExistingDestination(
                                      mydestination.alias,
                                      mydestination.logo,
                                      mydestination.id,
                                      mydestination.name,
                                      mydestination.details
                                    )
                                  }
                                  className="w-dyn-item"
                                  style={{
                                    display: "inline-block",
                                    marginleft: "5px",
                                  }}
                                >
                                  <div className="w-embed">
                                    <input
                                      type="hidden"
                                      className="jetboost-list-item"
                                    />
                                  </div>
                                  <ConnectorCardPush
                                      datasource={mydestination}
                                      onDeleteSuccess={this.handleDeleteSuccess}
                                    />
                                </div>
                              ))
                            ) : (
                              <center className="connector-spinner">
                                <DatajoltSpinner />
                              </center>
                            )}
                          </div>
                          <b
                            style={{
                              marginLeft: "20px",
                              display: "block",
                              width: "100%",
                              marginTop: "50px",
                            }}
                          >
                            Available Connectors (
                            {this.state.datasources.length})
                          </b>
                          <div className="destinations-push connectors-container">
                            {!this.state.loading_connectors ? (
                              this.state.datasources.length > 0 &&
                              this.state.datasources.map((datasource) => (
                                <div
                                  onClick={() =>
                                    this.getTapDetails(
                                      datasource.value,
                                      datasource.logo,
                                      datasource.destination_group,
                                      datasource.status,
                                      datasource.destinationdefinitionID
                                        ? datasource.destinationdefinitionID
                                        : false
                                    )
                                  }
                                  className="w-dyn-item"
                                  style={{
                                    display: "inline-block",
                                  }}
                                >
                                  <div className="w-embed">
                                    <input
                                      type="hidden"
                                      className="jetboost-list-item"
                                    />
                                  </div>
                                  <ConnectorCardPush datasource={datasource} />
                                </div>
                              ))
                            ) : (
                              <center className="connector-spinner">
                                <DatajoltSpinner />
                              </center>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "setup"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="setup">
                      <div className="card-body push-tap-form">
                        <div className="card form-step-wrapper">
                          <h4 className="d-flex gap-2 align-items-center horizontal-spacing">
                            <b>Destination Details </b>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-left`}>
                                  <Popover.Header as="h3">
                                    {"What are Destinations?"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    Destinations are saved integrations that you
                                    can reuse to push data to.
                                    <br />
                                    <br />
                                    One destination can have several data
                                    pushes.
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>{" "}
                            {this.renderCloseFormButton()}
                          </h4>

                          {this.state.spec_details.length > 0 ? (
                            <Col lg="12">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      <b style={{ marginLeft: "20px" }}>
                                        Destination Alias
                                      </b>
                                    </Form.Label>
                                    <Form.Control
                                      style={{ width: "96%", marginLeft: "3%" }}
                                      type="text"
                                      onChange={(e) =>
                                        this.setState({
                                          destination_alias: e.target.value,
                                        })
                                      }
                                      placeholder="Your destination alias"
                                    />
                                  </Form.Group>
                                  <SpecDetailsForm
                                    spec_details={this.state.spec_details}
                                    onStateUpdate={this.handleStateUpdate}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <Button
                                    style={{
                                      marginLeft: "20px",
                                      marginBottom: "20px",
                                    }}
                                    type="submit"
                                    variant={this.state.color}
                                    size="lg"
                                    disabled={
                                      this.state.testing
                                        ? true
                                        : this.state.color == "success"
                                        ? true
                                        : false
                                    }
                                    onClick={this.testDestination}
                                  > <Icon.Repeat size={16} />&nbsp;
                                    {this.state.color == "primary"
                                      ? this.state.testing
                                        ? "Testing..."
                                        : "Test & Save"
                                      : this.state.color == "success"
                                      ? "Success"
                                      : ""}
                                    &nbsp;
                                    {this.state.color == "success" ? (
                                      <Icon.Check />
                                    ) : (
                                      ""
                                    )}
                                    {this.state.testing ? (
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                  {this.state.color == "success" ? (
                                    <Button
                                      style={{
                                        marginLeft: "20px",
                                        marginBottom: "20px",
                                      }}
                                      type="submit"
                                      variant="primary"
                                      size="lg"
                                      disabled={this.state.saving}
                                      onClick={() =>
                                        this.saveDestination(
                                          this.state.selected_tap,
                                          1
                                        )
                                      }
                                    ><Icon.Save />&nbsp;
                                      {this.state.saving
                                        ? "Saving... "
                                        : "Save Destination"}
                                      {this.state.saving ? (
                                        <Spinner
                                          as="span"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <br />
                              </Row>
                            </Col>
                          ) : (
                            ""
                          )}
                          {this.state.loading_spinner ? (
                            <div className="row">
                              <br></br>
                              <center>
                                <DatajoltSpinner />
                              </center>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "dataset"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="dataset">
                      <div className="card-body push-tap-form dataset-step push-form">
                        <div className="card form-step-wrapper">
                          <h4 className="d-flex gap-2 align-items-center horizontal-spacing">
                            <b>
                              {!this.state.editing
                                ? "Select data to push to " +
                                  this.state.selected_tap
                                : "Review your push setup" }{" "}
                            </b>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-left`}>
                                  <Popover.Header as="h3">
                                    {"What are push types?"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    You can select the push type you need in
                                    this screen, it depends on your destination.
                                    <br />
                                    <br />
                                    You can then use mappings to map fields from
                                    your source model to your destination.
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>
                            {this.state.destination_id.length < 20 &&
                            this.state.destination_id != "pending" &&
                            this.state.selected_tap != "Google Sheets" ? (
                              <Button
                                style={{ marginLeft: "auto" }}
                                type="submit"
                                variant="secondary"
                                size="lg"
                                disabled={
                                  this.state.loading_destination_objects || this.state.editing
                                }
                                onClick={() =>
                                  this.refreshDestinationCatalog(
                                    this.state.selected_tap,
                                    this.state.destination_alias
                                  )
                                }
                              >
                                &nbsp;&nbsp;Refresh destination
                                catalog&nbsp;&nbsp;
                                <Icon.RefreshCw size={16} />
                                &nbsp;&nbsp;
                              </Button>
                            ) : (
                              ""
                            )}{" "}
                            {this.renderCloseFormButton(!(
                              this.state.destination_id.length < 20 &&
                              this.state.destination_id != "pending" &&
                              this.state.selected_tap != "Google Sheets"
                            ))}
                          </h4>
                          <Col lg="12">
                          <Row>
                            <h6 style={{ marginLeft: "20px" }}>
                              <b>Push Details</b>
                            </h6>
                            <Col md={5}>
                              <Form.Label>
                                <b style={{ marginLeft: "20px" }}>Push Alias</b>
                              </Form.Label>
                              <Form.Control
                                style={{ width: "100%", marginLeft: "20px", height: "38px" }}
                                type="text"
                                onChange={(e) =>
                                  this.setState({
                                    push_alias: e.target.value,
                                  })
                                }
                                value={this.state.push_alias}
                                placeholder={this.state.editing ? "Loading..." : "Your push alias"}
                                className="push-alias-input"
                                disabled={this.state.editing}
                              />
                              <div
                                style={{ marginLeft: "20px" }}
                                dangerouslySetInnerHTML={{
                                  __html: "This is the alias of your push. It needs to be unique.",
                                }}
                              ></div>
                            </Col>
                            <Col md={1}>
                                    <div
                                      style={{
                                        marginTop: "50px",
                                        marginLeft: "50px",
                                      }}
                                    >
                                    </div>
                                  </Col>

                            <Col md={5}>
                              <Form.Label>
                                <b style={{ marginLeft: "20px" }}>Push Type</b>
                              </Form.Label>
                              <Select
                                maxMenuHeight={150}
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                    width: '100%', // Adjust the width as needed
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    height: "38px", // Adjust the height as needed
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    marginLeft: "20px !important",
                                    marginTop: "4px"
                                  })
                                }}
                                className={
                                  this.state.editing
                                    ? "react-select-container disabled"
                                    : "react-select-container"
                                }
                                value={{
                                  value: this.state.push_type,
                                  label: this.state.push_type,
                                }}
                                placeholder="Select a push type..."
                                isDisabled={
                                  this.state.editing ? true : false
                                }
                                classNamePrefix="react-select"
                                name="dataset_select"
                                options={this.state.push_types}
                                onChange={(e) =>
                                  this.setState({ push_type: e.value })
                                }
                              />
                              <div
                                style={{ marginLeft: "20px" }}
                                dangerouslySetInnerHTML={{
                                  __html: "This is the push type to your destination.",
                                }}
                              ></div>
                            </Col>
                          </Row>

                            <hr className="horizontal-spacing" />

                            {this.state.push_type ? (
                              <>
                                <Row>
                                  <Col md={5}>
                                    <h6 style={{ marginLeft: "20px" }}>
                                      <b>Source</b>
                                    </h6>
                                    <Form.Label>
                                      <b style={{ marginLeft: "20px" }}>
                                        2. Select a DBT model 
                                      </b>
                                    </Form.Label>
                                    <Select
                                      maxMenuHeight={150}
                                      styles={{
                                        container: (provided) => ({
                                          ...provided,
                                          width: '100%', // Adjust the width as needed
                                        }),
                                        control: (provided) => ({
                                          ...provided,
                                          minWidth: '100%', // Adjust the minimum width as needed
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          width: '100% !important',
                                          marginLeft: "20px !important",
                                          marginTop: 4,
                                        }),
                                      }}
                                      className={
                                        this.state.editing ||
                                        this.state.getting_models
                                          ? "react-select-container disabled"
                                          : "react-select-container"
                                      }
                                      classNamePrefix="react-select"
                                      name="dataset_select"
                                      isDisabled={
                                        this.state.editing
                                          ? true
                                          : this.state.getting_models
                                      }
                                      value={
                                        this.state.depends_on
                                          ? {
                                              value: this.state.depends_on,
                                              label: this.state.depends_on,
                                            }
                                          : null
                                      }
                                      placeholder={this.state.editing ? this.state.selected_model :
                                        this.state.getting_models ? (
                                          <>
                                            Loading...&nbsp;&nbsp;
                                            <Spinner
                                              as="span"
                                              variant="light"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                              animation="border"
                                            />
                                          </>
                                        ) : (
                                          "Select a DBT model..."
                                        )
                                      }
                                      options={this.state.options}
                                      onChange={(e) => this.getColumns(e.label,e.value)}
                                    />
                                    <div
                                      style={{ marginLeft: "20px" }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "The data will be pushed to your destination after every run of the <a href='/jobs/overview' target='_blank'>selected model</a>.",
                                      }}
                                    ></div>
                                  </Col>
                                  <Col md={1}>
                                    <div
                                      style={{
                                        marginTop: "50px",
                                        marginLeft: "50px",
                                      }}
                                    >
                                      <Icon.ArrowRightCircle />
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <h6 style={{ marginLeft: "20px" }}>
                                      <b>Destination</b>
                                    </h6>
                                    <Form.Label>
                                      <b style={{ marginLeft: "20px" }}>
                                        3. Select a dataset from your
                                        Destination Object
                                      </b>
                                    </Form.Label>
                                    <Select
                                      maxMenuHeight={150}
                                      style={{ width: "10%" }}
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          marginLeft: "20px !important",
                                          marginTop: 4,
                                        }),
                                      }}
                                      className={
                                        this.state.editing ||
                                        this.state.loading_destination_objects
                                          ? "react-select-container disabled"
                                          : "react-select-container"
                                      }
                                      isDisabled={
                                        this.state.editing
                                          ? true
                                          : this.state
                                              .loading_destination_objects
                                      }
                                      classNamePrefix="react-select"
                                      name="dataset_select"
                                      value={
                                        this.state.selected_destination_object
                                          ? {
                                              value:
                                                this.state
                                                  .selected_destination_object,
                                              label:
                                                this.state
                                                  .selected_destination_object,
                                            }
                                          : null
                                      }
                                      placeholder={
                                        this.state
                                          .loading_destination_objects ? (
                                          <>
                                            Loading...&nbsp;&nbsp;
                                            <Spinner
                                              as="span"
                                              variant="light"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                              animation="border"
                                            />
                                          </>
                                        ) : (
                                          "Select a destination object..."
                                        )
                                      }
                                      options={this.state.destination_objects}
                                      onChange={(e) =>
                                        this.getDestinationFields(e.value)
                                      }
                                    />
                                    <div
                                      style={{ marginLeft: "20px" }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "This is the object to push data to in your destination, like a table, a spreadsheet tab, or a salesforce object (Accounts, etc)",
                                      }}
                                    ></div>
                                  </Col>
                                </Row>
                                {this.state.selected_tap != "Google Sheets" ? (
                                  <>
                                    {this.state.push_type == "Upsert" ||
                                    this.state.push_type == "Update" ||
                                    (this.state.push_type == "Create" &&
                                      this.state.selected_tap != "Copper") ? (
                                      <>
                                        <hr className="horizontal-spacing" />

                                        <h6 style={{ marginLeft: "20px" }}>
                                          <b>Link</b>
                                        </h6>
                                        <Row>
                                          <Col md={5}>
                                            <Form.Label>
                                              <b style={{ marginLeft: "20px" }}>
                                                4. Source ID
                                              </b>
                                            </Form.Label>
                                            <Select
                                              maxMenuHeight={150}
                                              styles={{
                                                container: (provided) => ({
                                                  ...provided,
                                                  width: '100%', // Adjust the width as needed
                                                }),
                                                control: (provided) => ({
                                                  ...provided,
                                                  minWidth: '100%', // Adjust the minimum width as needed
                                                }),
                                                menu: (provided) => ({
                                                  ...provided,
                                                  width: '100% !important',
                                                  marginLeft: "20px !important",
                                                  marginTop: 4,
                                                }),
                                              }}
                                              className={
                                                this.state.editing ||
                                                this.state.getting_models
                                                  ? "react-select-container disabled"
                                                  : "react-select-container"
                                              }
                                              classNamePrefix="react-select"
                                              name="dataset_select"
                                              isDisabled={
                                                this.state.editing
                                                  ? true
                                                  : !this.state.selected_model
                                              }
                                              placeholder={
                                                this.state
                                                  .loading_datapoints ? (
                                                  <>
                                                    Loading...&nbsp;&nbsp;
                                                    <Spinner
                                                      as="span"
                                                      variant="light"
                                                      size="sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                      animation="border"
                                                    />
                                                  </>
                                                ) : (
                                                  "Select a source link..."
                                                )
                                              }
                                              value={{
                                                value: this.state.source_link,
                                                label:
                                                  this.state.source_link_label,
                                              }}
                                              options={
                                                this.state.datapoints_options
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  source_link: e.value,
                                                  source_link_label: e.label,
                                                })
                                              }
                                            />
                                            <div
                                              style={{ marginLeft: "20px" }}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  "This is the unique ID in your source that will link to your destination, like a Customer ID.",
                                              }}
                                            ></div>
                                          </Col>
                                          <Col md={1}>
                                            <div
                                              style={{
                                                marginTop: "20px",
                                                marginLeft: "50px",
                                              }}
                                            >
                                              <Icon.Link />
                                            </div>
                                          </Col>
                                          <Col md={5}>
                                            <Form.Label>
                                              <b style={{ marginLeft: "20px" }}>
                                                5. Destination ID
                                              </b>
                                            </Form.Label>
                                            <Select
                                              maxMenuHeight={150}
                                              style={{ width: "10%" }}
                                              className={
                                                this.state.editing ||
                                                this.state.getting_models
                                                  ? "react-select-container disabled"
                                                  : "react-select-container"
                                              }
                                              classNamePrefix="react-select"
                                              name="dataset_select"
                                              isDisabled={
                                                this.state.editing
                                                  ? true
                                                  : this.state
                                                      .selected_destination_object
                                                  ? false
                                                  : true
                                              }
                                              placeholder={
                                                this.state
                                                  .selected_destination_object
                                                  ? "Select a destination link..."
                                                  : "Select an object from your destination in step 2."
                                              }
                                              value={{
                                                value:
                                                  this.state.destination_link,
                                                label:
                                                  this.state
                                                    .destination_link_label,
                                              }}
                                              options={
                                                this.state.push_fields_options
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  destination_link: e.value,
                                                  destination_link_label:
                                                    e.label,
                                                })
                                              }
                                            />
                                            <div
                                              style={{ marginLeft: "20px" }}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  "This is the unique ID in your destination that will link to your source, like a Customer ID.",
                                              }}
                                            ></div>
                                          </Col>
                                        </Row>
                                        <br />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <hr className="horizontal-spacing" />

                                    <h6 style={{ marginLeft: "20px" }}>
                                      <b>Fields Mapping</b>
                                    </h6>
                                    <Row>
                                      <Col md={5} className="dataset-mapper">
                                        <Form.Label>
                                          <b style={{ marginLeft: "20px" }}>
                                            {this.state.push_type != "Append" &&
                                            this.state.push_type != "Delete" &&
                                            this.state.selected_tap != "Copper"
                                              ? "6"
                                              : "4"}
                                            . Select a data point from your
                                            selected model
                                          </b>
                                        </Form.Label>
                                        {this.state.push_type == "Delete" ? (
                                          <div
                                            style={{ marginLeft: "20px" }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "This is the datapoint(s) you want to delete in your destination on every run.",
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{ marginLeft: "20px" }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "This is the datapoint(s) you want to push to your destination on every run.",
                                            }}
                                          ></div>
                                        )}

                                        {this.state.fields_mapping.map(
                                          (mapping, index) => (
                                            <>
                                              <Select
                                                menuPlacement="top"
                                                maxMenuHeight={150}
                                                styles={{
                                                  container: (provided) => ({
                                                    ...provided,
                                                    width: '100%', // Adjust the width as needed
                                                  }),
                                                  control: (provided) => ({
                                                    ...provided,
                                                    minWidth: '100%', // Adjust the minimum width as needed
                                                  }),
                                                  menu: (provided) => ({
                                                    ...provided,
                                                    width: '100% !important',
                                                    marginLeft: "20px !important",
                                                    marginTop: 4,
                                                  }),
                                                }}
                                                classNamePrefix="react-select"
                                                name="dataset_select"
                                                className={
                                                  this.state.editing ||
                                                  this.state.loading_destination_objects
                                                    ? "react-select-container disabled"
                                                    : "react-select-container"
                                                }
                                                isDisabled={
                                                  this.state.editing
                                                    ? true
                                                    : !this.state
                                                        .selected_model
                                                }
                                                value={{
                                                  value: mapping["source"],
                                                  label:
                                                    mapping["source_label"],
                                                }}
                                                options={
                                                  this.state.datapoints_options
                                                }
                                                onChange={(e) =>
                                                  this.updateFieldsMapping(
                                                    "source",
                                                    index,
                                                    e
                                                  )
                                                }
                                              />
                                            </>
                                          )
                                        )}
                                      </Col>
                                      <Col md={1}>
                                        <div
                                          style={{
                                            marginTop: "20px",
                                            marginLeft: "50px",
                                          }}
                                        >
                                          <Icon.ArrowRightCircle />
                                        </div>
                                      </Col>
                                      <Col md={5}>
                                        <Form.Label>
                                          <b style={{ marginLeft: "20px" }}>
                                            {this.state.push_type != "Append" &&
                                            this.state.push_type != "Delete" &&
                                            this.state.selected_tap != "Copper"
                                              ? "7"
                                              : "5"}
                                            . Select{" "}
                                            {this.state.push_type != "Delete"
                                              ? "the field you want to send data to"
                                              : "the ID you want to delete data for"}
                                          </b>
                                        </Form.Label>
                                        {this.state.push_type == "Delete" ? (
                                          <div
                                            style={{ marginLeft: "20px" }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "This is the datapoint(s) you want to delete in your destination on every run.",
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{ marginLeft: "20px" }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "This is the datapoint(s) you want to update in your destination on every run.",
                                            }}
                                          ></div>
                                        )}

                                        {this.state.fields_mapping.map(
                                          (mapping, index) => (
                                            <Row
                                              style={{
                                                position: "relative",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <Col md={12}>
                                                <Select
                                                  menuPlacement="top"
                                                  maxMenuHeight={150}
                                                  style={{ width: "100%" }}
                                                  className={
                                                    this.state.editing ? "react-select-container disabled"
                                                      : "react-select-container"
                                                  }
                                                  classNamePrefix="react-select"
                                                  name="dataset_select"
                                                  isDisabled={this.state.editing
                                                      ? true
                                                      : !this.state
                                                      .selected_destination_object 
                                                  }
                                                  value={{
                                                    value: mapping["target"],
                                                    label:
                                                      mapping["target_label"],
                                                  }}
                                                  placeholder={
                                                    this.state
                                                      .selected_destination_object
                                                      ? "Select a target field..."
                                                      : "Select an object from your destination in step 2."
                                                  }
                                                  options={
                                                    this.state
                                                      .push_fields_options
                                                  }
                                                  onChange={(e) =>
                                                    this.updateFieldsMapping(
                                                      "target",
                                                      index,
                                                      e
                                                    )
                                                  }
                                                />
                                              </Col>
                                              {index > 0 ? (
                                                <Col md={1}>
                                                  <Button
                                                    className="align-button"
                                                    disabled={this.state.editing}
                                                    onClick={() =>
                                                      this.setState({
                                                        fields_mapping:
                                                          this.state.fields_mapping.filter(
                                                            (value, index_) =>
                                                              index_ != index
                                                          ),
                                                      })
                                                    }
                                                    variant="light"
                                                    size="lg-pad"
                                                    style={{
                                                      marginTop: "1px",
                                                      position: "absolute",
                                                      top: 0,
                                                      right: "-40px",
                                                    }}
                                                  >
                                                    <Icon.X size={14} />
                                                  </Button>
                                                </Col>
                                              ) : (
                                                ""
                                              )}
                                            </Row>
                                          )
                                        )}
                                      </Col>

                                      <Row>
                                        <Col md={5}></Col>
                                        {this.state.push_type != "Delete" ? (
                                          <Col md={1}>
                                            <Button
                                              style={{
                                                color: "white",
                                                fontSize: "14px",
                                                width: "22px",
                                                marginLeft: "55px",
                                              }}
                                              type="submit"
                                              variant="primary"
                                              size="sm"
                                              disabled={this.state.editing}
                                              onClick={() =>
                                                this.setState({
                                                  fields_mapping: [
                                                    ...this.state
                                                      .fields_mapping,
                                                    { source: "", target: "" },
                                                  ],
                                                })
                                              }
                                            >
                                              +
                                            </Button>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        <Col md={5}></Col>
                                      </Row>
                                    </Row>
                                    <Row></Row>
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                              </>
                            ) : (
                              ""
                            )}
                            <Row>
                              <Col lg={12}>
                                {!this.state.editing ? (
                                  <Button
                                    style={{
                                      margin: "20px",
                                    }}
                                    type="submit"
                                    variant="primary"
                                    size="lg"
                                    disabled={this.state.creating_push}
                                    onClick={() => this.createPush()}
                                  >
                                    {" "}
                                    <Icon.Save />                                    
                                    &nbsp;&nbsp;
                                    {this.state.color == "primary"
                                      ? this.state.testing
                                        ? "Creating... "
                                        : "Create push"
                                      : this.state.color == "success"
                                      ? "Success"
                                      : ""}
                                    &nbsp;
                                    {this.state.creating_push ? (
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    style={{
                                      marginLeft: "20px",
                                      marginBottom: "20px",
                                    }}
                                    type="submit"
                                    variant="primary"
                                    size="lg"
                                    disabled={this.state.testing || this.state.editing ? true : false}
                                    onClick={this.applyEdit}
                                  >
                                    {" "}
                                    <Icon.Save />
                                    &nbsp;&nbsp;
                                    {this.state.testing ? "Saving... " : "Save"}
                                    {this.state.testing ? (
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                )}
                              </Col>
                              <br />
                            </Row>
                          </Col>
                          {this.state.loading_spinner ? (
                            <div className="row">
                              <br></br>
                              <center>
                                <Spinner
                                  as="span"
                                  variant="primary"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                                <br />
                                <br />
                              </center>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "schedule"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="schedule">
                      <div className="card-body push-tap-form">
                        <div className="card form-step-wrapper">
                          <h4 className="d-flex gap-2 align-items-center horizontal-spacing">
                            <b>Schedule your {this.state.selected_tap} push</b>
                            <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                <Popover id={`popover-positioned-left`}>
                                  <Popover.Header as="h3">
                                    {"What are Scheduled Refreshes?"}
                                  </Popover.Header>
                                  <Popover.Body>
                                    This page allows you to set a refresh
                                    cadence so Datajolt knows when to pull data
                                    from your source systems to your DWH
                                    <br />
                                    <br />
                                    This cadence should be aligned with your
                                    business requirements and data consumption.
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <DetailsIcon />
                            </OverlayTrigger>{" "}
                            {this.renderCloseFormButton()}
                          </h4>

                          <Row>
                            <Col lg="12">
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  <b style={{ marginLeft: "20px" }}>
                                    Push Alias
                                  </b>
                                </Form.Label>
                                <Form.Control
                                  style={{
                                    width: "96%",
                                    marginLeft: "20px",
                                    width: "80%",
                                  }}
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      push_alias: e.target.value,
                                    })
                                  }
                                  value={this.state.push_alias}
                                  placeholder="Your push alias"
                                  disabled={this.state.editing}
                                />
                                <div
                                  style={{ marginLeft: "20px" }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "This is the alias of your push. It needs to be unique.",
                                  }}
                                ></div>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <Button
                                style={{
                                  marginLeft: "20px",
                                  marginBottom: "20px",
                                }}
                                type="submit"
                                variant="primary"
                                size="lg"
                                disabled={this.state.scheduling}
                                onClick={() => this.schedulePush()}
                              >
                                <Icon.Repeat size={16} />
                                &nbsp;&nbsp;
                                {this.state.scheduling
                                  ? "Saving... "
                                  : "Saving your push"}
                                {this.state.scheduling ? (
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Col>
                          </Row>
                          {this.state.logs_schedule ? (
                            <Alert
                              variant={this.state.logs_schedule_variant}
                              className="alert-outline-coloured"
                              key="1"
                              onClose={() =>
                                this.setState({ logs_schedule: false })
                              }
                              dismissible
                            >
                              <div className="alert-icon">
                                <Icon.X size={16} />
                              </div>
                              <div className="alert-message">
                                <strong>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: this.state.logs_schedule,
                                    }}
                                  />
                                </strong>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          
          <Modal
            show={this.state.modal_lose_create_push_progress}
            onHide={() =>
              this.setState({ modal_lose_create_push_progress: false })
            }
            centered
          >
            <Modal.Header closeButton>
              <b>Warning!</b>
            </Modal.Header>
            <Modal.Body className="text-center m-2">
              <center>
                You are about to close your push creator form without saving
                your progress, all the progress will be lost.
                <br />
                <b>Are you sure ?</b>
              </center>
            </Modal.Body>
            <Modal.Footer>
              {" "}
              <Button
                variant="danger"
                onClick={() => {
                  this.toggleLoseCreatePushFormProgressModal();
                  this.props.toggleCreatePushForm();
                }}
              >
                <Icon.Delete size={16} />
                &nbsp; Close
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ modal_lose_create_push_progress: false })
                }
              >
                <Icon.X size={16} />
                &nbsp; Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default WithRouter(Push);
