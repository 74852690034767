import React from "react";
import datajoltLoading from "../assets/img/icons/Datajolt-Spinner.json";
import Lottie from "lottie-react";

export default function DatajoltSpinner({ width = 100 }) {
  return (
    <div style={{ width }}>
      <Lottie animationData={datajoltLoading} loop />
    </div>
  );
}
