import axios from "axios";
import https from "https";
import { config } from "../constants";
import { ContextHolder } from "@frontegg/react";
import { setSession } from "./jwt";

const axiosInstance = axios.create();
const httpsAgent = new https.Agent({ rejectUnauthorized: false });
axios.defaults.httpsAgent = httpsAgent;
// axiosRetry(axios, {
//   retries: 3,
//   shouldResetTimeout: true,
//   retryCondition: (_error) => 504 // retry no matter what
// });
const logout = async () => {
  setSession(null);
  const baseUrl = ContextHolder.getContext().baseUrl;
  window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      // Redirect to login page
      logout();
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
