/* eslint-disable */

import React, { Component } from "react";
import axios from "../../utils/axios";
import {
  Row,
  Tooltip,
  OverlayTrigger,
  Tab,
  Col,
  Button,
  Spinner,
  Form,
  Popover,
} from "react-bootstrap";
import Redshift from "../../assets/img/brands/dwh/redshift.png";
import Snowflake from "../../assets/img/brands/dwh/snowflake.png";
import { useNavigate } from "react-router-dom";
import { config } from "../../constants";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Icon from "react-feather";

const user = JSON.parse(localStorage.getItem("user"));

var api_url = config.url.API_URL;
const DWHTypes = [
  {
    value: "Snowflake",
    label: (
      <center>
        <img src={Snowflake} width="14%"></img>
      </center>
    ),
  },
  {
    value: "Redshift",
    label: (
      <center>
        <img src={Redshift} width="14%"></img>
      </center>
    ),
  },
];

export default class DataWarehouses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DWHs: [],
      message: "",
      loading_dwhs: false,
      color: "primary",
      activeTab: "dwh",
      EditOpen: false,
      progress: 40,
      dwh_types: [],
      loading_connectors: false,
    };
  }

  componentDidMount() {
    this.fetchVersion();
    this.fetchVersion2();
  }

  fetchVersion2 = () => {
    axios
      .get(api_url + "/api/datajolt2/version")
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          this.setState({
            api_version2: response.data["Datajolt API version"],
          });
        }
      })
      .catch((err) => {
        toast.error("Error while loading version.");
      })
      .finally(() => {
        this.setState({ progress: 100, loading_dwhs: false });
      });
  };

  fetchVersion = () => {
    axios
      .get(api_url + "/api/datajolt/version")
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          this.setState({
            api_version: response.data["Datajolt API version"],
          });
        }
      })
      .catch((err) => {
        toast.error("Error while loading version.");
      })
      .finally(() => {
        this.setState({ progress: 100, loading_dwhs: false });
      });
  };

  render() {
    return (
      <div>
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <div className="container-fluid py-3">
          <Helmet title="Datajolt | Data Warehouse" />
          <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
            API Version: {this.state.api_version}
            <br />
            API2 Version: {this.state.api_version2}
          </Tab.Container>
        </div>
      </div>
    );
  }
}
