import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import { FronteggProvider } from "@frontegg/react";
import { config } from "./constants";

const contextOptions = {
  baseUrl: config.frontegg.BASE_URL,
  clientId: config.frontegg.CLIENT_ID,
};

ReactDOM.render(
  <BrowserRouter>
    <FronteggProvider contextOptions={contextOptions} hostedLoginBox={true}>
      <App />
    </FronteggProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
