import React, { useState } from "react";
import useScheduleCrud from "../../hooks/useScheduleCrud";
import "./SidebarModal.css";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import {
  Form,
  Button,
  Collapse,
  Accordion,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import LineageVisulaizer from "../../pages/jobs/workflows/lineageVisulaizer";
import axios from "../../utils/axios";
import { config } from "../../constants";
import { toast } from "react-toastify";
import HorizontalDropdownMenu from "../HorizontalDropdown";
import TablePagination from "../TablePagentation";

import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { useEffect } from "react";
import DatajoltSpinner from "../DatajoltSpinner";

var api_url = config.url.API_URL;

const workflowList = [
  { label: "test_bud", value: "dumb" },
  { label: "awesome_bud", value: "wut" },
];

export default function ScheduleCRUD() {
  const { setIsOpen } = useScheduleCrud();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [schedulesList, setScheduleList] = useState([]);
  const [isCreatingSchedule, setIsCreatingSchedule] = useState(false);
  const [currentEditedSchedule, setCurrentEditedSchedule] = useState(null);
  const [modal_delete, setModal_delete] = useState(false);

  const [scheduleName, setScheduleName] = useState("");
  const [scheduleDetails, setScheduleDetails] = useState("");
  const [scheduleMode, setScheduleMode] = useState("");
  const [isWorkflowMenuOpen, setWorkflowMenuOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axios
      .get(api_url + "/api/schedules/get_schedules")
      .then((response) => {
        console.log(response.data, "hahahah");

        setScheduleList(response.data);
      })
      .catch((err) => {
        toast.error("Error while loading schedules.");
        setIsError("Error fetching schedules data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const toggleForm = () => setIsCreatingSchedule((is) => !is);

  const handleWorkflowMenuOpen = () => {
    setWorkflowMenuOpen(!isWorkflowMenuOpen);
  };

  const selectScheduleMode = (val) => {
    setScheduleMode(val);
    handleWorkflowMenuOpen();
  };

  const renderWorkflowsOptions = () =>
    workflowList.map((scheduleOption, idx) => {
      if (idx + 1 !== workflowList.length) {
        return (
          <>
            <li
              onClick={() => selectScheduleMode(scheduleOption.value)}
              className="schedule-menu-item"
            >
              {scheduleOption.label}
            </li>
            <hr className="menu-divider" />
          </>
        );
      }

      return (
        <li
          onClick={() => selectScheduleMode(scheduleOption.value)}
          className="schedule-menu-item"
        >
          {scheduleOption.label}
        </li>
      );
    });

  useEffect(() => {
    if (isCreatingSchedule) {
      setCurrentEditedSchedule(null);
    }
  }, [isCreatingSchedule]);

  return (
    <ResizableBox
      className={`sidebar-modal-wrapper`}
      height={window.innerHeight - 50}
      width={window.innerWidth / 2}
      maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
      minConstraints={[400, 400]}
      resizeHandles={["w"]}
      id="general-schedule-crud-modal"
    >
      <section className="schedule-sidebar-content">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="heading-schedule-model mb-0">
            <Icon.ChevronsRight
              color="#438593"
              onClick={() => setIsOpen(false)}
              role="button"
            />{" "}
            {isCreatingSchedule
              ? "Create a new Schedule"
              : currentEditedSchedule
              ? "Editing a schedule"
              : "Schedules"}
          </h2>
          {!isCreatingSchedule && !currentEditedSchedule && (
            <Button onClick={toggleForm} className="create-button">
              Create New Schedule
            </Button>
          )}
        </div>

        {currentEditedSchedule ? (
          <Form>
            <Form.Group controlId="scheduleName" className="mb-3">
              <Form.Label>Schedule name</Form.Label>
              <Form.Control
                disabled
                value={currentEditedSchedule.schedule_name}
                onChange={(e) =>
                  setCurrentEditedSchedule((sch) => ({
                    ...sch,
                    name: e.target.val,
                  }))
                }
                type="text"
                placeholder="Enter Schedule name"
              />
            </Form.Group>
            <Form.Group controlId="scheduleDetails" className="mb-3">
              <Form.Label>Schedule details</Form.Label>
              <Form.Control
                value={currentEditedSchedule.cron_expression}
                onChange={(e) =>
                  setCurrentEditedSchedule((sch) => ({
                    ...sch,
                    details: e.target.val,
                  }))
                }
                type="text"
                placeholder="Enter Schedule details"
              />
            </Form.Group>
            <Form.Group controlId="schedule">
              {/* <Form.Label>Workflow</Form.Label>
        <div>
          <Button
            onClick={handleWorkflowMenuOpen}
            aria-controls="schedule-collapse"
            aria-expanded={isWorkflowMenuOpen}
            className={`collapse-schedule ${
              scheduleMode && "collapse-schedule-chosen"
            }`}
          >
            {scheduleMode
              ? workflowList.find((opt) => opt.value === scheduleMode)
                  .label
              : "Select Workflow"}
          </Button>
          <Collapse in={isWorkflowMenuOpen}>
            <ul id="schedule-collapse" className="schedule-menu">
              {renderWorkflowsOptions()}
            </ul>
          </Collapse>
        </div> */}
              <Button className="create-button">Edit Schedule</Button>
            </Form.Group>
          </Form>
        ) : (
          <>
            {isCreatingSchedule ? (
              <Form>
                <Form.Group controlId="scheduleName" className="mb-3">
                  <Form.Label>Schedule name</Form.Label>
                  <Form.Control
                    value={scheduleName}
                    onChange={(e) => setScheduleName(e.target.value)}
                    type="text"
                    placeholder="Enter Schedule name"
                  />
                </Form.Group>
                <Form.Group controlId="scheduleDetails" className="mb-3">
                  <Form.Label>Schedule details</Form.Label>
                  <Form.Control
                    value={scheduleDetails}
                    onChange={(e) => setScheduleDetails(e.target.value)}
                    type="text"
                    placeholder="Enter Schedule details"
                  />
                </Form.Group>
                <Form.Group controlId="schedule">
                  {/* <Form.Label>Workflow</Form.Label>
              <div>
                <Button
                  onClick={handleWorkflowMenuOpen}
                  aria-controls="schedule-collapse"
                  aria-expanded={isWorkflowMenuOpen}
                  className={`collapse-schedule ${
                    scheduleMode && "collapse-schedule-chosen"
                  }`}
                >
                  {scheduleMode
                    ? workflowList.find((opt) => opt.value === scheduleMode)
                        .label
                    : "Select Workflow"}
                </Button>
                <Collapse in={isWorkflowMenuOpen}>
                  <ul id="schedule-collapse" className="schedule-menu">
                    {renderWorkflowsOptions()}
                  </ul>
                </Collapse>
              </div> */}
                  <Button className="create-button">
                    Create a new Schedule
                  </Button>
                </Form.Group>
              </Form>
            ) : isError ? (
              <h3 className="text-danger">{isError}</h3>
            ) : isLoading ? (
              <center>
                <DatajoltSpinner />
              </center>
            ) : (
              <>
                <table className="table-lists table-daged table-bordered table-triped table-hover my-8px">
                  <thead>
                    <tr>
                      <th width="28%">Schedule Name</th>
                      <th width="28%">Schedule Details</th>
                      <th width="28%">Description</th>
                      <th width="16%">actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {schedulesList.length > 0 &&
                      schedulesList.map((schedule, index) => (
                        <tr key={schedule.name + index}>
                          <td>{schedule.schedule_name}</td>
                          <td>{schedule.cron_expression}</td>
                          <td>{schedule.description}</td>

                          <td>
                            <HorizontalDropdownMenu>
                              <OverlayTrigger
                                rootClose
                                trigger={["hover", "hover"]}
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Edit Schedule
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  variant="no-outline-success"
                                  onClick={() =>
                                    setCurrentEditedSchedule(schedule)
                                  }
                                >
                                  <SettingsIcon />
                                </Button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                rootClose
                                trigger={["hover", "hover"]}
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Delete Schedule
                                  </Tooltip>
                                }
                              >
                                <Button
                                  size="sm"
                                  variant="no-outline-success"
                                  className="me-1 mb-1"
                                  // disabled={this.state.pausing}
                                  onClick={() => {
                                    setModal_delete(schedule.scheduleName);
                                  }}
                                >
                                  <CancelIcon />
                                </Button>
                              </OverlayTrigger>
                            </HorizontalDropdownMenu>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <TablePagination
                  currentPage={1}
                  totalPages={1}
                  updatePageNum={(num) => console.log({ currentPage: num })}
                  updatePerPage={(num) => console.log({ perPage: num })}
                  perPage={5}
                />
              </>
            )}
          </>
        )}
      </section>

      <Modal
        show={modal_delete}
        onHide={() => {
          setModal_delete(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <b>Warning! You are about to delete a Schedule</b>
        </Modal.Header>
        <Modal.Body className="text-center m-2">
          <center>
            You are about to delete <b>{modal_delete}</b> Schedule.
            <br />
            <b>Are you sure ?</b>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal_delete(false)}>
            <Icon.X size={16} />
            &nbsp; No
          </Button>{" "}
          <Button
            variant="danger"
            // disabled={this.state.deleting}
            onClick={() => {
              console.log("deleting");
              setModal_delete(false);
            }}
          >
            <Icon.Trash2 size={16} />
            &nbsp; Delete
            {/* {this.state.deleting ? (
              <>
                Deleting...&nbsp;&nbsp;
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              </>
            ) : (
              "Delete"
            )} */}
          </Button>
        </Modal.Footer>
      </Modal>
    </ResizableBox>
  );
}
