import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingTableBody = ({ colNum }) => (
  <tbody>
    {Array.from({ length: 10 }).map((_) => (
      <tr>
        {Array.from({ length: colNum }).map((_) => (
          <td>
            <Skeleton />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);
export default LoadingTableBody;
