import NavIndicator from "./NavIndicator";
import SidebarNavSection from "./SidebarNavSection";

const SidebarNav = ({ items }) => {
  return (
    <ul className="sidebar-nav" style={{ position: "relative" }}>
      <NavIndicator />
      {items &&
        items.map((item) => {
          return (
            <SidebarNavSection
              key={item.title}
              pages={item.pages}
              title={item.title}
            />
          );
        })}
    </ul>
  );
};

export default SidebarNav;
