import React, { useState } from "react";
import {
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { Trash2, Settings, MoreHorizontal, X } from "react-feather"; // Import icons
import axios from "axios"; // Import axios for API call
import { config } from "../../constants";
import { toast } from "react-toastify";

function ConnectorCardPush({ datasource, onDeleteSuccess }) {
  const sourceAlias =
    datasource && datasource.alias ? datasource.alias : "";
  const [isLoading, setIsLoading] = useState(false); // Add state to manage loading
  const [isDeleting, setIsDeleting] = useState(false); // Add state to manage loading
  const [showDelete, setShowDelete] = useState(false); // State to manage modal visibility
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility
  const truncatedAlias =
    sourceAlias.length > 10
      ? `${sourceAlias.substring(0, 10)}...`
      : sourceAlias;

  // Function to handle delete action
  const handleDelete = async (e) => {
    setIsDeleting(true);
    e.stopPropagation(); // Stop event propagation
    console.log(`Deleting destination: ${datasource ? datasource.id : ""}`);
    var toastId = toast.loading("Deleting destination...")
    // Uncomment the following lines to actually perform the delete operation
    try {
      setIsLoading(true);
      axios
        .post(config.url.PUSH_URL + `/api/push/delete_destination`, {
          destination_id: datasource.id,
        })
        .then((response) => {
          toast.success(
            "Your destination " +
              datasource.alias +
              " was deleted successfully.",
            { autoClose: 5000 }
          );
          setIsLoading(false);
          setShowDelete(false); // Close modal after deletion
          onDeleteSuccess();
        })
        // .catch((err) => {
        //   toast.error(
        //     "There was an internal issue deleting your source.",
        //     { autoClose: 5000 }
        //   );
        // })
        .finally(() => {
          setIsDeleting(false);
          toast.dismiss(toastId)
        });
      // Handle successful deletion (e.g., refresh the list, show a message, etc.)
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const handleCloseModal = (e) => {
    if (e) {
      e.stopPropagation(); // Prevent triggering the card click event
    }
    setShowDelete(false); // Close the modal
  };

  // Function to handle dropdown item click
  const handleDropdownItemClick = (e, action) => {
    e.stopPropagation(); // Prevent triggering the card click event
    setShowDropdown(false); // Hide the dropdown
    if (action === "delete") {
      setShowDelete(true); // Show delete modal
    }
    // Add other actions here if needed
  };

  return (
    <>
      <div
        className="card-taps"
        role="button"
        tabIndex={0}
        aria-pressed="false"
        style={{ cursor: "pointer", position: "relative" }}
      >
        <div
          className="card-taps-body connector-card-taps-body"
          title={
            datasource && datasource.label ? datasource.label : datasource.alias
          }
        >
          <center>
            <div className="connector-image">
              {datasource && datasource.logo ? (
                <img
                  src={datasource.logo}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  loading="lazy"
                  alt=""
                  className="image-contain"
                />
              ) : null}
            </div>
          </center>
          <div className="card-taps-info" style={{ flexGrow: 3 }}>
            <center>
              <div
                className="card-taps-title text-center"
                style={{
                  wordWrap: "break-word",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "10px",
                  lineHeight: "15px",
                  maxWidth: "100px",
                }}
                title={
                  datasource && datasource.label
                    ? datasource.label
                    : datasource.alias
                }
              >
              </div>
              <b>{
                  datasource && datasource.label
                    ? datasource.label
                    : ""
                }</b>
            </center>
            <div className="card-taps-metadata">
              {datasource && datasource.tap_group}
            </div>
          </div>
          <div className="card-taps-metadata mt-1">
            {datasource && !datasource.status ? (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${sourceAlias}`}>
                      {sourceAlias}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="live-success"
                    className="me-1 mb-1"
                    size="sm"
                  >
                    {truncatedAlias}
                  </Button>
                </OverlayTrigger>
                <div
                  className="icons-wrapper"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  <Dropdown
                    show={showDropdown}
                    onToggle={(isOpen) => setShowDropdown(isOpen)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Toggle
                      as={MoreHorizontal}
                      size={14}
                      style={{ cursor: "pointer" }}
                    />
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/edit-source"
                        disabled={true}
                        onClick={(e) => handleDropdownItemClick(e, "edit")}
                      >
                        <Settings size={14} className="me-2" /> Edit connection
                        details
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => handleDropdownItemClick(e, "delete")}
                      >
                        <Trash2 size={14} className="me-2" /> Delete destination
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <Button
                disabled={isLoading} // Disable button when loading
                className={
                  datasource && datasource.status === "Live"
                    ? "btn btn-success rounded-pill"
                    : "btn btn-primary rounded-pill"
                }
                style={{ fontSize: "8px" }}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : datasource && datasource.status ? (
                  datasource.status.toUpperCase()
                ) : (
                  ""
                )}
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Modal for delete confirmation */}
      <div onClick={(e) => e.stopPropagation()}>
        <Modal show={showDelete} onHide={handleCloseModal}>
          <div
            className="custom-backdrop"
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal(e);
            }}
          ></div>
          <Modal.Header closeButton onClick={handleCloseModal}>
            <Modal.Title>Confirm destination deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The following pushes will be impacted:
            <br />
            {datasource?.taps?.split(",").map((tap, index) => (
              <>
                - <b>{tap}</b>
                <br />
              </>
            ))}
            <br />
            Are you sure you want to delete destination <b>{sourceAlias}</b>?<br />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              disabled={isDeleting}
              onClick={handleDelete}
            >
              <Trash2 size={14} className="me-2" />
              Delete destination
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              <X size={14} /> Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ConnectorCardPush;
