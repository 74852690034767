import React from "react";
import "./DagCreators.css";

const Step = ({ name, isDone, num }) => {
  return (
    <div className="step-container">
      <div
        id="outer-circle"
        className="step-outer-circle"
        style={{
          background: isDone ? "#B0EAAA" : "#EAEAEA",
        }}
      >
        <div
          id="inner-circle"
          className="step-inner-circle"
          style={{
            color: isDone ? "#090E47" : "#448593",
          }}
        >
          {num}
        </div>
      </div>
      <p
        className="step-name"
        style={{
          fontWeight: isDone ? "500" : "400",
          color: isDone ? "#090E47" : "#448593",
          width: "81px",
          textAlign: "left",
        }}
      >
        {name}
      </p>
    </div>
  );
};

export default function StepIndicator({ activeStep = 0, steps }) {
  return (
    <section className="push-tap-stepper">
      <div id="step-indicator-container" className="step-indicator-container">
        {steps.map((step, index) => (
          <Step
            key={step}
            name={step}
            isDone={activeStep >= index + 1}
            num={index + 1}
          />
        ))}

        <div
          id="green-indicator"
          className="done-indicator"
          style={{
            height: `${
              activeStep
                ? (activeStep / steps.length) *
                    document.getElementById("step-indicator-container")
                      ?.offsetHeight || 200
                : 0
            }px`,
          }}
        ></div>
      </div>
    </section>
  );
}
