import React, { useState, useEffect } from "react";
import axios from "axios";
import DatajoltSpinner from "../../components/DatajoltSpinner";
import * as Icon from "react-feather";
import _ from "lodash";

const DbtDocsModal = ({ show, handleClose, dbtUrl, activeBranch }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (show) {
      const fetchDbtDocs = async () => {
        try {
          console.log("Active Branch:", activeBranch); // Debug log
          const response = await axios.post(dbtUrl + "/serve_dbt_docs", {
            branch: activeBranch,
          });
          setContent(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching DBT Docs", error);
          setContent(
            '<p>Could not find DBT Docs.</p> <br/>Make sure you run <b>"dbt docs generate --static"</b> in the DBT CLI first.'
          );
          setLoading(false);
        }
      };
      fetchDbtDocs();
    }
  }, [show, activeBranch]);

  return (
    <div
      className={`sidebar-modal-wrapper ${isFullScreen && "fullscreen"}`}
      style={{
        height: window.innerHeight - 50,
        width: "50%",
      }}
    >
      {loading ? (
        <center className="connector-spinner" style={{ minHeight: "600px" }}>
          <br />
          <br />
          <DatajoltSpinner />
        </center>
      ) : (
        <section className="schedule-sidebar-content">
          <header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>
              <Icon.ChevronsRight
                color="#438593"
                onClick={handleClose}
                role="button"
              />{" "}
              DBT Docs
            </h2>

            {isFullScreen ? (
              <Icon.Minimize
                role="button"
                onClick={() => setIsFullScreen(false)}
              />
            ) : (
              <Icon.Maximize
                role="button"
                onClick={() => setIsFullScreen(true)}
              />
            )}
          </header>
          <iframe srcDoc={content} width={`100%`} height={`100%`} />
        </section>
      )}
    </div>
  );
};

export default DbtDocsModal;
