import React, { useState } from "react";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import ScheduleCRUD from "../components/ScheduleModal/ScheduleCRUD";

const ScheduleCrudContext = React.createContext();
ScheduleCrudContext.displayName = "ScheduleCrudContext";

function ScheduleCrudProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const updateModalVisibility = (visible) => {
    const isAnotherModalActive = document.getElementById(
      "active-modal-sidebar"
    );

    if (isAnotherModalActive) {
      toast.info(
        "please finish up the progress with current modal before opening another one"
      );
    } else {
      setIsOpen(visible);
    }
  };

  return (
    <ScheduleCrudContext.Provider
      value={{
        isOpen,
        setIsOpen: updateModalVisibility,
      }}
    >
      <CSSTransition
        in={isOpen}
        classNames="right-slide"
        timeout={300}
        unmountOnExit
      >
        <ScheduleCRUD />
      </CSSTransition>

      {children}
    </ScheduleCrudContext.Provider>
  );
}

export { ScheduleCrudProvider, ScheduleCrudContext };
