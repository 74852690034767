import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

export class SpecDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subelements: {},
    };
  }

  handleChange = (datatype, variable_name) => (e) => {
    if (datatype === "string") {
      this.setState({ [e.target.name]: e.target.value.trim() }, () => {
        this.props.onStateUpdate(this.state); // pass the updated state to the parent
      });
    } else if (datatype === "boolean") {
      this.setState({ [e.target.name]: !!e.target.value.trim() }, () => {
        this.props.onStateUpdate(this.state); // pass the updated state to the parent
      });
    } else if (datatype === "array") {
      const arrayValue = e.target.value.split(",").map(item => item.trim());
      this.setState({ [e.target.name]: arrayValue }, () => {
        this.props.onStateUpdate(this.state); // pass the updated state to the parent
      });
    } else if (datatype === "integer") {
      this.setState(
        { [e.target.name]: parseInt(e.target.value.trim()) },
        () => {
          this.props.onStateUpdate(this.state); // pass the updated state to the parent
        }
      );
    }
  };

  options_oneofs = (name, oneofs) => {
    let oneof = [];
    oneofs.map((oneoff) => {
      //find upper key name where order = 0 inside property key and use that variable to identify what the initial dropdown value is
      let obj = oneoff["properties"];
      let result = Object.keys(obj).find((key) => obj[key]["order"] === 0);
      if (result === undefined) {
        oneof.push({ label: oneoff["title"], value: oneoff["title"] });
      } else {
        oneof.push({
          label: oneoff["title"],
          value: oneoff["properties"][result]["const"],
        });
      }
    });
    return oneof;
  };

  options_enums = (enums) => {
    let output = [];
    enums.map((enu) => {
      output.push({ label: enu, value: enu });
    });
    return output;
  };

  handleSelectChangeOneOf = (e, name, oneofs, options) => {
    const subelement_index = options
      .map((object) => object.value)
      .indexOf(e.value);
    const element_index = this.props.spec_details
      .map((object) => object.name)
      .indexOf(name);
    oneofs.some((oneoff) => {
      if (oneoff["title"] === e.label) {
        var selected_subelement = name + "_selected_value";
        var required_subelements = name + "_required_subelements";
        this.setState(
          {
            element_index: {
              ...this.state.element_index,
              [name]: element_index,
            },
            [required_subelements]: oneoff["required"],
            [selected_subelement]: subelement_index,
            subelements: {
              ...this.state.subelements,
              [name]: oneoff["required"],
            },
            subelement_index: {
              ...this.state.subelement_index,
              [name]: subelement_index,
            },
          },
          () => {
            this.props.onStateUpdate(this.state); // pass the updated state to the parent
          }
        );
        return true;
      }
      return false;
    });
  };

  handleSelectChangeEnum = (name) => (selectedvalue) => {
    this.setState({ [name]: selectedvalue.value }, () => {
      this.props.onStateUpdate(this.state); // pass the updated state to the parent
    });
  };

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] }, () => {
      this.props.onStateUpdate(this.state); // pass the updated state to the parent
    });
  };

  render() {
    const { spec_details } = this.props;
    let dropdownSelec = null;
    return (
      <>
        {spec_details.map((detail) =>
          detail.title ? (
            <Form.Group className="mb-3" key={detail.title}>
              <Form.Label>
                <b style={{ marginLeft: "20px" }}>{detail.title} </b>
                {detail.required ? <b>*</b> : <i>Optional</i>}
              </Form.Label>
              {(() => {
                if (detail.oneOf || detail.enum || detail.type === "boolean") {
                  if (detail.oneOf) {
                    return (
                      <>
                        <Select
                          menuShouldScrollIntoView={false}
                          className="react-select-container react-select-notrain"
                          classNamePrefix="react-select"
                          name={detail.name}
                          options={this.options_oneofs(
                            detail.name,
                            detail.oneOf
                          )}
                          onChange={(e) =>
                            this.handleSelectChangeOneOf(
                              e,
                              detail.name,
                              detail.oneOf,
                              this.options_oneofs(detail.name, detail.oneOf)
                            )
                          }
                        />
                        {this.state.subelements[detail.name]
                          ? this.state.subelements[detail.name].map(
                              (subelement) =>
                                !spec_details[
                                  this.state.element_index[detail.name]
                                ]["oneOf"][
                                  this.state.subelement_index[detail.name]
                                ]["properties"][subelement]["title"] ||
                                spec_details[
                                  this.state.element_index[detail.name]
                                ]["oneOf"][
                                  this.state.subelement_index[detail.name]
                                ]["properties"][subelement]["order"] === 0 ? (
                                  ""
                                ) : (
                                  <>
                                    <Form.Label>
                                      <b style={{ marginLeft: "20px" }}>
                                        {
                                          spec_details[
                                            this.state.element_index[
                                              detail.name
                                            ]
                                          ]["oneOf"][
                                            this.state.subelement_index[
                                              detail.name
                                            ]
                                          ]["properties"][subelement]["title"]
                                        }{" "}
                                        {detail.required ? "*" : ""}
                                      </b>
                                    </Form.Label>
                                    <Form.Control
                                      style={{ width: "96%", marginLeft: "3%" }}
                                      key={subelement}
                                      type={
                                        spec_details[
                                          this.state.element_index[detail.name]
                                        ]["oneOf"][
                                          this.state.subelement_index[
                                            detail.name
                                          ]
                                        ]["properties"][subelement][
                                          "airbyte_secret"
                                        ]
                                          ? "password"
                                          : "text"
                                      }
                                      name={subelement}
                                      placeholder={
                                        spec_details[
                                          this.state.element_index[detail.name]
                                        ]["oneOf"][
                                          this.state.subelement_index[
                                            detail.name
                                          ]
                                        ]["properties"][subelement][
                                          "description"
                                        ]
                                      }
                                      defaultValue={
                                        spec_details[
                                          this.state.element_index[detail.name]
                                        ]["oneOf"][
                                          this.state.subelement_index[
                                            detail.name
                                          ]
                                        ]["properties"][subelement][
                                          "autocomplete"
                                        ]
                                          ? spec_details[
                                              this.state.element_index[
                                                detail.name
                                              ]
                                            ]["oneOf"][
                                              this.state.subelement_index[
                                                detail.name
                                              ]
                                            ]["properties"][subelement][
                                              "autocomplete"
                                            ]
                                          : ""
                                      }
                                      onChange={this.handleChange(
                                        spec_details[
                                          this.state.element_index[detail.name]
                                        ]["oneOf"][
                                          this.state.subelement_index[
                                            detail.name
                                          ]
                                        ]["properties"][subelement]["type"],
                                        subelement
                                      )}
                                    />
                                  </>
                                )
                            )
                          : ""}
                      </>
                    );
                  } else if (detail.enum) {
                    dropdownSelec = this.options_enums(detail.enum);
                    return (
                      <Select
                        style={{ width: "10%" }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name={detail.name}
                        options={dropdownSelec}
                        onChange={this.handleSelectChangeEnum(detail.name)}
                      />
                    );
                  } else if (detail.type === "boolean") {
                    return (
                      <Form.Check
                        className="switch-checker"
                        style={{ width: "96%", marginLeft: "3%" }}
                        type="switch"
                        id="exampleCustomSwitch"
                        name="customSwitch"
                        onChange={(e) => this.handleToggle(detail.name)}
                      />
                    );
                  }
                }
                return (
                  <Form.Control
                    style={{ width: "96%", marginLeft: "3%" }}
                    key={detail.title}
                    type={detail.airbyte_secret ? "password" : "text"}
                    name={detail.name}
                    placeholder={detail.examples}
                    onChange={this.handleChange(detail.type, "")}
                  />
                );
              })()}
              <div
                style={{ marginLeft: "20px" }}
                dangerouslySetInnerHTML={{ __html: detail.description }}
              ></div>
            </Form.Group>
          ) : (
            ""
          )
        )}
      </>
    );
  }
}
