import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import mainlogo from "../../assets/img/customers/logo.png";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content position-sticky">
        <PerfectScrollbar
          options={{ suppressScrollX: true, suppressScrollY: true }}
        >
          <a className="sidebar-brand" href="/home">
            <img src={mainlogo} width="85%" alt="Datajolt" />
          </a>
          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
