import * as React from "react";

import useAuth from "../../hooks/useAuth";
import { useLoginWithRedirect } from "@frontegg/react";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  if (!isAuthenticated) {
    loginWithRedirect();
    return <></>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
