import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import ContextMenu from ".";
import { searchForFolder } from "../../../utils/searchForFolder";

const FolderContextMenu = ({
  dropdownAnchor,
  onClose,
  onDelete,
  onRename,
  setReposFiles,
  reposFiles,
}) => {
  const [newElementId, setNewElementId] = useState("");

  const expandFolder = () => {
    const treeLabelElement = dropdownAnchor.element.parentElement;
    const treeItemElement = treeLabelElement.parentElement;

    // Check if the tree item is expanded
    const isExpanded = treeItemElement.classList.contains("Mui-expanded");

    if (!isExpanded) {
      dropdownAnchor.element.click();
      treeItemElement.click();
    }
  };

  useEffect(() => {
    // If I add new file / folder
    if (newElementId) {
      // Get the file in the dom
      const file = document
        .querySelector(`[id$=${newElementId.split("/").slice(-1)[0]}]`)
        ?.querySelector(".MuiBox-root");

      if (file) {
        // Focus on the new element
        file.focus();

        // Remove the element id
        setNewElementId("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reposFiles]);

  const addNewFile = () => {
    const folderId = dropdownAnchor.nodes.id;

    searchForFolder(reposFiles, folderId, (currentFolder) => {
      const id = `${currentFolder.id}/new${Date.now()}`;
      const newFolder = {
        branch: "Dev",
        children: [],
        id,
        name: "",
        contentEditable: true,
        isFolder: false,
      };

      // Add file / folder to the tree
      currentFolder.children.unshift(newFolder);
      setNewElementId(id);
    });
    setReposFiles(reposFiles);
    // FIXME: This code needs to be refactored
    setTimeout(expandFolder, 0);
    expandFolder();
  };

  const addNewFolder = () => {
    const folderId = dropdownAnchor.nodes.id;

    searchForFolder(reposFiles, folderId, (currentFolder) => {
      const id = `${currentFolder.id}/new${Date.now()}`;
      const newFolder = {
        branch: "Dev",
        children: [],
        id,
        name: "",
        contentEditable: true,
        isFolder: true,
      };

      // Add file / folder to the tree
      currentFolder.children.unshift(newFolder);
      setNewElementId(id);
    });
    setReposFiles(reposFiles);
    // FIXME: This code needs to be refactored
    setTimeout(expandFolder, 0);
    expandFolder();
  };

  const options = [
    {
      label: "Create File",
      OptionIcon: Icon.FilePlus,
      onClick: addNewFile,
    },
    {
      label: "Create Folder",
      OptionIcon: Icon.FolderPlus,
      onClick: addNewFolder,
    },
  ];

  if (dropdownAnchor.nodes?.id.split("/").length > 1) {
    options.push(
      {
        label: "Rename Folder",
        OptionIcon: Icon.Edit3,
        onClick: onRename,
      },
      {
        label: "Delete Folder",
        OptionIcon: Icon.Trash2,
        onClick: onDelete,
      }
    );
  }

  return (
    <ContextMenu
      options={options}
      onClose={onClose}
      dropdownAnchor={dropdownAnchor}
    />
  );
};

export default FolderContextMenu;
