/* eslint-disable */

import React, { Component, createRef } from "react";
import axios from "../../utils/axios";
import { useAuth, useTenantsState } from "@frontegg/react";
import {
  Modal,
  Row,
  Col,
  Popover,
  Button,
  Tooltip,
  OverlayTrigger,
  Spinner,
  Tab,
  Form,
} from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { config } from "../../constants";
import Select from "react-select";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import InputMask from "react-input-mask";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import moment from "moment-timezone";
import WithRouter from "../../HOC/RouterWrapper";
import TablePagination from "../../components/TablePagentation";
import HorizontalDropdownMenu from "../../components/HorizontalDropdown";

import { ReactComponent as FailedIcon } from "../../assets/img/icons/failed.svg";
import { ReactComponent as SuccessIcon } from "../../assets/img/icons/success.svg";
import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as QueuedIcon } from "../../assets/img/icons/queed.svg";
import { ReactComponent as StopIcon } from "../../assets/img/icons/StopIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/img/icons/PlayIcon.svg";

import SearchJobs from "./SearchJobs";
import LoadingTableBody from "../../components/Loading/LoadingTableBody";
import PushCreatorForm from "../../components/DagCreators/PushCreatorForm";
import DagHistory from "./DagHistory";
import RunningLottie from "../../components/RunningLottie";
import { ResizableBox } from "react-resizable";
import SortBtn from "../../components/SortBtn";

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const user = JSON.parse(localStorage.getItem("user"));
var api_url = config.url.API_URL;
var customer_timezone = localStorage.getItem("timezone");
const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

const TenantContextComponent = (props) => {
  const { user } = useAuth();
  const { tenants } = useTenantsState();
  const activeTenant = tenants.find((t) => t.tenantId === user.tenantId);

  return <Push {...props} activeTenant={activeTenant} />;
};


class Push extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields_mapping: [{ source: "", target: "" }],
      loading: false,
      loading_destination_objects: false,
      EditOpen: false,
      datasources: [],
      progress: 40,
      spec_details: [],
      running_dags: [],
      DAGs:[],   
      loading_my_destinations: false,
      loading_connectors: false,
      dag_runs: [],
      destination_id: "",
      historical_dag_runs: [],
      subelements: false,
      selected_datapoint_source: false,
      json_update_endpoint: false,
      source_link: false,
      destination_link: false,
      logs_schedule: false,
      get_dags_status: true,
      push_type: false,
      selected_destination_object: false,
      selected_datapoint_destination: false,
      destination_fields: [],
      modal_history: false,
      activeTab: "all",
      deleting_push_alias: "",
      push_alias: "",
      destination_alias: "",
      mytaps: [],
      modal_edit: false,
      modal_flow: false,
      selected_job: "",
      scheduling: false,
      schedule_day: false,
      jobNameFilters: "",
      currentPage: 1,
      perPage: 10,
      isShowingCreatePushForm: false,
      columnsWidth: [0, 0, 0, 0, 0, 0],
      sortedColumn: {
        column: "",
        isAsc: true,
      },
    };

    this.tableRef = createRef(null);
  }

  componentDidUpdate(prevProps, prevState) {
    const hasQueryParamsChanged =
      this?.props?.router?.location &&
      prevProps?.router?.location &&
      this.props?.router.location.search !== prevProps?.router.location.search;

    const hasInitialHistoryModalView =
      prevProps?.router.location.search.includes("history_modal") ||
      this.props?.router.location.search.includes("history_modal");

    const hasInitialEditView =
      prevProps?.router.location.search.includes("edit_push") ||
      this.props?.router.location.search.includes("edit_push");

    if (hasQueryParamsChanged) {
      if (hasInitialHistoryModalView) {
        this.showInitialHistoryModal(prevProps);
      }

      if (hasInitialEditView) {
        this.showInitialEditView(prevProps);
      }
    }

    if (!prevState.DAGs.length && this.state.DAGs.length) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }

      if (this.getTargetEditedDAGData()) {
        if (!this.state.isShowingCreatePushForm) {
          this.toggleCreatePushForm();
        }
      }
    }
  }

  setSelectedPushData = (data) => this.setState(data);

  toggleCreatePushForm = () => {
    this.setState(
      {
        isShowingCreatePushForm: !this.state.isShowingCreatePushForm,
      },
      () => {
        if (!this.state.isShowingCreatePushForm) {
          this.props?.router.navigate("/jobs/push");
        }
      }
    );
  };

  setdbturl = () => {
    console.log("Setting DBT URL");
    const localUrl = "http://127.0.0.1:5000";
    const sqlEditorUrl = config.url.SQL_EDITOR_URL;

    const waitForTenant = setInterval(() => {
      if (this.props.activeTenant?.name) {
        clearInterval(waitForTenant);
        const dbtUrl =
          sqlEditorUrl === localUrl
            ? localUrl
            : `${sqlEditorUrl}/${this.props.activeTenant.name}-dbt`;
        console.log("DBT URL:", dbtUrl);
        this.setState({ dbt_url: dbtUrl });
      }
    }, 500); // Check every 100ms
  };

  getTargetEditedDAGData = () => {
    const edit_push_id = new URLSearchParams(window.location.search).get(
      "edit_push"
    );
    const targetDAG = this.state.DAGs.find((DAG) => DAG.id == edit_push_id);
    return targetDAG;
  };

  getTargetDAGData = () => {
    const history_modal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );
    const targetDAG = this.state.DAGs.find((DAG) => DAG.id == history_modal);
    return targetDAG;
  };

  toggleHistoryDagTable = () => {
    this.setState({
      isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
    });
  };

  showInitialHistoryModal = (prevProps) => {
    //prevProps to extract queryParams from pervious render
    if (
      prevProps?.router.location.search.includes("history_modal") &&
      !this.props?.router.location.search.includes("history_modal")
    ) {
      this.setState({
        historical_runs_available: false,
        modal_history: false,
        isShowingDagHistoryTable: false,
      });
    }

    if (
      !prevProps?.router.location.search.includes("history_modal") &&
      this.props?.router.location.search.includes("history_modal")
    ) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  showInitialEditView = (prevProps) => {
    //prevProps to extract queryParams from pervious render
    if (
      prevProps?.router.location.search.includes("edit_push") &&
      !this.props?.router.location.search.includes("edit_push")
    ) {
      this.setState({
        activeTab: "all",
        isShowingCreatePushForm: false,
      });
    }

    if (
      !prevProps?.router.location.search.includes("edit_push") &&
      this.props?.router.location.search.includes("edit_push")
    ) {
      if (!this.state.isShowingCreatePushForm) {
        this.toggleCreatePushForm();
      }
    }
  };

  redirectToHistoryModalView = (DAG) => {
    this.props?.router.navigate(`/jobs/push?history_modal=${DAG.id}`);
  };

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  calculateTotalPages = () => {
    const totalLength = this.state.DAGs.filter((DAG) =>
      this.filterJobNames(DAG)
    ).length;
    return totalLength ? Math.ceil(totalLength / this.state.perPage) : 1;
  };

  paginateArray = (
    array,
    activePage = this.state.currentPage,
    perPageCount = this.state.perPage
  ) => {
    const startIndex = (activePage - 1) * perPageCount;
    const endIndex = startIndex + perPageCount;

    return array.slice(startIndex, endIndex);
  };

  async componentDidMount() {
    this.setState({ progress: 70 });
    await this.fetchAirflowDags();
    this.setState({ progress: 100 }, () => {});
    this.fetchDAGLastRunsTimerMounted = true;
    this.fetchDAGLastRunsTimeout = this.fetchDAGLastRunsTimer();
    this.setdbturl();

    const resizableColumnsWidth = this.tableRef.current.clientWidth * 0.83;
    const lastColumnWidth = this.tableRef.current.clientWidth * 0.05;
    const eachColumnWidth = resizableColumnsWidth / 5.4;
    this.setState({columnsWidth: [...new Array(5).fill(eachColumnWidth), lastColumnWidth]})
  }

  componentWillUnmount = () => {
    this.fetchDAGLastRunsTimerMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  };

  fetchDAGLastRunsTimer = (dag_id, no, bool, time) => {
    if (dag_id)
      setTimeout(() => {
        this.fetchDAGLastRunsTimerMounted &&
          this.fetchDAGLastRuns(dag_id, no, bool);
      }, time);
  };

  toggleEditModal = (DAG) => {
    this.setState({ selected_job: DAG, modal_edit: !this.state.modal_edit });
  };

  fetchAirflowDags = () => {
    this.setState({
      editing: false,
      get_dags_status: true,
      DAGs: [],
      loading: true,
      loading_spinner_pushes: true,
      progress: 60,
    });
    axios
    .post(config.url.AIRFLOW + "/api/airflow/list_tasks_from_pg", {'dag_type':'push'}) //'/api/airflow/list_dags
      .then((response) => {
        this.setState({
          loading: false,
          DAGs: response.data["dags_list"]
        });
        this.setState({ options: [] });
        console.log(response.data["dags_list"])
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to retrieve pushes.");
      })
      .finally(() => {
        this.setState({
          progress: 100,
          loading: false,
          loading_spinner_pushes: false,
        });
      });
  };

  stopDAG = (DAG_id, dag_run_id) => {
    var toastId = toast.loading(
      "Stopping " + DAG_id + ", Run ID:" + dag_run_id + "..."
    );
    this.setState({ stopping: true });
    this.setState({ progress: 80 });
    axios
      .post(api_url + "/api/airflow/stop_dag", {
        dag_id: DAG_id,
        dag_run_id: dag_run_id,
      })
      .then((response) => {
        toast.success(DAG_id + " stopped successfully.");
      })
      .catch((err) => {
        toast.error("Could not stop your DAG " + DAG_id + ".");
      })
      .finally(() => {
        this.setState({ progress: 100, stopping: false });
        toast.dismiss(toastId);
      });
  };

  toggleDeletepushmodal = (alias,push_id) => {
    this.setState({
      delete_push_id: push_id,
      deleting_push_alias: alias,
      modal_delete_push: !this.state.modal_delete_push,
    });
  };

  toggleDeletedestinationmodal = (destination_name, alias, destination_id) => {
    this.setState({
      delete_destination_id: destination_id,
      deleting_destination_alias: alias,
      destinaton_to_delete: destination_name,
      modal_delete_destination: !this.state.modal_delete_destination,
    });
  };

  triggerDAG = (dag_id) => {
    this.setState({ running_dags: [...this.state.running_dags, dag_id] });
    axios
      .post(api_url + "/api/airflow/trigger_dag", { dag_id: dag_id })
      .then((response) => {
        toast.success("Job triggered successfully.");
      })
      .catch((err) => {
        toast.error("Error triggering your push.");
      })
      .finally(() => {
        this.fetchDAGLastRuns(dag_id, 1, false);
      });
  };

  deletePush = (push_alias,push_id) => {
    var toastId = toast.loading(
      "Deleting your " + this.state.deleting_push_alias + " push..."
    );
    this.setState({ deleting_push: true });
    axios
      .post(config.url.PUSH_URL + "/api/push/delete_push", {
        push_id: push_id,
        push_alias:push_alias
      })
      .then((response) => {
        toast.success("Push deleted successfully.");
        this.fetchAirflowDags();
        this.toggleDeletepushmodal("");
      })
      .catch((err) => {
        toast.error("Error deleting your push.");
      })
      .finally(() => {
        toast.dismiss(toastId);
        this.setState({ deleting_push: false });
      });
  };

  updateJobNameFilters = (filters) =>
    this.setState({ jobNameFilters: filters });

  filterJobNames = (DAG) => {
    const target = DAG.id;
    if (this.state.jobNameFilters) {
      if (target.includes(this.state.jobNameFilters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  fetchDAGLastRuns = (dag_id, page_limit, toastId) => {
    this.setState({ fetching_last_runs: true });
    axios
      .post(api_url + "/api/airflow/get_dag_runs", {
        dag_id: dag_id,
        page_limit: page_limit,
      })
      .then((response) => {
        if (!response.data["runs"].length) {
          this.setState({ historical_runs_available: false });
        } else {
          if (page_limit > 1) {
            this.setState({
              historical_runs_available: true,
              historical_dag_runs: {
                ...this.state.historical_dag_runs,
                [dag_id]: response.data["runs"],
              },
            });
          } else {
            this.setState({
              dag_runs: {
                ...this.state.dag_runs,
                [dag_id]: response.data["runs"],
              },
            });
          }
          if (
            (response.data["runs"][0].state == "running" ||
              response.data["runs"][0].state == "queued") &&
            this.state.get_dags_status
          ) {
            console.log(dag_id + " is running, pinging again...");
            this.fetchDAGLastRunsTimer(dag_id, 1, false, 10000);
          } else {
            this.setState({
              running_dags: this.state.running_dags.filter(
                (dag) => dag != dag_id
              ),
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error while fetching job history.");
      })
      .finally(() => {
        this.setState({ fetching_last_runs: false, progress: 100 });
      });
  };

  renderTableHeadingCell = ({ children, i }) => (
    <ResizableBox
      width={this.state.columnsWidth[i]}
      height={40}
      axis="x"
      minConstraints={[50, 40]}
      resizeHandles={["e"]}
      onResize={(_, data) => {
        const widths = [...this.state.columnsWidth];
        widths[i] = data.size.width;

        this.setState({ columnsWidth: widths });
      }}
      style={{
        display: "flex",
        alignItems: "center",
        paddingRight: "10px"
      }}
    >
      {children}
    </ResizableBox>
  );

  onSortColumn = (column) => {
    const dags = this.state.DAGs.map((dag) => ({ ...dag }));
    let isAsc = true;

    if (this.state.sortedColumn.column === column) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      DAGs: dags.sort((a, b) => {
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  render() {
    const { navigate } = this.props?.router;

    return (
      <div className="container-fluid py-3">
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <Helmet title="Datajolt | Data Pushes" />
        <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "12px 8px",
              height: "45px",
            }}
          >
            <h4 style={{ margin: 0 }}>
              <b style={{ color: "#090E47", marginRight: "10px" }}>
                My Data Pushes{" "}
                {this.state.loading ? "" : "(" + this.state.DAGs.length + ")"}
              </b>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">
                      {"What are Pushes?"}
                    </Popover.Header>
                    <Popover.Body>
                      Pushes help you push the data into the destination where
                      it will be consumed.
                      <br />
                      There can be several pushes to the same destination.
                    </Popover.Body>
                  </Popover>
                }
              >
                <DetailsIcon />
              </OverlayTrigger>
            </h4>

            {this.state.activeTab === "all" && (
              <>
                <center style={{ marginLeft: "auto" }}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>Refresh Pushes</Tooltip>
                    }
                  >
                    {this.state.loading_spinner_pushes ? (
                      <Spinner
                        size="sm"
                        variant="secondary"
                        animation="border"
                      />
                    ) : (
                      <RefreshIcon onClick={() => this.componentDidMount()} />
                    )}
                  </OverlayTrigger>

                  <Button
                    className="lighter-hover"
                    style={{
                      marginLeft: "10px",
                      padding: "5px 7px",
                      borderRadius: "5px",
                      background: "#2D49FD",
                    }}
                    onClick={this.toggleCreatePushForm}
                  >
                    Create a push{" "}
                    <Icon.UploadCloud
                      style={{ marginLeft: "5px" }}
                      size={14}
                      color="white"
                    />
                  </Button>
                </center>
              </>
            )}
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="all">
              <hr
                style={{
                  margin: "0 8px",
                }}
              />
              {!this.state.loading_spinner_pushes &&
              this.state.DAGs.length == 0 ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <center>
                    <h4>You currently have no data push set up.</h4>
                    <br />
                    <Button
                      variant="generaltabs"
                      onClick={() => this.toggleCreatePushForm()}
                      className="active"
                    >
                      Create your first push now
                    </Button>
                  </center>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <table className="table-lists table-daged table-bordered table-triped table-hover my-8px" ref={this.tableRef}>
                    <thead>
                      <tr>
                        <th width="6%"></th>
                        <th
                          id="th-name"
                          style={{
                            width: `${this.state.columnsWidth[0]}px`,
                          }}
                        >
                          <span className="d-flex align-items-center h-100">
                            {this.renderTableHeadingCell({
                              children: (
                                <>
                                  Push alias
                                  <SortBtn
                                    onClick={() => this.onSortColumn("alias")}
                                    isSorted={
                                      this.state.sortedColumn.column === "alias"
                                        ? this.state.sortedColumn.isAsc
                                          ? "asc"
                                          : "desc"
                                        : "none"
                                    }
                                  />
                                </>
                              ),
                              i: 0,
                            })}
                          </span>
                        </th>
                        <th
                          style={{
                            width: `${this.state.columnsWidth[1]}px`,
                          }}
                        >
                          <span className="d-flex align-items-center h-100">
                            {this.renderTableHeadingCell({
                              children: (
                                <>
                                  source in DWH
                                  <SortBtn
                                    onClick={() => this.onSortColumn("push_from")}
                                    isSorted={
                                      this.state.sortedColumn.column === "push_from"
                                        ? this.state.sortedColumn.isAsc
                                          ? "asc"
                                          : "desc"
                                        : "none"
                                    }
                                  />
                                </>
                              ),
                              i: 1,
                            })}
                          </span>
                        </th>
                        <th style={{width: `${this.state.columnsWidth[2]}px`}}>
                          {this.renderTableHeadingCell({
                            children: (
                              <div className="d-flex justify-content-start gap-1 align-items-center w-100 h-100">
                                <span>destination</span>
                                <SearchJobs
                                  onFilterChange={this.updateJobNameFilters}
                                />
                                <SortBtn
                                  onClick={() => this.onSortColumn("push_to")}
                                  isSorted={
                                    this.state.sortedColumn.column === "push_to"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </div>
                            ),
                            i: 2,
                          })}
                        </th>
                        <th
                          style={{
                            width: `${this.state.columnsWidth[3]}px`,
                          }}
                        >
                          <span className="d-flex align-items-center h-100">
                            {this.renderTableHeadingCell({
                              children: (
                                <>
                                  last started
                                  <SortBtn
                                    onClick={() => this.onSortColumn("last_started")}
                                    isSorted={
                                      this.state.sortedColumn.column === "last_started"
                                        ? this.state.sortedColumn.isAsc
                                          ? "asc"
                                          : "desc"
                                        : "none"
                                    }
                                  />
                                </>
                              ),
                              i: 3,
                            })}
                          </span>
                        </th>
                        <th
                          style={{
                            width: `${this.state.columnsWidth[4]}px`,
                          }}
                        >
                          <span className="d-flex align-items-center h-100">
                            {this.renderTableHeadingCell({
                              children: (
                                <>
                                  last ended
                                  <SortBtn
                                    onClick={() => this.onSortColumn("last_ended")}
                                    isSorted={
                                      this.state.sortedColumn.column === "last_ended"
                                        ? this.state.sortedColumn.isAsc
                                          ? "asc"
                                          : "desc"
                                        : "none"
                                    }
                                  />
                                </>
                              ),
                              i: 4,
                            })}
                          </span>
                        </th>
                        <th
                          style={{
                            width: `${this.state.columnsWidth[5]}px`,
                          }}
                        >
                          {this.renderTableHeadingCell({
                            children: "status",
                            i: 5,
                          })}
                        </th>
                        <th width="10%">actions</th>
                      </tr>
                    </thead>
                    {this.state.loading ? (
                      <LoadingTableBody colNum={8} />
                    ) : (
                      <tbody>
                        {this.state.DAGs.length > 0 &&
                          this.paginateArray(
                            this.state.DAGs.filter((DAGtarget) =>
                              this.filterJobNames(DAGtarget)
                            )
                          ).map((dag, index) =>
                              <tr key={dag.id + index}>
                                <td>
                                  {dag.logo && (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: 5,
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        className="rounded-table-logo"
                                        style={{
                                          transform: "scale(0.8)",
                                        }}
                                        src={dag.logo}
                                      ></img>
                                      <span
                                        style={{
                                          width: "1.5px",
                                          height: "50px",
                                          background: "#EBEBEB",
                                          display: "block",
                                        }}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td>{dag.alias}</td>
                                <td>{dag.push_from}</td>
                                <td className="text-break">
                                  {/* {dag.subtype == "Google Sheets" ? (
                                    <a
                                      href={dag.push_to_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {dag.push_to}
                                    </a>
                                  ) : ( */}
                                    {dag.push_to}
                                  {/* )} */}
                                </td>
                                <td>
                                    {dag.last_started == "None"
                                      ? ""
                                      : moment
                                          .utc(dag.last_started)
                                          .tz(customer_timezone)
                                          .format("YYYY-MM-DD HH:mm")}
                                  </td>
                                  <td>
                                    {dag.last_ended == "None"
                                      ? ""
                                      : moment
                                          .utc(dag.last_ended)
                                          .tz(customer_timezone)
                                          .format("YYYY-MM-DD HH:mm")}
                                  </td>
                                <td>
                                    {dag.status == "failed" ||
                                    dag.status == "cancelled" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {dag.status}
                                          </Tooltip>
                                        }
                                      >
                                        <FailedIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : dag.status == "success" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Success
                                          </Tooltip>
                                        }
                                      >
                                        <SuccessIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : dag.status == "queued" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Queued
                                          </Tooltip>
                                        }
                                      >
                                        <QueuedIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : dag.status == "running" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Running
                                          </Tooltip>
                                        }
                                      >
                                        <RunningLottie className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : (
                                      dag.status
                                    )}
                                  </td>

                                <td width="10%">
                                  <HorizontalDropdownMenu>
                                    <OverlayTrigger
                                      rootClose
                                      trigger={["hover", "hover"]}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Trigger Push
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant="no-outline-success"
                                        disabled={true}
                                        onClick={() => this.triggerDAG(dag.id)}
                                      >
                                        {this.state.running_dags.includes(
                                          dag.id
                                        ) ? (
                                          <Spinner
                                            as="span"
                                            variant="success"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"
                                          />
                                        ) : (
                                          <PlayIcon />
                                        )}
                                      </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      rootClose
                                      trigger={["hover", "hover"]}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Push Details
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant="no-outline"
                                        disabled={dag.subtype == "Tableau" ? true : false}
                                        className="me-1 mb-1"
                                        onClick={() =>
                                          navigate(
                                            `/jobs/push?edit_push=${dag.id}`
                                          )
                                        }
                                      >
                                        <SettingsIcon className="bigger-svg" />
                                      </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      rootClose
                                      trigger={["hover", "hover"]}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Stop Push
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant="no-outline-danger"
                                        className="me-1 mb-1"
                                        disabled={true}
                                        onClick={() =>
                                          this.stopDAG(
                                            dag.id,
                                            this.state.dag_runs[dag.id][0]
                                              .dag_run_id
                                          )
                                        }
                                      >
                                        <StopIcon />
                                      </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      rootClose
                                      trigger={["hover", "hover"]}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          Delete Push
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant="no-outline-danger"
                                        className="mb-1"
                                        onClick={() =>
                                          this.toggleDeletepushmodal(dag.alias,dag.id)
                                        }
                                      >
                                        <CancelIcon />
                                      </Button>
                                    </OverlayTrigger>
                                  </HorizontalDropdownMenu>{" "}
                                </td>
                              </tr>
                          )}
                      </tbody>
                    )}
                  </table>
                  <TablePagination
                    currentPage={this.state.currentPage}
                    totalPages={this.calculateTotalPages()}
                    updatePageNum={(num) => this.setState({ currentPage: num })}
                    perPage={this.state.perPage}
                    updatePerPage={(num) => this.setState({ perPage: num })}
                    activeTable={this.state.jobNameFilters}
                  />
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <Modal
                show={this.state.modal_delete_push}
                onHide={() => this.toggleDeletepushmodal()}
                centered
              >
                <Modal.Header closeButton>
                  <b>Warning!</b>
                  <img
                    src={this.state.delete_logo}
                    width="5%"
                    style={{
                      marginTop: "-3px",
                      position: "absolute",
                      right: "40px",
                    }}
                  ></img>
                </Modal.Header>
                <Modal.Body className="text-center m-2">
                  <center>
                    You are about to delete your{" "}
                    <b>{this.state.deleting_push_alias}
                    </b>{" "}
                    push.
                    <br />
                    <br />
                    <b>Are you sure ?</b>
                  </center>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    <Icon.X size={16} />
                    &nbsp; No
                  </Button>{" "}
                  <Button
                    variant="danger"
                    disabled={this.state.deleting_push}
                    onClick={() =>
                      this.deletePush(
                        this.state.deleting_push_alias,
                        this.state.delete_push_id,
                      )
                    }
                  >
                    <Icon.Trash2 size={16} />
                    &nbsp;
                    {this.state.deleting_push ? (
                      <>
                        Deleting...&nbsp;&nbsp;
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      </>
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

        <CSSTransition
          in={this.state.isShowingDagHistoryTable}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <DagHistory
            dagList={Boolean(this.state.DAGs.length) && this.state.DAGs}
            onClose={() => {
              this.setState({
                isShowingDagHistoryTable: false,
              });
              navigate("/jobs/push");
            }}
          />
        </CSSTransition>

        <CSSTransition
          in={this.state.isShowingCreatePushForm}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <PushCreatorForm
            dagsList={this.state?.DAGs}
            setSelectedPushData={this.setSelectedPushData}
            isEditingPush={new URLSearchParams(window.location.search).get(
              "edit_push"
            )}
            toggleCreatePushForm={this.toggleCreatePushForm}
            fetchAirflowDags={this.fetchAirflowDags}
            editorURL={this.state.dbt_url}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default WithRouter(TenantContextComponent);
