import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from "react-flow-renderer";
import { toast } from "react-toastify";
import WorkflowDAG from "./workflowDAG";
import { ReactFlowProvider } from "react-flow-renderer";
import { debounce } from "lodash";
import { CSSTransition } from "react-transition-group";

import DagHistory from "../DagHistory";
import axios from "../../../utils/axios";
import { config } from "../../../constants";

import "./workflows.css";
import DatajoltSpinner from "../../../components/DatajoltSpinner";

const nodeTypes = { workflowDAG: WorkflowDAG };

export default function WorkflowManger({
  editorURL,
  DAGList,
  editWorkflow = false,
  updateWorkflows,
  setCurrentWorkflow,
  listDags
}) {
  const [isShowingDagHistoryTable, setIsShowingDagHistoryTable] =
    useState(false);
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const prevNodesRef = useRef([]);
  const prevEdgesRef = useRef([]);

  const setInitialNodes = (statusesList) => {
    updateNodeStatuses();
    if (editWorkflow.initial_workflow_chain) {
      const starterEdges = [];
      const starterData = [...editWorkflow.initial_workflow_chain].map(
        (node) => {
          if (node.dependencies.length) {
            node.dependencies.forEach((dep) => {
              starterEdges.push({
                target: node.dag,
                source: dep,
                id: `${node.dag}-${dep}`,
              });
            });
          }
          return {
            id: node.dag,
            type: "workflowDAG",
            position: {
              x: node?.position?.x ? parseFloat(node.position.x) : 0,
              y: node?.position?.y ? parseFloat(node.position.y) : 0,
            },
            data: {
              label: `${node.dag}`,
              logo: node.logo,
              nodeData: {
                ...DAGList.find((target) => target.id === node.dag),
                ...(statusesList && statusesList[node.dag]),
              },
              _setIsShowingDagHistoryTable: setIsShowingDagHistoryTable,
              _handleDelete: undefined,
            },
          };
        }
      );
      setNodes(starterData);
      setEdges(starterEdges);
    }
  };

  useEffect(() => {
    setInitialNodes();
  }, [JSON.stringify(editWorkflow)]);

  const handleCanvasUpdate = useCallback(
    debounce((nodesData, edgesData) => {
      const updatedWorkflowChain = combineNodesAndEdges(nodesData, edgesData);
      updateWorkflows(updatedWorkflowChain);
      console.log(updatedWorkflowChain, "updated workflow chain2");
    }, 1000),
    []
  );

  function combineNodesAndEdges(nodes, edges) {
    const uniqueIds = new Set();
    const filteredNodes = [];

    nodes.forEach((node) => {
      if (!uniqueIds.has(node.id)) {
        uniqueIds.add(node.id);
        filteredNodes.push(node);
      }
    });

    const combinedArray = filteredNodes.map((node) => {
      const dependencies = edges
        .filter((edge) => edge.target === node.id)
        .map((edge) => edge.source);

      return {
        dag: node.id,
        dependencies,
        position: node.position,
      };
    });

    return combinedArray;
  }

  useEffect(() => {
    if (
      JSON.stringify(nodes) !== JSON.stringify(prevNodesRef.current) ||
      JSON.stringify(edges) !== JSON.stringify(prevEdgesRef.current)
    ) {
      handleCanvasUpdate(nodes, edges);
      prevNodesRef.current = nodes;
      prevEdgesRef.current = edges;
    }
  }, [nodes, edges]);

  const updateNodeStatuses = async () => {
    try {
      const response = await axios.post(
        config.url.WORKFLOWS + "/api/workflows/metadata/task_level",
        {
          run_id: editWorkflow.run_id,
          workflow_name: editWorkflow.workflow_name,
        }
      );

      const workflow_dag_status = response?.data;
      setNodes((nds) =>
        nds.map((node) => {
          const updatedNodeData = workflow_dag_status[node.id];
          return {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...updatedNodeData,
              },
            },
          };
        })
      );
    } catch (error) {
      toast.error("Error retrieving tasks status");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateNodeStatuses();
    }, 7000); // Fetch node statuses every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array ensures this runs once on mount

  const handleRefreshing = () => {
    console.log("Refreshing visualization...");
    const dag = DAGList.find(dag => dag.workflowname === editWorkflow.workflow_name);
    var advanced_settings = dag.advanced_settings;
    setIsLoading(true);
    
    axios.post(editorURL + "/api/sql/refresh_dag_lineage", {
      advanced_settings:advanced_settings
    })
    .then(response => {
      if (response.status === 200) {
        toast.success("DAG lineage refreshed successfully.");
        const newLineage = response.data.lineage;
        console.log("New Lineage: ");
        console.log(newLineage);
        setCurrentWorkflow(newLineage);
        listDags();
      } else {
        toast.error("Error refreshing DAG lineage.");
      }
    })
    .catch(error => {
      console.log(error);
      toast.error("Error refreshing DAG lineage.");
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <CSSTransition
        in={isShowingDagHistoryTable}
        classNames="form-tab"
        timeout={300}
        unmountOnExit
      >
        <DagHistory
          dagList={DAGList}
          historyModalId={isShowingDagHistoryTable}
          onClose={() => setIsShowingDagHistoryTable(false)}
        />
      </CSSTransition>
      <section id="workflowManager">
        <ReactFlowProvider>
          <div style={{ height: "calc(100vh - 140px)" }}>
            {Boolean(nodes.length && !isLoading) ? (
              <div className="dndflow canvas-wrapper canvas-wrapper--full-width">
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    deleteKeyCode="192"
                    onNodesChange={onNodesChange}
                    nodes={nodes}
                    edges={edges}
                    onInit={setReactFlowInstance}
                    nodeTypes={nodeTypes}
                    minZoom={0.05}
                    maxZoom={0.8}
                    fitView
                  >
                    <Controls />
                  </ReactFlow>
                </div>
              </div>
            ) : (
              <div className="row loading-workflow-visuals">
                <br></br>
                <center>
                  <DatajoltSpinner />
                </center>
              </div>
            )}
          </div>
        </ReactFlowProvider>

        <button
          style={{
            position: "absolute",
            right: "16px",
            bottom: "16px",
            color: "white",
            border: "1px solid #D0D5DD",
            borderRadius: "5px",
            background: "#818589",
            padding: "10px 15px",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "25px",
            zIndex: 10,
          }}
          onClick={handleRefreshing}
        >
          Refresh Visualisation
        </button>
      </section>
    </>
  );
}
