import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ContextMenu = ({ dropdownAnchor, onClose, options }) => {
  return (
    <Menu
      anchorEl={dropdownAnchor.element}
      open={Boolean(dropdownAnchor.id)}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={dropdownAnchor.position}
      sx={{
        "& .MuiMenu-list": { padding: "1px" },
      }}
    >
      {options.map(({ OptionIcon, label, onClick }) => (
        <MenuItem
          onClick={() => {
            onClick();
            onClose();
          }}
          sx={{ fontSize: "12px", paddingX: 1 }}
          key={label}
        >
          <span>
            <OptionIcon
              size={14}
              style={{
                marginRight: 5,
                position: "relative",
                top: -1,
              }}
            />
            {label}
          </span>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ContextMenu;
