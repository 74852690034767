import React, { useState, useEffect } from "react";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import { Form, Button, Accordion, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify"; // Make sure to import toast if you're using it

export default function PullRequestModal({
  setIsShown,
  selectedBranch,
  dbtCommand,
  activeTenant,
  test_dbt_model,
  dbt_test_status,
  dbt_logs,
  dbt_url
}) {
  const [showBypassModal, setShowBypassModal] = useState(false);
  const [localDbtTestStatus, setLocalDbtTestStatus] = useState(dbt_test_status);

  useEffect(() => {
    // Call test_dbt_model when the modal is opened
    if(!localDbtTestStatus && !dbt_logs) {
        test_dbt_model(dbtCommand);
    }
  }, []); // Empty dependency array ensures this runs only once when the modal is opened

  useEffect(() => {
    setLocalDbtTestStatus(dbt_test_status);
  }, [dbt_test_status]);

  const handleCreatePullRequest = () => {
    if (localDbtTestStatus === "failed" || !localDbtTestStatus) {
      setShowBypassModal(true);
    } else {
      openGitHubPullRequest();
    }
  };

  const openGitHubPullRequest = () => {
    console.log("Creating pull request");
    if (!localDbtTestStatus) {
    axios
      .post(dbt_url + "/api/sql/kill_dbt_process")
      .then((response) => {
        console.log("DBT process killed successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error killing DBT process:", error);
      });
    }
      const tenantName = typeof activeTenant === 'string' ? activeTenant : activeTenant.name || 'default';
      const pullRequestUrl = `https://github.com/datajolt/${tenantName}/compare/main...${selectedBranch}`;
      window.open(pullRequestUrl, "_blank");
    };    

  const handleTestClick = () => {
    setLocalDbtTestStatus(null);
    test_dbt_model(dbtCommand);
  };

  const getStatusIcon = (isButton = false) => {
    if (!localDbtTestStatus) {
      return (
        <Spinner
          as="span"
          variant={isButton ? "light" : "primary"}
          size="sm"
          role="status"
          aria-hidden="true"
          animation="border"
        />
      );
    } else if (localDbtTestStatus === "success") {
      return <Icon.Check color={isButton ? "white" : "green"} />;
    } else if (localDbtTestStatus === "failed") {
      return <Icon.X color={isButton ? "white" : "red"} />;
    }
    return null;
  };

  return (
    <>
      <ResizableBox
        className={`dragged-box react-resizable sidebar-modal-wrapper`}
        height={window.innerHeight - 50}
        width={window.innerWidth / 3}
        maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
        minConstraints={[400, 400]}
        resizeHandles={["w"]}
        id="active-modal-sidebar"
      >
        <section className="schedule-sidebar-content">
          <h2 className="heading-schedule-model">
            <small
              style={{
                fontWeight: 500,
                fontSize: 12,
                border: "2px solid #D0D5DD",
                borderRadius: "7px",
                display: "flex",
                alignItems: "center",
                padding: "12px 15px",
                height: "48px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >            
              <Icon.ChevronsRight
                color="#438593"
                onClick={() => setIsShown(false)}
                role="button"
              />{" "}
              Pull Request for {selectedBranch}
            </small>
          </h2>
          <Form>
            <Form.Group controlId="dependencies">
              <Form.Label>Testing your branch with the following command</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  value={dbtCommand}
                  type="text"
                  disabled
                />
                <Button
                  variant="secondary"
                  className="ml-2 test-button"
                  onClick={handleTestClick}
                  disabled={!localDbtTestStatus}
                >
                  <Icon.Code width={15} className="button-icon" />
                  {localDbtTestStatus ? "Test" : "Testing..."}
                </Button>
              </div>
            </Form.Group>
            <br />
            <Accordion defaultActiveKey="0" className="pl-0 mb-3">
              <Accordion.Item eventKey="0" className="bg-white">
                <Accordion.Header>
                  DBT Test Logs &nbsp;&nbsp;&nbsp;{" "}
                  {getStatusIcon()}
                </Accordion.Header>
                {dbt_logs && (
                  <Accordion.Body className="p-0 m-2">
                    <div
                      className="log-display"
                      dangerouslySetInnerHTML={{
                        __html: dbt_logs,
                      }}
                    />
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </Form>

          <div className="d-flex justify-content-between gap-2 mt-auto schedule-model-btns-wrapper">
            <Button
              variant={localDbtTestStatus === "failed" ? "danger" : "primary"}
              className={`w-100 sc-btn ${localDbtTestStatus === "failed" || !localDbtTestStatus ? "btn-danger" : ""}`}
              style={localDbtTestStatus === "failed" || !localDbtTestStatus ? { backgroundColor: "#dc3545", borderColor: "#dc3545" } : {}}
              onClick={handleCreatePullRequest}
            >
              {localDbtTestStatus === "failed" || !localDbtTestStatus ? (
                <>Bypass test & Create pull request</>
              ) : (
                <>Create Pull Request <Icon.GitPullRequest width={24} className="button-icon" /></>
              )}
            </Button>
          </div>
        </section>
      </ResizableBox>

      <Modal show={showBypassModal} onHide={() => setShowBypassModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Bypass Tests Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to bypass tests? This may impact your production DAGs.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBypassModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => {
            setShowBypassModal(false);
            setLocalDbtTestStatus("failed");
            openGitHubPullRequest();
          }}>
            Yes, bypass
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}