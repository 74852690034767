import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Header from "./Header";
import Statistics from "./Statistics";

const Default = () => (
  <React.Fragment>
    <Helmet title="Datajolt | Home" />
    <Container fluid className="p-0">
      <Header />
      <Statistics />
    </Container>
  </React.Fragment>
);

export default Default;
