import React from "react";

import { Col, Row } from "react-bootstrap";
import { useAuth as FronteggAuth } from "@frontegg/react";

const Header = () => {
  const { user } = FronteggAuth();

  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="ms-auto text-end mt-n1"></Col>
    </Row>
  );
};

export default Header;
