import { toast } from "react-toastify";
import axios from "../utils/axios";
import { config } from "../constants";

export function stopTask(display_task_id, datajolt_task_id, setState) {
  var toastId = toast.loading(
    "Stopping " +
      display_task_id.substring(display_task_id.indexOf("_") + 1) +
      "..."
  );
  setState({ stopping: true });
  setState({ progress: 80 });
  axios
    .post(config.url.AIRFLOW + "/api/datajolt/stop_task", {
      datajolt_task_id: datajolt_task_id,
    })
    .then((response) => {
      toast.success(display_task_id + " stopped successfully.", {
        autoClose: 5000,
      });
      setState((prevState) => {
        const updatedDAGs = prevState.mytasks.map((dag) =>
          dag.datajolt_task_id === datajolt_task_id
            ? { ...dag, status: "canceled" }
            : dag
        );
        return { ...prevState, DAGs: updatedDAGs };
      });
    })
    .catch((err) => {
      toast.error("Could not stop your task " + display_task_id + ".", {
        autoClose: 5000,
      });
    })
    .finally(() => {
      setState({ progress: 100, stopping: false });
      toast.dismiss(toastId);
    });
}

export const fetchLastRuns = (
  datajolt_task_id,
  page_limit,
  updateState,
  isComponentMounted
) => {
  axios
    .post(config.url.METADATA + "/api/get_historical_runs", {
      datajolt_task_id: datajolt_task_id,
      page_limit: page_limit,
    })
    .then((response) => {
      console.log("Response", response.data["runs"]);
      if (response.data["runs"].length) {
        if (page_limit > 1) {
          updateState((prevState) => ({
            historical_runs_available: true,
            historical_dag_runs: {
              ...prevState.historical_dag_runs,
              [datajolt_task_id]: response.data["runs"],
            },
          }));
        } else {
          if (
            response?.data?.["runs"]?.[0]?.state === "running" ||
            response?.data?.["runs"]?.[0]?.state === "queued"
          ) {
            console.log(
              datajolt_task_id + " is still running, pinging again..."
            );
            setTimeout(() => {
              if (isComponentMounted()) {
                fetchLastRuns(
                  datajolt_task_id,
                  1,
                  updateState,
                  isComponentMounted
                );
              }
            }, 5000);
          } else {
            console.log(datajolt_task_id + " is finished. Updating its status");

            // Update the state
            updateState((prevState) => {
              const updatedMytasks = prevState.mytasks.map((dag) => {
                if (dag.datajolt_task_id === datajolt_task_id) {
                  return {
                    ...dag,
                    status: response?.data?.["runs"]?.[0]?.state,
                    last_started: response?.data?.["runs"]?.[0]?.last_started,
                    last_ended: response?.data?.["runs"]?.[0]?.last_ended,
                    rows: response?.data?.["runs"]?.[0]?.rows,
                  };
                }
                return dag;
              });
              return { mytasks: updatedMytasks };
            });
          }
        }
      }
    })
    .catch((err) => {
      toast.error("Error while fetching job history.");
      updateState((prevState) => ({
        historical_runs_available: false,
      }));
    })
    .finally(() => {
      updateState((prevState) => ({
        fetching_last_runs: false,
        progress: 100,
      }));
    });
};

export function runTask(display_task_id, datajolt_task_id, setState) {
  var toastId = toast.loading(
    "Stopping " +
      display_task_id.substring(display_task_id.indexOf("_") + 1) +
      "..."
  );
  setState({ stopping: true });
  setState({ progress: 80 });
  axios
    .post(config.url.AIRFLOW + "/api/tasks/run_task", {
      datajolt_task_id: datajolt_task_id,
    })
    .then((response) => {
      toast.success(display_task_id + " stopped successfully.", {
        autoClose: 5000,
      });
      setState((prevState) => {
        const updatedDAGs = prevState.DAGs.map((dag) =>
          dag.datajolt_task_id === datajolt_task_id
            ? { ...dag, status: "canceled" }
            : dag
        );
        return { ...prevState, DAGs: updatedDAGs };
      });
    })
    .catch((err) => {
      toast.error("Could not stop your task " + display_task_id + ".", {
        autoClose: 5000,
      });
    })
    .finally(() => {
      setState({ progress: 100, stopping: false });
      toast.dismiss(toastId);
    });
}
