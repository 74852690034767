export const searchForFolder = (folder, folderId, cb) => {
  const recursionCalls = (folder) => {
    for (let i = 0; i < folder.length; i++) {
      if (folder[i].id === folderId) {
        cb(folder[i], folder, i);

        return;
      }

      if (
        folder[i].children &&
        folderId.includes(folder[i].id) &&
        recursionCalls(folder[i].children)
      )
        return;
    }
  };

  return recursionCalls(folder);
};


export const searchByName = (folder, name, cb) => {
  const recursionCalls = (folder) => {
    for (let i = 0; i < folder.length; i++) {
      if (folder[i].name === name) {
        cb(folder[i]);
  
        return;
      }
  
      if (
        folder[i].children &&
        recursionCalls(folder[i].children)
      )
        return;
    }
  };
  
  return recursionCalls(folder);
}