import React from "react";
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/index.css";
import "react-loading-skeleton/dist/skeleton.css";

import "./i18n";
import routes from "./routes";

//import TenantMatcher from "./HOC/TenantMatcher";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { AuthProvider } from "./contexts/JWTContext";
import { ScheduleCrudProvider } from "./contexts/ScheduleCrudContext";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./pages/error";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s" defaultTitle="Datajolt" />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScheduleCrudProvider>
            <ThemeProvider>
              <SidebarProvider>
                <LayoutProvider>
                  <AuthProvider>
                    <ToastContainer
                      position="top-right"
                      closeOnClick
                      pauseOnHover
                      pauseOnFocusLoss
                      style={{ height: "50px", minHeight: "50px" }}
                    />
                    <ErrorBoundary fallback={<ErrorFallback />}>
                      {content}
                    </ErrorBoundary>
                  </AuthProvider>
                </LayoutProvider>
              </SidebarProvider>
            </ThemeProvider>
          </ScheduleCrudProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
