import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from "react-flow-renderer";
import { toast } from "react-toastify";
import WorkflowDAG from "./workflowDAG";
import { ReactFlowProvider } from "react-flow-renderer";

import useIntervalMethodCaller from "../../../hooks/useIntervalMethodCaller";
import axios from "../../../utils/axios";
import { config } from "../../../constants";

import "./workflows.css";
import DatajoltSpinner from "../../../components/DatajoltSpinner";
import { useSelector } from "react-redux";

const nodeTypes = { workflowDAG: WorkflowDAG };

var api_url = config.url.API_URL;

export default function LineageVisulaizer({ editWorkflow = false }) {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [dagsStatuses, setDagsStatuses] = useState([]);
  const [DAGList, setDAGList] = useState([]);
  const [init, setInit] = useState(true);

  const activeFile = useSelector((state) => state.activeTab);

  const checkActiveFile = (fileName) => {
    const activeFileName = activeFile.split("/");
    const targetFileName = activeFileName[activeFileName.length - 1];

    return (
      `${fileName.substring(fileName.indexOf("_") + 1)}.sql` === targetFileName
    );
  };

  const getInitialNodes = async () => {
    if (!editWorkflow?.initial_workflow_chain || !editWorkflow?.run_id) {
      setInitialNodes();
      return;
    }

    axios
      .post(api_url + "/api/workflows/metadata/dag_level", {
        run_id: editWorkflow.run_id,
        workflow_name: editWorkflow.workflow_name,
      })
      .then((response) => {
        setInitialNodes(response.data);
        setDagsStatuses(Object.values(response.data).map((item) => item.state));
      })
      .catch((err) => {
        toast.error("Error fetching your tasks status");
        setInitialNodes();
      });
  };

  console.log("Initial workflowchain");
  console.log(editWorkflow.initial_workflow_chain);
  console.log("DAGList");
  console.log(DAGList);
  const setInitialNodes = (statusesList) => {
    if (editWorkflow.initial_workflow_chain) {
      const starterEdges = [];
      const starterData = [...editWorkflow.initial_workflow_chain].map(
        (node) => {
          console.log("Node inside setInitialNodes");
          console.log(node);
          console.log(node.logo);
          if (node.dependencies.length) {
            node.dependencies.forEach((dep) => {
              starterEdges.push({
                target: node.dag,
                source: dep,
                id: `${node.dag}-${dep}`,
              });
            });
          }
          console.log({
            id: node.dag,
            type: "workflowDAG",
            position: {
              x: node?.position?.x ? parseFloat(node.position.x) : 0,
              y: node?.position?.y ? parseFloat(node.position.y) : 0,
            },
            data: {
              label: `${node.dag}`,
              isActiveFile: checkActiveFile(node.dag),
              nodeData: {
                logo: node.logo,
                type: node.type,
                ...DAGList.find((target) => target.id === node.dag),
                ...(statusesList && statusesList[node.dag]),
              },
              _setIsShowingDagHistoryTable: undefined,
              _handleDelete: undefined,
            },
          });
          return {
            id: node.dag,
            type: "workflowDAG",
            position: {
              x: node?.position?.x ? parseFloat(node.position.x) : 0,
              y: node?.position?.y ? parseFloat(node.position.y) : 0,
            },
            data: {
              label: `${node.dag}`,
              isActiveFile: checkActiveFile(node.dag),
              logo: node.logo,
              nodeData: {
                logo: node.logo,
                type: node.type,
                ...DAGList.find((target) => target.id === node.dag),
                ...(statusesList && statusesList[node.dag]),
              },
              _setIsShowingDagHistoryTable: undefined,
              _handleDelete: undefined,
            },
          };
        }
      );
      setNodes((nds) => nds.concat(starterData));
      setEdges((edges) => edges.concat(starterEdges));
    }
  };

  const updateOpenedFilesNodes = () => {
    setNodes(
      nodes.map((item) => {
        return {
          ...item,
          data: {
            ...item.data,
            isActiveFile: checkActiveFile(item.data.label),
          },
        };
      })
    );
  };

  useEffect(() => {
    getInitialNodes();
  }, []);

  useEffect(() => {
    if (!init) {
      updateOpenedFilesNodes();
    }
    setInit(false);
  }, [activeFile]);

  return (
    <>
      <section id="workflowManager" className="Lineage-Vis">
        <ReactFlowProvider>
          <div style={{ height: "calc(100vh - 200px)" }}>
            {Boolean(nodes.length) ? (
              <div className="dndflow canvas-wrapper canvas-wrapper--full-width">
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    deleteKeyCode="192"
                    onNodesChange={onNodesChange}
                    nodes={nodes}
                    edges={edges}
                    onInit={setReactFlowInstance}
                    nodeTypes={nodeTypes}
                    minZoom={0.05}
                    maxZoom={0.8}
                    fitView
                  >
                    <Controls />
                  </ReactFlow>
                </div>
              </div>
            ) : (
              <div className="row loading-workflow-visuals">
                <br></br>
                <center>
                  <DatajoltSpinner />
                </center>
              </div>
            )}
          </div>
        </ReactFlowProvider>
      </section>
    </>
  );
}
