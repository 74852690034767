import { debounce } from "lodash";
import React, { createContext, useCallback, useEffect, useState } from "react";

export const HeightContext = createContext({
  height: {
    queries: 0,
    schema: 0,
    history: 0,
  },
  collapsed: { schema: false, queries: false, history: false },
  onResizeBox: () => {},
});

const LeftBoxes = ({ children }) => {
  const [fullHeight, setFullHeight] = useState(
    document.documentElement.clientHeight - 136
  );
  const [height, setHeight] = useState({
    queries: fullHeight / 3,
    schema: fullHeight / 3,
    history: fullHeight / 3,
  });
  const [collapsed, setCollapsed] = useState({
    schema: false,
    queries: false,
    history: false,
  });

  useEffect(() => {
    const savedHeight = localStorage.getItem("boxesExpandedHeight");
    const savedCollapsed = localStorage.getItem("boxesCollapseState");

    if (savedHeight) setHeight(JSON.parse(savedHeight));
    else localStorage.setItem("boxesExpandedHeight", JSON.stringify(height));

    if (savedCollapsed) setCollapsed(JSON.parse(savedCollapsed));
    else localStorage.setItem("boxesCollapseState", JSON.stringify(collapsed));
  }, []);

  const resetHeight = useCallback(() => {
    const newHeight = document.documentElement.clientHeight - 136;
    const heightDiff = newHeight - fullHeight;
    let newBoxesHeight = { ...height };

    if (heightDiff < 0) {
      if (heightDiff + height.history >= 48 && !collapsed.history) {
        newBoxesHeight.history += heightDiff;
      } else {
        if (heightDiff + height.queries >= 48 && !collapsed.queries) {
          newBoxesHeight.history = 48;
          newBoxesHeight.queries += heightDiff + (height.history - 48);
        } else {
          newBoxesHeight.history = 48;
          newBoxesHeight.queries = 48;
          newBoxesHeight.schema = collapsed.schema ? 48 : newHeight - 96;
        }
      }
    } else {
      if (!collapsed.history) {
        newBoxesHeight.history += heightDiff;
      } else if (!collapsed.queries) {
        newBoxesHeight.queries += heightDiff;
      } else if (!collapsed.schema) {
        newBoxesHeight.schema += heightDiff;
      }
    }

    setFullHeight(newHeight);
    setHeight(newBoxesHeight);
    localStorage.setItem("boxesExpandedHeight", JSON.stringify(newBoxesHeight));
  }, [collapsed, fullHeight, height]);

  useEffect(() => {
    const debounceEvent = debounce(resetHeight, 300);

    window.addEventListener("resize", debounceEvent);

    return () => window.removeEventListener("resize", debounceEvent);
  }, [resetHeight]);

  /**
   * For queries box => [schema, history]
   * For history box => [queries, schema]
   * For schema box => [queries, history]
   */
  const onChangeHeight = (targetBox, firstBox, secondBox, newHeight) => {
    const targetBoxHeight = newHeight;
    const firstBoxHeight = height[firstBox] + height[targetBox] - newHeight;
    const secondBoxHeight = height[secondBox];

    return [targetBoxHeight, firstBoxHeight, secondBoxHeight];
  };

  const onExpndBox = (firstBox, secondBox) => {
    const targetBoxHeight = collapsed[firstBox]
      ? collapsed[secondBox]
        ? fullHeight - 96
        : height[secondBox] / 2 + 24
      : height[firstBox] / 2 + 24;
    const firstBoxHeight = collapsed[firstBox] ? 48 : height[firstBox] / 2 + 24;
    const secondBoxHeight =
      collapsed[firstBox] && !collapsed[secondBox]
        ? height[secondBox] / 2 + 24
        : height[secondBox];

    return [targetBoxHeight, firstBoxHeight, secondBoxHeight];
  };

  const onCollapseBox = (firstBox, secondBox) => {
    const targetBoxHeight = 48;
    const firstBoxHeight = collapsed[firstBox]
      ? 48
      : fullHeight - 48 - height[secondBox];
    const secondBoxHeight =
      collapsed[firstBox] && !collapsed[secondBox]
        ? fullHeight - 96
        : height[secondBox];

    return [targetBoxHeight, firstBoxHeight, secondBoxHeight];
  };

  const getOtherBoxes = (box) => {
    if (box === "queries") {
      return ["schema", "history"];
    } else if (box === "history") {
      return ["queries", "schema"];
    } else {
      return ["queries", "history"];
    }
  };

  const onResizeBox = (box, newHeight) => {
    const otherBoxes = getOtherBoxes(box);
    let heights;

    if (newHeight === -2) {
      // Collapse box
      localStorage.setItem(
        "boxesCollapseState",
        JSON.stringify({ ...collapsed, [box]: true })
      );
      setCollapsed({ ...collapsed, [box]: true });
      heights = onCollapseBox(...otherBoxes);
    } else if (newHeight === -1) {
      // Expand box
      localStorage.setItem(
        "boxesCollapseState",
        JSON.stringify({ ...collapsed, [box]: false })
      );
      setCollapsed({ ...collapsed, [box]: false });
      heights = onExpndBox(...otherBoxes);
    } else {
      // Resize box
      heights = onChangeHeight(box, ...otherBoxes, newHeight);
    }

    setHeight({
      [box]: heights[0],
      [otherBoxes[0]]: heights[1],
      [otherBoxes[1]]: heights[2],
    });
    localStorage.setItem(
      "boxesExpandedHeight",
      JSON.stringify({
        [box]: heights[0],
        [otherBoxes[0]]: heights[1],
        [otherBoxes[1]]: heights[2],
      })
    );
  };

  return (
    <HeightContext.Provider value={{ height, onResizeBox, collapsed }}>
      {children}
    </HeightContext.Provider>
  );
};

export default LeftBoxes;
