import React from "react";
import * as Icon from "react-feather";

const SortBtn = ({ onClick, isSorted }) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        border: 0,
        padding: 0,
        marginLeft: "auto",
        marginRight: "10px",
        height: "28px",
        alignSelf: "flex-end",
        justifyContent: "space-between"
      }}
    >
      <Icon.ChevronsUp size={12} color={isSorted === "asc" ? "blue" : "#879fb5"} />
      <Icon.ChevronsDown size={12} color={isSorted === "desc" ? "blue" : "#879fb5"} />
    </button>
  );
};

export default SortBtn;
