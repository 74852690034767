/* eslint-disable */
import React, { Component } from "react";
import axios from "../../utils/axios";
import {
  Tooltip,
  Modal,
  InputGroup,
  FormControl,
  Accordion,
  Spinner,
  Button,
  OverlayTrigger,
  Form,
  Alert,
  Row,
  Col,
  Tab,
  Popover,
} from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { config } from "../../constants";
import { schedule_options, propagation_options } from "../../constants";
import { toast } from "react-toastify";
import * as Icon from "react-feather";
import InputMask from "react-input-mask";
import moment from "moment-timezone";
import { isValidCron } from "cron-validator";
import { SpecDetailsForm } from "../connectors/specDetails.js";
import ConnectorCard from "../connectors/connectorCard.js";
import WithRouter from "../../HOC/RouterWrapper";
import StepIndicator from "./StepIndicator";

import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import DatajoltSpinner from "../DatajoltSpinner";

let GoogleLoginPage;
GoogleLoginPage = require("../google_oauth/oauth.js").default;

const user = JSON.parse(localStorage.getItem("user"));
let timestamps = [{ 5: "2 days ago" }];
const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

class TapCreatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 40,
      loading: false,
      resetting_data: false,
      get_dags_status: true,
      spec_details: [],
      saved_alias: false,
      global_stream_selected: false,
      global_replicate: false,
      global_selected_sync: "",
      tap_alias: "",
      datasources: [],
      schema_name: "",
      schema: "",
      running_taps: [],
      selected_streams: [],
      existing_sources: [],
      global_supportedSyncModes: [],
      custom_reports: [],
      activeTab: this.props.isEditingTap ? "data" : "setup",
      historical_dag_runs: [],
      replication_disabled: true,
      dag_pause_statuses: [],
      saving_settings: false,
      loading_connectors: true,
      show_schedule_button: false,
      saving_disabled: true,
      loading_mytaps: true,
      selected_tap: "",
      delete_name: "",
      selected_job: "",
      dag_runs: [],
      streams: [],
      modal_logs: false,
      modal_history: false,
      first_load: true,
      schema_saved: false,
      last_refresh: timestamps,
      mytaps: [],
      running_dags: [],
      delete_id: "",
      color: "primary",
      embedded_url: false,
      schema_overwritten: false,
      saving: false,
      new_schema: false,
      new_tap: "",
      deleting: false,
      dag_logs: [],
      searchConnectorsKeyword: "",
      modal_lose_create_tap_progress: false,
    };
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
    });
    document.body.style.overflow = "hidden";
    let edit_tap = new URLSearchParams(window.location.search).get("edit_tap");

    if (!this.props.isEditingTap) {
      this.listConnectors("data_sources");
    } else {
      this.setState({ selected_for_edit: edit_tap });
      await this.props.getMyTaps("data");
      console.log(this.props.mytasks);
      let item = this.props.mytasks.find((item) => item.id === edit_tap);
      if (item) {
        let { connectionID, logo, sourceID, subtype } = item;
        if (this.props.setSelectedTapData) {
          this.props.setSelectedTapData({
            logo: logo,
            selected_tap: subtype,
            connection_id: connectionID,
            source_ID: sourceID,
            selected_tap: subtype,
            logo: logo,
            selected_for_edit: edit_tap,
            selected_source_ID: sourceID,
          });
        }
        this.getCatalog(connectionID, sourceID, subtype, logo, edit_tap);
      } else {
        toast.error("Unable to find your tap to edit.", { autoClose: 5000 });
        this.props.router.navigate("/taps/mytaps");
        this.props.toggleCreateTapForm();
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
    if (this.props.setSelectedTapData) {
      this.props.setSelectedTapData({
        logo: null,
        selected_tap: "",
      });
    }
  }

  responseGoogle = (response) => {
    console.log(response);
  };

  options_enums = (enums) => {
    let output = [];
    enums.map((enu) => {
      output.push({ label: enu, value: enu });
    });
    return output;
  };

  options_oneofs = (name, oneofs) => {
    let oneof = [];
    oneofs.map((oneoff) => {
      //find upper key name where order = 0 inside property key and use that variable to identify what the initial dropdown value is
      let obj = oneoff["properties"];
      let result = Object.keys(obj).find((key) => obj[key]["order"] === 0);
      if (result === undefined) {
        oneof.push({ label: oneoff["title"], value: oneoff["title"] });
      } else {
        oneof.push({
          label: oneoff["title"],
          value: oneoff["properties"][result]["const"],
        });
      }
    });
    return oneof;
  };

  handleUpdate = (variable, value, stream_name) => {
    if (variable == "sync_modes") {
      console.log("Updating sync mode of" + variable + " to " + value);
      let streams_to_update = this.state.streams;
      //for global updates, check if value (needs to be reformated?) is in stream.supportedSyncModes
      streams_to_update[
        Object.keys(streams_to_update).find(
          (key) => streams_to_update[key]["name"] === stream_name
        )
      ]["sync_modes"] = value;
      streams_to_update[
        Object.keys(streams_to_update).find(
          (key) => streams_to_update[key]["name"] === stream_name
        )
      ]["sync_mode"] = value.split("|")[0].trim();
      streams_to_update[
        Object.keys(streams_to_update).find(
          (key) => streams_to_update[key]["name"] === stream_name
        )
      ]["destination_sync_mode"] = value.split("|")[1].trim();
      this.setState({ streams: streams_to_update });
    } else {
      let streams_to_update = this.state.streams;
      if (variable == "primary_key") {
        streams_to_update[
          Object.keys(streams_to_update).find(
            (key) => streams_to_update[key]["name"] === stream_name
          )
        ][variable] = value.map((item) => [item.value]);
      } else {
        streams_to_update[
          Object.keys(streams_to_update).find(
            (key) => streams_to_update[key]["name"] === stream_name
          )
        ][variable] = value;
      }
      this.setState({ streams: streams_to_update });
    }
  };

  testSource = () => {
    if (this.state.source_alias == "" || !this.state.source_alias) {
      toast.info("Please provide a source alias.", { autoClose: 5000 });
    } else if (
      /[`!@#$£%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.source_alias)
    ) {
      toast.info("Source alias cannot have special characters.", {
        autoClose: 5000,
      });
    } else {
      console.log("Testing source connection v2...");
      console.log(this.state.source_alias);
      var toastID = toast.loading(
        "Testing connection to  " +
          this.state.source_alias +
          " (" +
          this.state.selected_tap +
          ") data source..."
      );
      this.setState({ progress: 40, loading: true, logs: true });
      axios
        .post(config.url.TAPS_URL + "/api/airbyte/create_source", this.state)
        .then((response) => {
          toast.success(
            "Your " +
              this.state.selected_tap +
              " data source was created successfully. 🎉",
            { autoClose: 5000 }
          );
          this.setState({
            streams: [],
            selected_source_ID: response.data["sourceID"],
          });
          this.discoverSchema(response.data["sourceID"], "schedule");
        })
        .catch((err) => {
          try {
            if (err["error"]) {
              toast.error(err["error"], { autoClose: 15000 });
            }
          } catch (e) {
            toast.error(
              "Unable to connect to " + this.state.selected_tap + ".",
              { autoClose: 5000 }
            );
          }
        })
        .finally(() => {
          this.setState({ loading: false, progress: 100 });
          toast.dismiss(toastID);
        });
    }
  };

  getTapDetails = (
    fivetran_name,
    fivetran,
    tap,
    logo,
    tap_group,
    schema,
    status,
    sourcedefinitionID
  ) => {
    console.log("Fetching " + tap + " tap specs...");
    this.setState({
      catalogID: false,
      schema_name: false,
    });
    if (status == "Contact Us") {
      toast.info("Please contact Datajolt to set up this tap", {
        autoClose: 5000,
      });
    } else {
      if (!fivetran || fivetran != 1) {
        if (this.props.setSelectedTapData) {
          this.props.setSelectedTapData({
            logo: logo,
            selected_tap: tap,
          });
        }

        var toastID = toast.loading("Retrieving data source requirements...");
        const SelectedTap = {
          sourcedefinitionID: sourcedefinitionID,
          tap: tap,
          type: "source",
        };
        this.setState({
          logo: logo,
          selected_tap: tap,
          selected_source_definition_id: sourcedefinitionID,
          subelements: false,
          tap_group: tap_group,
          activeTab: "setup",
          progress: 40,
          schema_name: "",
          tap_alias: "",
          loading_spinner: true,
          logs: false,
          spec_details: false,
        });
        axios
          .post(config.url.TAPS_URL + "/api/airbyte/get_specs", SelectedTap)
          .then((response) => {
            this.setState({
              spec_details: JSON.parse(response.data["specs"]),
              documentationUrl: response.data["documentationUrl"],
            });
            //CHECK FOR BOOLEANS, IF FOUND ADD TO STATE (SO IT DOESNT CRASH IF IT IS NOT TOUCHED BY USER AS ONLY FORM ELEMENTS INTERACTED WITH WILL TRIGGER A STATE UPDATE)
            JSON.parse(response.data["specs"]).map((detail) =>
              detail.type == "boolean"
                ? this.setState({ [detail.name]: false })
                : ""
            );
          })
          .catch((err) => {
            toast.error(
              "There was an issue loading " + tap + " requirements.",
              { autoClose: 5000 }
            );
            this.setState({ spec_details: [] });
          })
          .finally(() => {
            toast.dismiss(toastID);
            this.setState({ progress: 100, loading_spinner: false });
          });
      } else {
        if (!this.state.showfivetran_modal) {
          this.setState({
            fivetran_name: fivetran_name,
            selected_connector_name: tap,
            embedded_url: false,
            showfivetran_modal: true,
            tap_alias: "",
            schema_overwritten: false,
            saved_alias: false,
          });
        } else {
          console.log("Using fivetran, fivetran name: " + fivetran_name);
          var toastID = toast.loading("Creating your data tap...");
          this.setState({
            fivetran_name: fivetran_name,
            embedded_url: false,
            showfivetran_modal: true,
            connector_status: "Your data tap is being created, please wait...",
          });
          if (schema == "") {
            var schema = "datajolt_" + fivetran_name;
          } else {
            this.setState({ schema_overwritten: true });
          }
          axios
            .post(config.url.TAPS_URL + "/api/fivetran/create_connector", {
              connector_name: this.state.selected_connector_name,
              service: fivetran_name,
              tap_alias: this.state.tap_alias,
              schema: schema,
            })
            .then((response) => {
              if (response.data["message"] == "provide schema") {
                this.setState({
                  new_schema: true,
                  saved_alias: true,
                  fivetran_selected: true,
                  selected_connector_name: tap,
                });
                toast.info("Please provide a different schema name.");
              } else {
                this.setState({
                  connector_status:
                    "Your data tap has been created and is ready to authenticate !",
                  fivetran_selected: true,
                  schema_saved: true,
                  embedded_url: response.data,
                  spec_details: ["fivetran"],
                });
              }
            })
            .catch((err) => {
              toast.error(
                "There was an issue loading " +
                  tap +
                  " requirements from Fivetran.",
                { autoClose: 5000 }
              );
              this.setState({ spec_details: [], showfivetran_modal: false });
            })
            .finally(() => {
              toast.dismiss(toastID);
              this.setState({ schema_overwritten: false, progress: 100 });
            });
        }
      }
    }
  };

  handleDeleteSuccess = () => {
    this.listConnectors("data_sources");
  };

  toggleFivetranModal = () => {
    this.setState({
      showfivetran_modal: !this.state.showfivetran_modal,
      schema_overwritten: false,
    });
  };

  scheduleTap = () => {
    if (this.state.tap_alias == "") {
      toast.info("Please specify a tap name", {
        autoClose: 5000,
      });
    } else if (this.state.schema_name == "") {
      toast.info("Please specify a destination schema name", {
        autoClose: 5000,
      });
    } else if (!this.state.propagation_value) {
      toast.info("Please specify schema propagation approach", {
        autoClose: 5000,
      });
    } else if (
      /[` !@#$£%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.schema_name)
    ) {
      toast.info("Schema name cannot have special characters", {
        autoClose: 5000,
      });
    } else if (
      this.props.mytasks.some(task => task.schema === this.state.schema_name)
    ) {
      toast.info("Schema already in use. Please choose a different name.", { autoClose: 5000 });
    }  
    else {
      var toastID = toast.loading(
        "Saving your " + this.state.tap_alias + " data tap..."
      );
      this.setState({
        scheduling: true,
        saving_disabled: true,
        progress: 40,
        replication_disabled: false,
        loading_spinner: true,
      });
      const SelectedTap = {
        catalogID: this.state.catalogID,
        schema_name: this.state.schema_name,
        tap_alias: this.state.tap_alias,
        sourceID: this.state.selected_source_ID,
        SyncCatalog: this.state.streams,
        propagation_value: this.state.propagation_value,
        selected_tap: this.state.selected_tap,
      };
      axios
        .post(config.url.TAPS_URL + "/api/airbyte/create_job", SelectedTap)
        .then((response) => {
          this.setState({
            tap_alias: "",
            schema_name: "",
            selected_tap: "",
            activeTab: "mytaps",
            saving_disabled: false,
            streams: [],
          });
          toast.success("Your tap is now ready to go ! 🎉", {
            autoClose: 5000,
          });
          this.props.getMyTaps();
          this.props.toggleCreateTapForm();
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "There was an internal issue scheduling your data tap. Please contact datajolt on slack.",
            { autoClose: 5000 }
          );
        })
        .finally(() => {
          toast.dismiss(toastID);
          this.setState({
            progress: 100,
            scheduling: false,
            loading_spinner: false,
          });
        });
    }
  };

  updateCatalog = () => {
    var toastID = toast.loading("Updating your data selection...");
    this.setState({ saving_disabled: true, progress: 40 });
    const SelectedTap = {
      tap_alias: this.state.selected_for_edit,
      connectionId: this.state.selected_connection_id,
      sourceID: this.state.selected_source_ID,
      tap_name: this.state.selected_tap,
      SyncCatalog: this.state.streams,
      catalogId: this.state.catalogId,
    };
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/update_catalog", SelectedTap)
      .then((response) => {
        this.setState({ activeTab: "mytaps" });
        this.props.router.navigate("/taps/mytaps");
        this.props.toggleCreateTapForm();
        toast.success("Your data selection has been updated.", {
          autoClose: 5000,
        });
        this.props.getMyTaps();
      })
      .catch((err) => {
        toast.error(
          "There was an internal issue updating your data selection. Please contact datajolt on slack.",
          { autoClose: 5000 }
        );
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100, saving_disabled: false });
        this.props.toggleCreateTapForm();
      });
  };

  updateCustomReport = (index, key, e, val) => {
    var current = [...this.state.custom_reports];
    current[index][key] = val || e.target.value;
    this.setState({ custom_reports: current });
  };

  goToSchedule = () => {
    this.setState({
      progress: 40,
      saving_settings: true,
      saving_disabled: true,
    });
    this.setState({
      progress: 100,
      saving_settings: false,
      saving_disabled: false,
      activeTab: "schedule",
    });
  };

  discoverSchema = (sourceID, type) => {
    var toastID = toast.loading(
      "Retrieving data structure from your " +
        this.state.selected_tap +
        " source..."
    );
    if (type != "schedule") {
      this.setState({ show_schedule_button: false });
    }
    this.setState({
      streams: [],
      selected_source_ID: sourceID,
      selection_type: type,
      saving_disabled: true,
      progress: 40,
      replication_disabled: false,
      loading_spinner: true,
      activeTab: "data",
    });
    const SelectedTap = {
      sourceID: sourceID,
      tap_name: this.state.selected_tap,
      destination: false,
    };
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/discover_schema", SelectedTap)
      .then((response) => {
        this.setState({
          saving_disabled: false,
          streams: response.data["streams"],
          catalogID: response.data["catalogID"],
          global_supportedSyncModes: response.data["global_supportedSyncModes"],
        });
      })
      .catch((err) => {
        toast.error(
          "There was an issue getting metadata from your data source..."
        );
        this.setState({ activeTab: "setup", spec_details: [] });
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100, loading_spinner: false });
      });
  };

  getCatalog = (connectionID, sourceID, tap, logo, dag_id) => {
    var toastID = toast.loading(
      "Retrieving data structure from your " + tap + " tap..."
    );
    this.setState({
      selected_for_edit: dag_id,
      get_dags_status: false,
      streams: [],
      selected_source_ID: sourceID,
      selected_tap: tap,
      logo: logo,
      selected_connection_id: connectionID,
      selected_tap: tap,
      show_schedule_button: false,
      selection_type: "update",
      saving_disabled: true,
      progress: 40,
      replication_disabled: false,
      loading_spinner: true,
      activeTab: "data",
    });
    const SelectedTap = { connectionID: connectionID, dag_name: dag_id };
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/get_catalog", SelectedTap)
      .then((response) => {
        this.setState({
          saving_disabled: false,
          global_supportedSyncModes: response.data["global_supportedSyncModes"],
          streams: response.data["streams"],
          catalogId: response.data["catalogId"],
        });
        console.log(response.data["streams"]);
      })
      .catch((err) => {
        toast.error("There was an issue listing your source metadata.");
        this.setState({ activeTab: "mytaps" });
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100, loading_spinner: false });
      });
  };

  resetData = (connectionId) => {
    console.log("Resetting data for conn id " + connectionId);
    this.setState({ resetting_data: true });
    var toastID = toast.loading("Queuing up a full reset of your tap...");
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/reset", {
        connectionId: connectionId,
      })
      .then((response) => {
        this.props.toggleCreateTapForm();
      })
      .catch((err) => {
        toast.error("There was an issue triggering your tap reset.");
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ resetting_data: false });
      });
  };

  new_schema = (newValue) => {
    this.setState({ schema: newValue.target.value });
  };

  new_tap = (newValue) => {
    this.setState({ new_tap: newValue.target.value });
  };

  listConnectors = (tap_type) => {
    this.setState({
      get_dags_status: false,
      global_replicate: false,
      selected_streams: [],
      global_stream_selected: false,
      global_selected_sync: "",
      datasources: [],
      custom_reports: [],
      show_schedule_button: true,
      color: "primary",
      progress: 40,
      loading_connectors: true,
      replication_disabled: true,
      loading_spinner: true,
      activeTab: "newtap",
    });
    axios
      .post(config.url.TAPS_URL + "/api/taps/list_available_connectors", {
        type: tap_type,
      })
      .then((response) => {
        this.setState({
          saving_settings: false,
          selected_tap: "",
          datasources: response.data.available_connectors,
          existing_sources: response.data.existing_sources,
        });
      })
      .catch((err) => {
        toast.error("There was an internal issue listing taps.");
      })
      .finally(() => {
        this.setState({ progress: 100, loading_connectors: false });
      });
  };

  discoverOrUpdate = (type) => {
    this.setState({
      global_replicate: false,
      selected_streams: [],
      global_selected_sync: "",
      global_stream_selected: false,
    });
    if (type == "schedule") {
      this.setState({ selected_for_edit: false });
      this.discoverSchema(this.state.selected_source_ID, "schedule");
    } else {
      this.getCatalog(
        this.state.selected_connection_id,
        this.state.selected_source_ID,
        this.state.selected_tap,
        this.state.logo,
        this.state.selected_for_edit
      );
    }
  };

  saveOrSchedule = (type) => {
    if (type == "schedule") {
      this.goToSchedule();
    } else {
      this.updateCatalog();
    }
  };

  applyGlobalSyncMode = () => {
    this.setState({
      global_replicate: false,
      selected_streams: [],
      global_selected_sync: "",
      global_stream_selected: false,
    });
    console.log(
      "Applying global sync mode: " + this.state.global_selected_sync
    );
    this.state.streams.forEach((stream) => {
      if (this.state.selected_streams.includes(stream.name)) {
        let streams_to_update = this.state.streams;
        if (
          this.state.global_selected_sync !== "" &&
          Array.isArray(
            streams_to_update[
              Object.keys(streams_to_update).find(
                (key) => streams_to_update[key]["name"] === stream.name
              )
            ]["supportedSyncModes"]
          ) &&
          streams_to_update[
            Object.keys(streams_to_update).find(
              (key) => streams_to_update[key]["name"] === stream.name
            )
          ]["supportedSyncModes"]
            .map((item) => item.value)
            .includes(this.state.global_selected_sync)
        ) {
          this.handleUpdate(
            "sync_modes",
            this.state.global_selected_sync,
            stream.name
          );
        }
        this.handleUpdate(
          "replicate",
          this.state.global_replicate,
          stream.name
        );
      }
    });
  };

  handleStateUpdate = (newState) => {
    this.setState(newState);
  };

  handleCheck = (box_ticked) => {
    if (box_ticked == "global") {
      if (!this.state.global_stream_selected) {
        this.setState({
          selected_streams: this.state.streams.map((stream) => {
            return stream.name;
          }),
        });
      } else {
        this.setState({ selected_streams: [] });
      }
      this.setState({
        global_stream_selected: !this.state.global_stream_selected,
      });
    } else {
      if (!this.state.selected_streams.includes(box_ticked)) {
        console.log("Adding " + box_ticked + " to selected streams");
        this.setState({
          selected_streams: [...this.state.selected_streams, box_ticked],
        });
      } else {
        this.setState({
          selected_streams: this.state.selected_streams.filter(
            (item) => item !== box_ticked
          ),
        });
      }
    }
  };

  updateSearchConnectorsKeyword = (e) => {
    this.setState({ searchConnectorsKeyword: e.target.value });
  };
  handleClearSearchConnectorsKeyword = () => {
    this.setState({ searchConnectorsKeyword: "" });
  };

  toggleLoseCreateTapFormProgressModal = () =>
    this.setState({
      modal_lose_create_tap_progress:
        !this.state.modal_lose_create_tap_progress,
    });

  handleCloseCreateForm = () => {
    if (this.state.activeTab === "newtap") {
      this.props.toggleCreateTapForm();
    } else {
      this.toggleLoseCreateTapFormProgressModal();
    }
  };

  render() {
    const { navigate } = this.props.router;
    return (
      <div id="push-tap-creator-form">
        <div className="container-fluid py-3">
          <div style={{ display: "flex" }} className="push-tap-collector-form">
            <StepIndicator
              steps={
                this.props.isEditingTap
                  ? ["Source", "Data"]
                  : ["Source", "Data", "Settings"]
              }
              activeStep={
                this.state.activeTab === "setup"
                  ? 1
                  : this.state.activeTab === "data"
                  ? 2
                  : this.state.activeTab === "schedule"
                  ? 3
                  : 0
              }
            />
            <div
              style={{
                width: "80%",
                position: "relative",
              }}
            >
              <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
                <Tab.Content>
                  <CSSTransition
                    in={this.state.activeTab === "newtap"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="newtap" className="push-tap-slide">
                      <div className="card-body">
                        <div className="card form-step-wrapper">
                          <h4
                            style={{
                              margin: "20px 20px",
                              marginBottom: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <b>Source Connectors </b>
                            <TextField
                              id="search-connectors-names"
                              autoFocus={false}
                              sx={{
                                bgcolor: "white",
                                "& > div": {
                                  padding: 0,
                                },
                                "& input": {
                                  padding: 0.3,
                                  pl: 0.8,
                                  color: "#6c757d",
                                },
                                "& fieldset": { top: 0 },
                                "& fieldset legend": { display: "none" },
                                "& .MuiInputBase-sizeSmall": {
                                  padding: "0 !important",
                                },
                              }}
                              label={null}
                              placeholder="search"
                              variant="outlined"
                              value={this.state.searchConnectorsKeyword}
                              onChange={this.updateSearchConnectorsKeyword}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{ cursor: "pointer" }}
                                    id="connectors-search"
                                  >
                                    <Icon.Search size={14} />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment
                                    onClick={
                                      this.handleClearSearchConnectorsKeyword
                                    }
                                    position="end"
                                    sx={{
                                      cursor: "pointer",
                                      mr: "8px",
                                    }}
                                    id="clear-connectors-search"
                                  >
                                    <Icon.X
                                      color="#6c757d"
                                      size={14}
                                      style={{
                                        visibility: this.state
                                          .searchConnectorsKeyword
                                          ? "visible"
                                          : "hidden",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <button
                              className="close-push-tap-form"
                              onClick={this.handleCloseCreateForm}
                            >
                              <Icon.X
                                size={18}
                                className="close-push-tap-form-icon"
                              />
                              Close
                            </button>
                          </h4>

                          <b
                            style={{
                              display: "block",
                              marginLeft: "20px",
                              width: "100%",
                            }}
                          >
                            <hr />
                            Your Existing Sources{" "}
                            {this.state.existing_sources.length > 0
                              ? "(" + this.state.existing_sources.length + ")"
                              : ""}
                          </b>
                          <div className="connectors-container">
                            {!this.state.loading_connectors ? (
                              this.state.existing_sources.length > 0 &&
                              this.state.existing_sources.map(
                                (datasource, index) => (
                                  <div
                                    key={datasource.label}
                                    onClick={() => {
                                      this.setState({
                                        selected_tap: datasource.source_type,
                                      });
                                      this.discoverSchema(
                                        datasource.external_source_id,
                                        "schedule"
                                      );
                                    }}
                                    className="w-dyn-item"
                                    style={{
                                      display: "inline-block",
                                      marginleft: "5px",
                                    }}
                                  >
                                    <div className="w-embed">
                                      <input
                                        type="hidden"
                                        className="jetboost-list-item"
                                      />
                                    </div>
                                    <ConnectorCard
                                      datasource={datasource}
                                      onDeleteSuccess={this.handleDeleteSuccess}
                                    />
                                  </div>
                                )
                              )
                            ) : (
                              <center className="connector-spinner">
                                <DatajoltSpinner />
                              </center>
                            )}
                          </div>
                          <b
                            style={{
                              display: "block",
                              marginLeft: "20px",
                              width: "100%",
                            }}
                          >
                            Available Source Connectors{" "}
                            {this.state.datasources.length > 0
                              ? "(" + this.state.datasources.length + ")"
                              : ""}
                          </b>
                          <div className="connectors-container">
                            {!this.state.loading_connectors
                              ? this.state.datasources.length > 0 &&
                                this.state.datasources
                                  .filter((target) => {
                                    if (this.state.searchConnectorsKeyword) {
                                      const sourceLabel =
                                        target.label.toLowerCase();

                                      return sourceLabel.includes(
                                        this.state.searchConnectorsKeyword.toLocaleLowerCase()
                                      );
                                    } else {
                                      return true;
                                    }
                                  })
                                  .map((datasource, index) => (
                                    <div
                                      key={datasource.label}
                                      onClick={() =>
                                        this.getTapDetails(
                                          datasource.fivetran_name,
                                          datasource.fivetran,
                                          datasource.label,
                                          datasource.logo,
                                          datasource.tap_group,
                                          "",
                                          datasource.status,
                                          datasource.sourcedefinitionID
                                        )
                                      }
                                      className="w-dyn-item"
                                      style={{
                                        display: "inline-block",
                                        marginleft: "5px",
                                      }}
                                    >
                                      <div className="w-embed">
                                        <input
                                          type="hidden"
                                          className="jetboost-list-item"
                                        />
                                      </div>
                                      <ConnectorCard datasource={datasource} />
                                    </div>
                                  ))
                              : ""}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "setup"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="setup" className="push-tap-slide">
                      <div className="card-body push-tap-form">
                        <div className="card form-step-wrapper">
                          <h4
                            style={{
                              margin: "20px 20px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <b>
                              Source Details
                              <a
                                href={this.state.documentationUrl}
                                target="_blank"
                                id="my-link"
                              >
                                <DetailsIcon
                                  style={{
                                    position: "relative",
                                    marginLeft: "10px",
                                    top: "-1px",
                                  }}
                                />
                              </a>
                            </b>
                            <button
                              className="close-push-tap-form"
                              onClick={this.handleCloseCreateForm}
                            >
                              <Icon.X
                                size={18}
                                className="close-push-tap-form-icon"
                              />
                              Close
                            </button>
                          </h4>

                          {this.state.spec_details.length > 0 && (
                            <>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  <b style={{ marginLeft: "20px" }}>
                                    Source Alias
                                  </b>
                                </Form.Label>
                                <Form.Control
                                  style={{ width: "96%", marginLeft: "3%" }}
                                  type="text"
                                  onChange={(e) =>
                                    this.setState({
                                      source_alias: e.target.value,
                                    })
                                  }
                                  placeholder="Your source alias (you will be able to reuse your source to set up a new tap)"
                                />
                              </Form.Group>
                              {this.state.selected_tap.includes(
                                "Google Analytics"
                              ) ||
                              this.state.selected_tap.includes("Google Ads") ? (
                                <Form.Group
                                  className="mb-3"
                                  style={{ marginLeft: "10px" }}
                                >
                                  {/* <GoogleLoginPage
                                    onStateUpdate={this.handleStateUpdate}
                                    tap={this.state.selected_tap}
                                  /> */}
                                </Form.Group>
                              ) : (
                                ""
                              )}
                              <SpecDetailsForm
                                spec_details={this.state.spec_details}
                                onStateUpdate={this.handleStateUpdate}
                              />

                              {this.state.selected_tap.includes(
                                "Google Analytics"
                              ) ? (
                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    <b style={{ marginLeft: "20px" }}>
                                      Custom Reports (
                                      {this.state.custom_reports.length})
                                    </b>
                                    &nbsp;&nbsp;&nbsp;
                                    <Button
                                      onClick={() =>
                                        this.setState({
                                          custom_reports: [
                                            ...this.state.custom_reports,
                                            {
                                              name: "",
                                              metrics: "",
                                              dimensions: "",
                                            },
                                          ],
                                        })
                                      }
                                      variant="generaltabs"
                                      size="sm-pad"
                                      className="active"
                                    >
                                      +
                                    </Button>
                                  </Form.Label>
                                </Form.Group>
                              ) : (
                                ""
                              )}
                              {this.state.selected_tap.includes(
                                "Google Analytics"
                              )
                                ? this.state.custom_reports.map(
                                    (custom_report, index) => (
                                      <>
                                        <Accordion
                                          defaultActiveKey="0"
                                          style={{ marginLeft: "20px" }}
                                        >
                                          <Accordion.Item
                                            key={"report_" + index}
                                            eventKey="0"
                                            className="bg-white"
                                          >
                                            <Accordion.Header>
                                              {custom_report["name"]}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <b>Report Name</b>
                                              <Form.Control
                                                style={{ width: "97%" }}
                                                key={"report_" + index}
                                                type={"text"}
                                                defaultValue={
                                                  custom_report["name"]
                                                }
                                                placeholder="LTV Report"
                                                onChange={(e) =>
                                                  this.updateCustomReport(
                                                    index,
                                                    "name",
                                                    e
                                                  )
                                                }
                                              />

                                              <b>Dimensions</b>
                                              <Form.Control
                                                style={{ width: "97%" }}
                                                key={"dimensions_" + index}
                                                type={"text"}
                                                defaultValue={
                                                  custom_report["dimensions"]
                                                }
                                                placeholder={
                                                  this.state.selected_tap ==
                                                  "Google Analytics"
                                                    ? "ga:date, ga:country"
                                                    : "date, country"
                                                }
                                                onChange={(e) =>
                                                  this.updateCustomReport(
                                                    index,
                                                    "dimensions",
                                                    e
                                                  )
                                                }
                                              />
                                              <b>Metrics</b>
                                              <Form.Control
                                                style={{ width: "97%" }}
                                                key={"metrics_" + index}
                                                type={"text"}
                                                defaultValue={
                                                  custom_report["metrics"]
                                                }
                                                placeholder={
                                                  this.state.selected_tap ==
                                                  "Google Analytics"
                                                    ? "ga:activeUsers"
                                                    : "activeUsers"
                                                }
                                                onChange={(e) =>
                                                  this.updateCustomReport(
                                                    index,
                                                    "metrics",
                                                    e
                                                  )
                                                }
                                              />
                                              <br />
                                              <right>
                                                <Button
                                                  onClick={() =>
                                                    this.setState({
                                                      custom_reports:
                                                        this.state.custom_reports.filter(
                                                          (value, index_) =>
                                                            index_ != index
                                                        ),
                                                    })
                                                  }
                                                  variant="danger"
                                                  size="sm-pad"
                                                  className="active"
                                                >
                                                  Delete Report
                                                </Button>
                                              </right>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </>
                                    )
                                  )
                                : ""}

                              <Button
                                style={{
                                  margin: "20px",
                                  alignSelf: "flex-start",
                                }}
                                type="submit"
                                variant={this.state.color}
                                size="lg"
                                disabled={
                                  this.state.loading
                                    ? true
                                    : this.state.color == "success"
                                    ? true
                                    : false
                                }
                                onClick={this.testSource}
                              >
                                <Icon.Play
                                  color="white"
                                  size={15}
                                  style={{
                                    position: "relative",
                                    top: "-2px",
                                    marginRight: "5px",
                                  }}
                                />
                                {this.state.color == "primary"
                                  ? this.state.loading
                                    ? "Connecting..."
                                    : "Test & Create source"
                                  : this.state.color == "success"
                                  ? "Success"
                                  : ""}
                                &nbsp;
                                {this.state.color == "success" ? (
                                  <Icon.Check />
                                ) : (
                                  ""
                                )}
                                {this.state.loading ? (
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                ) : (
                                  ""
                                )}
                              </Button>
                              {this.state.color == "success" ? (
                                <Button
                                  style={{
                                    marginLeft: "20px",
                                    marginBottom: "20px",
                                  }}
                                  type="submit"
                                  variant="primary"
                                  size="lg"
                                  disabled={this.state.saving}
                                  onClick={() =>
                                    this.saveTap(
                                      this.state.selected_tap,
                                      this.state.selected_tap,
                                      0
                                    )
                                  }
                                >
                                  {this.state.saving
                                    ? "Saving..."
                                    : "Save Credentials"}
                                  {this.state.saving ? (
                                    <Spinner
                                      as="span"
                                      variant="light"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      animation="border"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          {this.state.loading_spinner ? (
                            <div className="row">
                              <br></br>
                              <center>
                                <DatajoltSpinner />
                              </center>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "data"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="data" className="push-tap-slide">
                      <div className="card-body push-tap-form-table">
                        <div className="card form-step-wrapper">
                          <h4
                            style={{
                              margin: "20px 20px",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <b
                              style={{
                                marginRight: "auto",
                              }}
                            >
                              Data Selection{" "}
                              {!this.state.show_schedule_button
                                ? ": " + this.state.selected_for_edit
                                : ""}
                            </b>

                            <Button
                              style={{
                                padding: "0",
                                right:
                                  this.state.selection_type == "schedule"
                                    ? "220px"
                                    : "140px",
                                marginTop: "-5px",
                              }}
                              type="submit"
                              variant="outline"
                              size="lg"
                              disabled={this.state.saving_disabled}
                              onClick={() =>
                                this.discoverOrUpdate(this.state.selection_type)
                              }
                            >
                              <RefreshIcon />
                              &nbsp;&nbsp;
                              {this.state.loading ? "Refreshing..." : ""}
                              {this.state.loading && (
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              )}
                            </Button>
                            {this.state.selected_tap.includes(
                              "Google Analytics"
                            ) &&
                              this.state.selection_type == "update" && (
                                <Button
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  type="submit"
                                  variant="secondary"
                                  size="lg"
                                  disabled={this.state.saving_disabled}
                                  onClick={() =>
                                    navigate(
                                      `/taps/mytaps?edit_tap=${this.state.selected_for_edit}&custom_reports=true`
                                    )
                                  }
                                >
                                  <Icon.Settings />
                                  &nbsp;&nbsp;Edit
                                </Button>
                              )}

                            <Button
                              style={{
                                right: "20px",
                              }}
                              type="submit"
                              variant="primary"
                              size="lg"
                              disabled={this.state.saving_disabled}
                              onClick={() =>
                                this.saveOrSchedule(this.state.selection_type)
                              }
                            >
                              <Icon.Save />
                              &nbsp;&nbsp;
                              {this.state.saving_settings
                                ? "Saving..."
                                : this.state.selection_type == "schedule"
                                ? "Validate data"
                                : "Validate data"}
                              {this.state.saving_settings ? (
                                <Spinner
                                  as="span"
                                  variant="light"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  animation="border"
                                />
                              ) : (
                                ""
                              )}
                            </Button>
                            <button
                              className="close-push-tap-form"
                              onClick={this.handleCloseCreateForm}
                            >
                              <Icon.X
                                size={18}
                                className="close-push-tap-form-icon"
                              />
                              Close
                            </button>
                          </h4>

                          <table className="mt-2 table-lists table-daged table-bordered table-triped table-hover my-20px">
                            <thead>
                              <tr>
                                <th width="60px">
                                  {this.state.loading_spinner ? (
                                    ""
                                  ) : (
                                    <Form.Check
                                      checked={
                                        this.state.global_stream_selected
                                      }
                                      onChange={() =>
                                        this.handleCheck("global")
                                      }
                                      type="checkbox"
                                      label=""
                                      id="gobal_check"
                                    />
                                  )}
                                </th>
                                <th>Namespace</th>
                                <th>Stream</th>
                                <th>Replication</th>
                                <th width="25%">Source | Destination</th>
                                <th>Cursor field</th>
                                <th>Primary key</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.global_stream_selected ? (
                                <tr key="global_checks">
                                  <td
                                    style={{
                                      width: "60px",
                                    }}
                                  >
                                    <Button
                                      type="submit"
                                      variant="success"
                                      size="sm"
                                      onClick={() => this.applyGlobalSyncMode()}
                                    >
                                      Apply
                                    </Button>
                                  </td>
                                  <td
                                    style={{
                                      width: "200px",
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      width: "300px",
                                    }}
                                  ></td>
                                  <td width="100px">
                                    <center>
                                      <Form.Check
                                        type="switch"
                                        className="toggle-switch-inner"
                                        checked={this.state.global_replicate}
                                        id="exampleCustomSwitch"
                                        name="customSwitch"
                                        onChange={() =>
                                          this.setState({
                                            global_replicate:
                                              !this.state.global_replicate,
                                          })
                                        }
                                      />
                                    </center>
                                  </td>
                                  <td
                                    style={{
                                      width: "300px",
                                    }}
                                  >
                                    <Select
                                      size="sm"
                                      className="react-select-container-not-offset"
                                      classNamePrefix="react-select"
                                      defaultValue={{
                                        label: "...",
                                        value: "...",
                                      }}
                                      options={
                                        this.state.global_supportedSyncModes
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          global_selected_sync: e.value,
                                        })
                                      }
                                    />
                                  </td>
                                  <td
                                    style={{
                                      width: "200px",
                                      color: "#fff",
                                    }}
                                  >
                                    {this.state.global_selected_sync ==
                                      "incremental | append" ||
                                    this.state.global_selected_sync ==
                                      "incremental | append_dedup"
                                      ? "<source defined>"
                                      : ""}
                                  </td>
                                  <td
                                    style={{
                                      width: "200px",
                                      color: "#fff",
                                    }}
                                  >
                                    {this.state.global_selected_sync ==
                                    "incremental | append_dedup"
                                      ? "<source defined>"
                                      : ""}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {this.state.streams.length > 0
                                ? this.state.streams.map((stream) =>
                                    !stream.name.startsWith("_airbyte") ? (
                                      <tr key={stream.name}>
                                        <td width="60px">
                                          <Form.Check
                                            onChange={() =>
                                              this.handleCheck(stream.name)
                                            }
                                            checked={
                                              this.state.selected_streams.includes(
                                                stream.name
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            label=""
                                            id={stream.name}
                                          />
                                        </td>
                                        <td width="200px">
                                          {stream.namespace ? (
                                            stream.namespace.toLowerCase()
                                          ) : (
                                            <i>No namespace</i>
                                          )}
                                        </td>
                                        <td width="300px">
                                          {stream.name.toLowerCase()}
                                        </td>
                                        <td width="100px">
                                          <center>
                                            <Form.Check
                                              type="switch"
                                              className="toggle-switch-inner"
                                              id="exampleCustomSwitch"
                                              checked={
                                                stream.replicate == 0
                                                  ? false
                                                  : true
                                              }
                                              name="customSwitch"
                                              onChange={() =>
                                                this.handleUpdate(
                                                  "replicate",
                                                  !stream.replicate,
                                                  stream.name
                                                )
                                              }
                                            />
                                          </center>
                                        </td>
                                        <td width="300px">
                                          <Select
                                            size="sm"
                                            className="react-select-container-not-offset"
                                            classNamePrefix="react-select"
                                            value={{
                                              label:
                                                stream.sync_modes
                                                  .split("|")[0]
                                                  .replace(/_/g, " ")
                                                  .replace(/^\w/, (c) =>
                                                    c.toUpperCase()
                                                  ) +
                                                " | " +
                                                stream.sync_modes
                                                  .split("|")[1]
                                                  .trimStart()
                                                  .replace(/_/g, " ")
                                                  .replace(/^\w/, (c) =>
                                                    c.toUpperCase()
                                                  ),
                                              value: stream.sync_modes,
                                            }}
                                            options={stream.supportedSyncModes}
                                            onChange={(e) =>
                                              this.handleUpdate(
                                                "sync_modes",
                                                e.value,
                                                stream.name
                                              )
                                            }
                                          />
                                        </td>
                                        <td width="200px">
                                          {stream.sync_mode == "incremental" ? (
                                            stream.defaultCursorField.length ==
                                            1 ? (
                                              stream.defaultCursorField
                                            ) : (
                                              <Select
                                                size="sm"
                                                className="react-select-container-not-offset"
                                                classNamePrefix="react-select"
                                                defaultValue={{
                                                  label: stream.cursor_field,
                                                  value: stream.cursor_field,
                                                }}
                                                options={this.options_enums(
                                                  stream.columns
                                                )}
                                                onChange={(e) =>
                                                  this.handleUpdate(
                                                    "cursor_field",
                                                    e.value,
                                                    stream.name
                                                  )
                                                }
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td width="200px">
                                          {stream.sync_mode == "incremental" &&
                                          stream.destination_sync_mode ==
                                            "append_dedup" ? (
                                            stream.sourceDefinedPrimaryKey ? (
                                              stream.sourceDefinedPrimaryKey
                                            ) : (
                                              <Select
                                                isMulti
                                                size="sm"
                                                classNamePrefix="react-select"
                                                value={
                                                  stream.primary_key
                                                    ? stream.primary_key.map(
                                                        (dep) => ({
                                                          value: dep[0],
                                                          label: dep[0],
                                                        })
                                                      )
                                                    : undefined
                                                }
                                                options={this.options_enums(
                                                  stream.columns
                                                )}
                                                onChange={(e) =>
                                                  this.handleUpdate(
                                                    "primary_key",
                                                    e,
                                                    stream.name
                                                  )
                                                }
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )
                                  )
                                : ""}
                            </tbody>
                          </table>
                          <br />
                          <center>
                            {this.state.loading_spinner ? (
                              <>
                                <DatajoltSpinner />
                              </>
                            ) : (
                              <img
                                style={{
                                  paddingBottom: "1%",
                                  marginTop: "-2%",
                                  maxWidth: "5%",
                                }}
                                src={this.state.logo}
                                loading="lazy"
                                alt=""
                                className="image-contain"
                              />
                            )}
                            {this.state.selection_type == "update" &&
                            !this.state.loading_spinner ? (
                              <>
                                <br />
                                <Button
                                  style={{
                                    right: "20px",
                                  }}
                                  type="submit"
                                  variant="danger"
                                  size="lg"
                                  disabled={this.state.resetting_data}
                                  onClick={() =>
                                    this.resetData(
                                      this.state.selected_connection_id
                                    )
                                  }
                                >
                                  <Icon.AlertCircle size={16} /> Reset Data
                                </Button>
                              </>
                            ) : (
                              ""
                            )}
                          </center>
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.activeTab === "schedule"}
                    classNames="push-tap-slide"
                    timeout={300}
                    unmountOnExit
                  >
                    <Tab.Pane eventKey="schedule" className="push-tap-slide">
                      <div className="card-body push-tap-form">
                        <div className="card form-step-wrapper">
                          <h4
                            style={{
                              margin: "20px 20px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <b>
                              Replication Settings
                              <OverlayTrigger
                                trigger="click"
                                placement="right"
                                overlay={
                                  <Popover id={`popover-positioned-left`}>
                                    <Popover.Header as="h3">
                                      {"What are Scheduled Refreshes?"}
                                    </Popover.Header>
                                    <Popover.Body>
                                      This page allows you to set a refresh
                                      cadence so Datajolt knows when to pull
                                      data from your source systems to your DWH
                                      <br />
                                      <br />
                                      This cadence should be aligned with your
                                      business requirements and data
                                      consumption.
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <DetailsIcon
                                  style={{
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "-1px",
                                  }}
                                />
                              </OverlayTrigger>{" "}
                            </b>
                            <button
                              className="close-push-tap-form"
                              onClick={this.handleCloseCreateForm}
                            >
                              <Icon.X
                                size={18}
                                className="close-push-tap-form-icon"
                              />
                              Close
                            </button>
                          </h4>

                          <Form.Label>
                            <b style={{ marginLeft: "20px" }}>Your tap name</b>
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              value={this.state.tap_alias}
                              placeholder={
                                "Name your tap to identify it easily in Datajolt"
                              }
                              onChange={(e) =>
                                this.setState({
                                  tap_alias: e.target.value,
                                })
                              }
                            />
                          </Form.Group>

                          <Form.Label>
                            <b style={{ marginLeft: "20px" }}>
                              Your destination schema name
                            </b>
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              value={this.state.schema_name}
                              placeholder={"Schema name in your destination"}
                              onChange={(e) =>
                                this.setState({
                                  schema_name: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Label>
                            <b style={{ marginLeft: "20px" }}>
                              How should we handle changes in your source
                              system?
                            </b>
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Select
                              className="react-select-notrain"
                              classNamePrefix="react-select"
                              onChange={(e) =>
                                this.setState({
                                  propagation_label: e.label,
                                  propagation_value: e.value,
                                })
                              }
                              options={propagation_options}
                              value={
                                this.state.propagation_label
                                  ? {
                                      value: this.state.propagation_value,
                                      label: this.state.propagation_label,
                                    }
                                  : null
                              }
                            />
                          </Form.Group>
                          <Button
                            style={{
                              marginLeft: "20px",
                              alignSelf: "flex-start",
                            }}
                            type="submit"
                            variant="primary"
                            size="lg"
                            disabled={this.state.scheduling}
                            onClick={() => this.scheduleTap()}
                          >
                            <Icon.Calendar
                              color="white"
                              size={15}
                              style={{
                                position: "relative",
                                top: "-2px",
                                marginRight: "5px",
                              }}
                            />

                            {this.state.scheduling
                              ? "Creating... "
                              : "Create your data tap"}
                            {this.state.scheduling ? (
                              <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />
                            ) : (
                              ""
                            )}
                          </Button>

                          {this.state.logs_schedule && (
                            <Alert
                              variant={this.state.logs_schedule_variant}
                              className="alert-outline-coloured"
                              key="1"
                              onClose={() =>
                                this.setState({ logs_schedule: false })
                              }
                              dismissible
                            >
                              <div className="alert-icon">
                                <Icon.X size={16} />
                              </div>
                              <div className="alert-message">
                                <strong>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: this.state.logs_schedule,
                                    }}
                                  />
                                </strong>
                              </div>
                            </Alert>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </CSSTransition>
                </Tab.Content>
              </Tab.Container>

              <Modal
                show={this.state.modal_delete_connector}
                onHide={() => this.toggleDeleteModal()}
                centered
              >
                <Modal.Header closeButton>
                  <b>Warning!</b>
                  <img
                    src={this.state.delete_logo}
                    width="5%"
                    style={{
                      marginTop: "-3px",
                      position: "absolute",
                      right: "40px",
                    }}
                  ></img>
                </Modal.Header>
                <Modal.Body className="text-center m-2">
                  <center>
                    You are about to delete your{" "}
                    <b>
                      {this.state.delete_name.substring(
                        this.state.delete_name.indexOf("_") + 1
                      )}{" "}
                      (
                      {this.state.delete_id.substring(
                        this.state.delete_id.indexOf("_") + 1
                      )}
                      )
                    </b>{" "}
                    data tap.
                    <br />
                    <br />
                    <b>Are you sure ?</b>
                  </center>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.toggleDeleteModal()}
                  >
                    <Icon.X size={16} />
                    &nbsp; No
                  </Button>{" "}
                  <Button
                    variant="danger"
                    disabled={this.state.deleting}
                    onClick={() =>
                      this.deleteDAG(
                        this.state.delete_id,
                        this.state.selected_source_ID
                      )
                    }
                  >
                    <Icon.Trash2 size={16} />
                    &nbsp;
                    {this.state.deleting ? (
                      <>
                        Deleting...&nbsp;&nbsp;
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      </>
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={this.state.showfivetran_modal}
                onHide={() => this.toggleFivetranModal()}
                centered
              >
                <Modal.Header closeButton>
                  <b>
                    <h4 style={{ marginTop: "7px" }}>
                      Authenticate with Fivetran
                    </h4>
                  </b>
                  <img
                    src="https://assets-global.website-files.com/6130fa1501794ed4d11867ba/613e9ef6d25b70060cb305ca_fivetran-logo.fb5c1b9c.svg"
                    width="12%"
                    style={{
                      marginTop: "-3px",
                      position: "absolute",
                      right: "40px",
                    }}
                  ></img>
                </Modal.Header>
                <Modal.Body className="text-center m-2">
                  <h5>
                    We partner with Fivetran for our data ingestion layer.{" "}
                    <br />
                  </h5>
                  <center>
                    Once your data tap is created, upon clicking the
                    Authenticate button below, you will be redirected to
                    Fivetran to authenticate, then back to Datajolt and your
                    data tap will be ready for use.
                    <br />
                    <br />
                  </center>
                  <br />
                  <b>Name your tap to identify it easily in Datajolt</b>
                  <InputGroup className="mb-1">
                    <FormControl
                      onChange={(e) =>
                        this.setState({
                          tap_alias: e.target.value,
                        })
                      }
                      placeholder="Your tap alias"
                      disabled={this.state.saved_alias}
                      aria-describedby="basic-addon2"
                    />
                    <Button
                      variant="primary"
                      id="button-addon2"
                      disabled={
                        this.state.tap_alias == "" || this.state.saved_alias
                      }
                      onClick={() =>
                        this.getTapDetails(
                          this.state.fivetran_name,
                          1,
                          this.state.selected_connector_name,
                          "",
                          "",
                          "",
                          "",
                          ""
                        )
                      }
                    >
                      Save{this.state.saved_alias ? "d" : ""}
                      {this.state.saved_alias && <Icon.Check size={16} />}
                    </Button>
                  </InputGroup>
                  <br />
                  {this.state.new_schema ? (
                    <>
                      <b>
                        An integration is already replicating data into the
                        default schema name (datajolt_{this.state.fivetran_name}
                        )
                      </b>
                      <InputGroup className="mb-1">
                        <FormControl
                          onChange={this.new_schema}
                          placeholder="Provide a different schema name and click Save"
                          aria-describedby="basic-addon2"
                          disabled={this.state.schema_saved}
                        />
                        <Button
                          variant="primary"
                          id="button-addon2"
                          disabled={
                            this.state.schema == "" || this.state.schema_saved
                          }
                          onClick={() =>
                            this.getTapDetails(
                              this.state.fivetran_name,
                              1,
                              this.state.selected_connector_name,
                              "",
                              "",
                              this.state.schema,
                              "",
                              ""
                            )
                          }
                        >
                          Save{this.state.schema_saved ? "d" : ""}
                          {this.state.schema_saved && <Icon.Check size={16} />}
                        </Button>
                      </InputGroup>
                    </>
                  ) : (
                    ""
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <center>
                    <a
                      href={this.state.embedded_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        variant="success"
                        disabled={!this.state.embedded_url}
                      >
                        {this.state.schema_overwritten ? (
                          <Spinner
                            as="span"
                            style={{
                              marginTop: "2px",
                              marginLeft: "3px",
                              marginRight: "6px",
                            }}
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        ) : (
                          <>
                            <Icon.LogIn size={16} />
                            &nbsp;&nbsp;Authenticate
                          </>
                        )}
                      </Button>
                    </a>
                    &nbsp;&nbsp;&nbsp;This will open in a new window
                  </center>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.modal_lose_create_tap_progress}
          onHide={() =>
            this.setState({ modal_lose_create_tap_progress: false })
          }
          centered
        >
          <Modal.Header closeButton>
            <b>Warning!</b>
          </Modal.Header>
          <Modal.Body className="text-center m-2">
            <center>
              You are about to close your tap creator form without saving your
              progress, all the progress will be lost.
              <br />
              <b>Are you sure ?</b>
            </center>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <Button
              variant="danger"
              onClick={() => {
                this.toggleLoseCreateTapFormProgressModal();
                this.props.toggleCreateTapForm();
              }}
            >
              <Icon.Delete size={16} />
              &nbsp; Close
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                this.setState({ modal_lose_create_tap_progress: false })
              }
            >
              <Icon.X size={16} />
              &nbsp; Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default WithRouter(TapCreatorForm);
