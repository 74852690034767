import React from "react";
import { Spinner, Button, Modal } from "react-bootstrap";
import * as Icon from "react-feather";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "../../constants";

const DeleteBranchModal = ({ deleteBranch, setDeleteBranch,fetchBranches }) => {
  const onDeleteBranch = () => {
    // Show loading toast
    var toastId = toast.loading(`Deleting branch ${deleteBranch.branch}...`, { autoClose: false });

    // Before deleting the branch
    setDeleteBranch({ ...deleteBranch, isDeleting: true });

    console.log(`Deleting ${deleteBranch.branch}`);

    // Axios call to delete the branch
    axios.post(config.url.GIT_URL + '/api/git/delete_branch', { branch: deleteBranch.branch })
      .then(response => {
        // Show success toast and hide the modal
        fetchBranches();
        toast.dismiss(); // Remove the loading toast
        toast.success(`Branch ${deleteBranch.branch} deleted successfully!`);
        setDeleteBranch({ isVisible: false, isDeleting: false, branch: "" });
      })
      .catch(error => {
        // Show error toast and keep the modal open
        toast.dismiss(); // Remove the loading toast
        toast.error(`Error deleting branch ${deleteBranch.branch}: ${error.response.data.error}`);
        setDeleteBranch({ ...deleteBranch, isDeleting: false });
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={deleteBranch.isVisible}
        onHide={() =>
          setDeleteBranch({ isVisible: false, branch: "", isDeleting: false })
        }
        centered
      >
        <Modal.Header closeButton>
          <b>Warning!</b>
          <img
            src="/images/dbt.png"
            width="8%"
            style={{
              marginTop: "-3px",
              position: "absolute",
              right: "40px",
            }}
            alt=""
          />
        </Modal.Header>
        <Modal.Body className="text-center m-2">
          <center>
            You are about to delete your branch called <b>{deleteBranch.branch}</b>
            <br />
            <b>Are you sure ?</b>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setDeleteBranch({
                isVisible: false,
                isDeleting: false,
                branch: "",
              })
            }
          >
            <Icon.X size={16} />
            &nbsp; Cancel
          </Button>{" "}
          <Button
            variant="danger"
            disabled={deleteBranch.isDeleting}
            onClick={onDeleteBranch}
          >
            <Icon.Trash2 size={16} />
            &nbsp;
            {deleteBranch.isDeleting ? (
              <>
                Deleting...&nbsp;&nbsp;
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              </>
            ) : (
              "Yes, delete branch"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteBranchModal;
