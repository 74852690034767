import React from "react";
import ContextMenu from ".";
import * as Icon from "react-feather";
import { toast } from "react-toastify";

const CurlyBracesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    {/* Left brace */}
    <path d="M6 4v4c0 1.1-.9 2-2 2s-2 .9-2 2 .9 2 2 2 2 .9 2 2v4" />
    {/* Right brace */}
    <path d="M14 4v4c0 1.1.9 2 2 2s2 .9 2 2-.9 2-2 2-2 .9-2 2v4" />
  </svg>
);

const FileContextMenu = ({
  dropdownAnchor,
  onClose,
  onDuplicate,
  onDelete,
  onRename,
  isMainBranch,
}) => {
  const getFileName = () => {
    const splittedName = dropdownAnchor.nodes.name.split(".");
    splittedName.pop();

    return splittedName.join(".");
  };

  const options = [];

  if (!isMainBranch) {
    options.push(
      {
        label: "Rename File",
        OptionIcon: Icon.Edit3,
        onClick: onRename,
      },
      {
        label: "Duplicate File",
        OptionIcon: Icon.Copy,
        onClick: onDuplicate,
      },
      {
        label: "Delete File",
        OptionIcon: Icon.Trash2,
        onClick: onDelete,
      }
    );
  }

  if (dropdownAnchor.nodes.name.endsWith(".sql")) {
    options.unshift(
      {
        label: "Copy as ref",
        OptionIcon: CurlyBracesIcon,
        onClick: () => {
          navigator.clipboard.writeText(`{{ref('${getFileName()}')}}`);
          var text = `{{ref('${getFileName()}')}}`;
          toast.info(text + " copied to clipboard");
        },
      },
      {
        label: "Copy Filename",
        OptionIcon: Icon.Clipboard,
        onClick: () => {
          navigator.clipboard.writeText(getFileName());
          toast.info("Filename copied to clipboard");
        },
      }
    );
  }

  if (!options.length) {
    return <></>;
  }

  return (
    <ContextMenu
      options={options}
      onClose={onClose}
      dropdownAnchor={dropdownAnchor}
    />
  );
};

export default FileContextMenu;
