import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import * as Reducer from "../../redux/slices/editorSlice";
import { Spinner } from "react-bootstrap";

const mapStateToProps = (state) => {
  return {
    activeTab: state.activeTab,
    unsaved_files: state.unsaved_files,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reorderFiles: (files) => dispatch(Reducer.reorderFiles(files)),
});

const EditorTabs = ({
  files,
  reorderFiles,
  activeTab,
  unsaved_files,
  setFileName,
  running_queries,
  saveCheck,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      files,
      result.source.index,
      result.destination.index
    );

    reorderFiles(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className="editor-tab-container"
            style={{ overflowX: "auto" }}
            {...provided.droppableProps}
          >
            {files.map((file, index) => (
              <Draggable key={file} draggableId={file} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`dragged-tab btn ${
                      activeTab === file ? "active" : ""
                    }`}
                    variant={"tab"}
                    onClick={() => setFileName(file, true)}
                    id={file}
                  >
                    {/* {console.log(provided.draggableProps.style)} */}
                    <Icon.File size={11} style={{ margin: "0 5px" }} />
                    {file.startsWith("untitled-") ? (
                      <i>{file.split("/").pop()}</i>
                    ) : (
                      file.split("/").pop()
                    )}

                    {Object.keys(unsaved_files).includes(file) ? "*" : ""}

                    {Object.keys(running_queries).includes(file) ? (
                      <Spinner
                        as="span"
                        variant="secondary"
                        size="sm"
                        style={{
                          marginLeft: "4px",
                          width: "10px",
                          height: "10px",
                          marginTop: "2px",
                        }}
                        role="status"
                        aria-hidden="true"
                        animation="grow"
                      />
                    ) : (
                      ""
                    )}

                    <Icon.X
                      size={12}
                      onClick={(e) => saveCheck(e, file)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorTabs);
